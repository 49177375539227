import {AxiosResponse} from 'axios';
import {IMediaDetails} from '../model/mediaDetails';
import ApiUtil from '../util/ApiUtil';
import Constants from '../constants/Constants';

const videoApiBaseUrl = '/api/v1/video';

const copyMedia = async (mediaUrl: string, parentFolderHash: string | null): Promise<IMediaDetails> => {
   const response: AxiosResponse<IMediaDetails> = await ApiUtil.executePostAsync<IMediaDetails>(videoApiBaseUrl,
      [Constants.statusCodes.created], {
         Link: mediaUrl,
         parentFolderHash: parentFolderHash
      });

   return response.data;
};

export default {
   copyMedia,
   importYoutubeVideo: copyMedia
};
