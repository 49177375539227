import {ControlButton, TscButtonType, Glyphs, TscThemeName, TscThemeNames, TscStyles} from '@techsmith/tsc-cloud-style-guide';
import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {FileUploadStates, IFileUploadCandidate, MediaTypes} from '../../../model/fileUploadCandidate';
import CssUtil from '../../../util/CssUtil';
import {formatBytes} from '../../../util/FileSizeFormatter';
import HorizontalSeparator from '../../util/HorizontalSeparator';
import ProgressLoader from '../../util/ProgressLoader';
import cssConstants from '../../../constants/cssConstants';
import withMemoizedContexts from '../../../context/contextContainerHoC';
import {themeStore} from '../../../context/themeProvider';

const backgroundAlpha = 0.1;
export const TileWrapper = styled.div<{theme: TscThemeNames; hasError: boolean; isCancelled: boolean}>`
   display: flex;
   flex-direction: row;
   padding: 0;
   height: 3.25rem;
   background-color: ${props =>
      props.hasError ? CssUtil.toRgba(TscStyles.color.action.destructive, backgroundAlpha) :
         props.isCancelled ? CssUtil.toRgba(TscStyles.color.message.caution, backgroundAlpha) :
            props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.mediumDark :
               TscStyles.color.ui.dawn.lightMedium};
   border: 1px solid ${props =>
      props.hasError ? TscStyles.color.action.destructive :
         props.isCancelled ? TscStyles.color.message.caution :
            props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.dark :
               TscStyles.color.ui.dawn.medium};
   border-radius: ${TscStyles.border.radius.lg};
`;

const GlyphWrapper = styled.div<{theme: TscThemeNames}>`
   display: flex;
   width: 3rem;
   justify-content: center;
   align-items: center;
   fill: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.text.light : TscStyles.color.text.medium};
   padding: 0 0.5rem;
`;

const InfoContainer = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   overflow: hidden;
   justify-content: center;
`;

const BoldText = styled.span`
   font-weight: ${TscStyles.font.weight.semibold};
   font-size: 0.875rem;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
`;

const SubTextWrapper = styled.div`
   line-height: 1rem;
   white-space: nowrap;
   overflow: hidden;
`;

const SubText = styled.span`
   font-weight: ${TscStyles.font.weight.regular};
   font-size: 0.75rem;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
   font-variant-numeric: tabular-nums;
`;

export const UploadProgressTileBase: React.FC<IUploadProgressTileProps & IStateMappedProps> = ({uploadCandidate, cancelUpload, restartUpload, theme}) => {
   const {t} = useTranslation();
   const hasUnsupportedFileError = uploadCandidate.state === FileUploadStates.rejectedType || uploadCandidate.state === FileUploadStates.unknownType ||
                                   uploadCandidate.state === FileUploadStates.fileSizeError || uploadCandidate.state === FileUploadStates.rejectedByServerError;

   const getProgressText = () => {
      let progressText: string;
      switch (uploadCandidate.state) {
         case FileUploadStates.ready:
            progressText = t('uploadMedia.uploadStates.queued');
            break;
         case FileUploadStates.uploading:
            progressText = t('uploadMedia.uploadStates.uploading', {progress: uploadCandidate.uploadProgress});
            break;
         case FileUploadStates.processing:
            progressText = t('uploadMedia.uploadStates.processing');
            break;
         case FileUploadStates.completed:
            progressText = t('uploadMedia.uploadStates.completed');
            break;
         case FileUploadStates.cancelled:
            progressText = t('uploadMedia.uploadStates.cancelled', {progress: uploadCandidate.uploadProgress});
            break;
         case FileUploadStates.networkError:
            progressText = t('uploadMedia.uploadStates.networkError');
            break;
         case FileUploadStates.fileSizeError:
            progressText = t('uploadMedia.uploadStates.fileSizeError');
            break;
         case FileUploadStates.rejectedType:
            progressText = t('uploadMedia.uploadStates.unsupportedFileError');
            break;
         case FileUploadStates.unknownType:
            progressText = t('uploadMedia.uploadStates.unsupportedFileError');
            break;
         case FileUploadStates.rejectedByServerError:
            progressText = t('uploadMedia.uploadStates.unsupportedFileError');
            break;
         default:
            progressText = t('uploadMedia.uploadStates.error');
            break;
      }

      if (uploadCandidate.uploadErrorStatusCode) {
         progressText = `${progressText} (Code ${uploadCandidate.uploadErrorStatusCode})`;
      }

      return progressText;
   };

   const getLeftSection = () => {
      switch (uploadCandidate.mediaType) {
         case MediaTypes.video:
            return <Glyphs.Video16x16 />;
         case MediaTypes.image:
            return <Glyphs.Image16x16 />;
         case MediaTypes.audio:
            return <Glyphs.Audio16x16 />;
         default:
            return <Glyphs.Question16x16 />;
      }
   };

   const getRightSection = () => {
      if (uploadCandidate.state === FileUploadStates.uploading) {
         return <ControlButton glyph={<Glyphs.Close16x16 />} onClick={cancelUpload} title={t('general.cancel')} buttonType={TscButtonType.tertiary} themeName={theme} testId="uploadProgressTile-cancel-upload-button" />;
      } else if (uploadCandidate.state === FileUploadStates.completed) {
         return <Glyphs.Checkmark16x16 />;
      } else if (hasUnsupportedFileError) {
         return <Glyphs.Warning16x16 />;
      } else if (uploadCandidate.state === FileUploadStates.networkError || uploadCandidate.state === FileUploadStates.cancelled) {
         return <ControlButton glyph={<Glyphs.Redo16x16 />} onClick={restartUpload} title={t(uploadCandidate.state === FileUploadStates.cancelled ? 'uploadMedia.restartUpload' : 'uploadMedia.retryUpload')} buttonType={TscButtonType.tertiary} themeName={theme} testId="uploadProgressTile-retry-upload-button" />;
      }
      return <></>;
   };

   const progressBarContainerStyle: React.CSSProperties = {
      height: '0.25rem',
      width: '100%',
      margin: '0.125rem auto',
      background: theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.lightMedium : TscStyles.color.ui.dawn.medium
   };

   const progressBarStyle: React.CSSProperties = {
      backgroundImage: uploadCandidate.state === FileUploadStates.uploading ? cssConstants.progressLoaderGradientGreen : cssConstants.progressLoaderGradientOrange
   };

   return (
      <TileWrapper data-test-id="upload-progress-tile" theme={theme} hasError={hasUnsupportedFileError || uploadCandidate.state === FileUploadStates.networkError} isCancelled={uploadCandidate.state === FileUploadStates.cancelled}>
         <GlyphWrapper data-test-id="upload-media-icon" theme={theme}>{getLeftSection()}</GlyphWrapper>
         <InfoContainer>
            <BoldText title={uploadCandidate.file.name}>{uploadCandidate.file.name}</BoldText>
            {(uploadCandidate.state === FileUploadStates.uploading || uploadCandidate.state === FileUploadStates.processing) && <ProgressLoader uploadProgress={uploadCandidate.uploadProgress} containerStyle={progressBarContainerStyle} progressStyle={progressBarStyle} />}
            <SubTextWrapper>
               <SubText>{getProgressText()}</SubText>
               <HorizontalSeparator />
               <SubText>{formatBytes(uploadCandidate.file.size)}</SubText>
            </SubTextWrapper>
            {uploadCandidate.state === FileUploadStates.rejectedByServerError && <SubTextWrapper></SubTextWrapper>}
         </InfoContainer>
         <GlyphWrapper data-test-id="upload-status-icon" theme={theme}>{getRightSection()}</GlyphWrapper>
      </TileWrapper>
   );
};

export interface IUploadProgressTileProps {
   uploadCandidate: IFileUploadCandidate;
   cancelUpload(): void;
   restartUpload(): void;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {uploadCandidate, cancelUpload, restartUpload}: IUploadProgressTileProps,
   {theme}: Partial<IStateMappedProps>
): IStateMappedProps & IUploadProgressTileProps => ({uploadCandidate, cancelUpload, restartUpload, theme});

export default withMemoizedContexts(mapStatesToProps, themeStore)(UploadProgressTileBase);
