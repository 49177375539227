import React from 'react';
import {useTranslation} from 'react-i18next';
import {AccountWidget, PopoverButton, Glyphs, TscPopoverOrigin, TscThemeName, TscThemeNames, TscButtonType} from '@techsmith/tsc-cloud-style-guide';
import config from '../../service/config';
import Settings from './Settings';
import styled from 'styled-components';
import HelpLinksMenu from './HelpLinksMenu';
import cssConstants from '../../constants/cssConstants';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';
import UserQuotas from './UserQuotas';

const AccountWidgetWrapper = styled.div`
   z-index: 1;

   .site-account-widget-overlay-body {
      padding: 1rem 1rem 0 1rem;
   }

   .site-account-widget-overlay-actions {
      padding: 0 1rem 1rem 1rem;
   }
`;

const HelpLinkWrapper = styled.div`
   margin: 0 0.5rem 0 0.25rem;
`;

const QuotasWrapper = styled.div`
   margin: .5rem 1rem .5rem 1rem;
`;

export const SettingsAndAccountWidgetBase: React.FC<ISettingsAndAccountWidgetProps & ISettingsAndAccountWidgetStateMappedProps> = ({hideSettings, hideThemePicker, theme}) => {
   const {t} = useTranslation();

   const showSettings = !hideSettings && !hideThemePicker;

   return (
      <>
         {showSettings && <Settings hideThemePicker={hideThemePicker} />}
         <HelpLinkWrapper>
            <PopoverButton
               buttonType={TscButtonType.tertiary} // TODO:  is this right
               buttonGlyph={<Glyphs.Question16x16/>}
               origin={TscPopoverOrigin.bottomRight}
               themeName={TscThemeName.dusk}
               showArrow={false}
               buttonTitle={t('helpLinks.buttonTitle')}
               testId="help-links-button"
               closeMenuOnClick={true}
               popoverMenu={<HelpLinksMenu/>}
               width="2rem"
               useMobileMenu={true}
               popoverMenuMaxHeight="20rem"
               mobileContextMenuZindex={cssConstants.zIndex.mobileContextMenu}
               mobileContextMenuTitle={t('helpLinks.buttonTitle')}
            />
         </HelpLinkWrapper>
         <AccountWidgetWrapper>
            <AccountWidget
               testId="account-widget"
               isSignedIn={config.user.IsSignedIn}
               hideSigninButton={false}
               userDisplayName={config.user.DisplayName}
               userEmail={config.user.EmailAddress}
               userID={config.user.TechSmithId}
               avatarImageUrl={config.user.AvatarImageUrl}
               signInUrl={config.environmentData.SignInUrl}
               myAccountUrl={!config.featureSwitches.HideAccountWidgetMyAccountLink ? config.environmentData.MyAccountUrl : null}
               signOutUrl={config.environmentData.SignOutUrl}
               themeName={theme}
               signInButtonText={t('SignIn')}
               signOutButtonText={t('SignOut')}
               myAccountButtonText={t('MyAccount')}
               className={'site-account-widget'}
               openMyAccountInNewTab={true}
            >
               {config.featureSwitches.EnableScreencastProEntitlement && <QuotasWrapper><UserQuotas theme={TscThemeName.dawn} /></QuotasWrapper>}
            </AccountWidget>
         </AccountWidgetWrapper>
      </>
   );
};

export interface ISettingsAndAccountWidgetProps {
   hideSettings?: boolean;
   hideThemePicker?: boolean;
}

export interface ISettingsAndAccountWidgetStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {hideSettings, hideThemePicker}: ISettingsAndAccountWidgetProps,
   {theme}: Partial<ISettingsAndAccountWidgetStateMappedProps>
): ISettingsAndAccountWidgetStateMappedProps & ISettingsAndAccountWidgetProps => ({hideSettings, hideThemePicker, theme});

export default withMemoizedContexts(mapStatesToProps, themeStore)(SettingsAndAccountWidgetBase);
