/* eslint-disable no-undefined */
import React, {createContext, ReactElement, useState} from 'react';
import {ISelectedConversationData, nullConversationDataFactory} from '../model/conversationModel';
import {useQueryParam, StringParam} from 'use-query-params';
import Constants from '../constants/Constants';

const initialState: ISelectedConversationData = nullConversationDataFactory();
const store = createContext<ISelectedConversationData>(initialState);
const Provider = store.Provider;
let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<ISelectedConversationData>> = () => null;
let privateUpdateCamelCaseCommentQueryParam: (newValue: string) => void = null;
let privateUpdateLowerCaseCommentQueryParam: (newValue: string) => void = null;
let privateUpdateConversationQueryParam: (newValue: string) => void = null;

const publicUpdateState = (newState: Partial<ISelectedConversationData>) => {
   if (newState.SelectedComment) {
      privateUpdateCamelCaseCommentQueryParam(newState.SelectedComment.id);
      privateUpdateLowerCaseCommentQueryParam(undefined);
   } else if (newState.SelectedComment === null) {
      privateUpdateCamelCaseCommentQueryParam(undefined);
      privateUpdateLowerCaseCommentQueryParam(undefined);
   }

   if (newState.VideoReviewId) {
      privateUpdateConversationQueryParam(newState.VideoReviewId);
   } else if (newState.VideoReviewId === '') {
      privateUpdateConversationQueryParam(undefined);
   }

   privateUpdateState(Object.assign({}, privateState, newState));
};

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<ISelectedConversationData>(initialState);
   const [, setDeepLinkedCommentIdCamelCase] = useQueryParam(Constants.queryParams.commentId, StringParam);
   const [, setDeepLinkedCommentIdLowerCase] = useQueryParam(Constants.queryParams.commentid, StringParam);
   const [, setConversationId] = useQueryParam(Constants.queryParams.conversation, StringParam);

   privateUpdateState = updateState;
   privateState = state;
   privateUpdateCamelCaseCommentQueryParam = v => setDeepLinkedCommentIdCamelCase(v, 'replaceIn');
   privateUpdateLowerCaseCommentQueryParam = v => setDeepLinkedCommentIdLowerCase(v, 'replaceIn');
   privateUpdateConversationQueryParam = v => setConversationId(v, 'replaceIn');

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useSelectedConversationProvider,
   store as selectedConversationStore,
   publicUpdateState as updateSelectedConversationState
};
