import React, {createContext, ReactElement, useState} from 'react';

interface ICanvasProvider {
   canvasRef: any;
}

const initialState: ICanvasProvider = {canvasRef: null};

const store = createContext(initialState);
const Provider = store.Provider;

let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<ICanvasProvider>> = () => null;
const publicUpdateState = (newState: Partial<ICanvasProvider>) => privateUpdateState(Object.assign({}, privateState, newState));

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<ICanvasProvider>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useCanvasProvider,
   store as canvasStore,
   publicUpdateState as updateCanvasState
};
