import React, {useState, useRef} from 'react';
import {ControlButton, TscButtonType, TscButtonGlyphPlacement, Glyphs, PopoverButton, TscThemeName} from '@techsmith/tsc-cloud-style-guide';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import Constants from '../../constants/Constants';
import CreateMediaMenu from './CreateMediaMenu';
import RecorderGlyph from '../../static/svg/RecorderGlyph.svg';
import AddYoutubeMediaModal from '../modals/AddYoutubeMediaModal';
import ConnectMobileDeviceModal from '../modals/ConnectMobileDeviceModal';
import UploadMediaModal from '../modals/UploadMediaModal/UploadMediaModal';
import cssConstants from '../../constants/cssConstants';
import config from '../../service/config';

const backgroundColor = `${cssConstants.sidebarAccentColor}`;

export const CreateMediaContentContainer = styled.div`
   background: ${backgroundColor};
   padding: 1rem;
   display: flex;
   flex-direction: column;

   a {
      margin-bottom: 1rem;
   }
`;

/* the width and position of the popover dropdown are calculated from the position
   of the parent 'split' button container, this is why we use a ref and set a width
   in this styling, as well as why we override the default popover transform/right/left */
const SplitButtonContainer = styled.div<{popoverMenuWidth: number}>`
   display: flex;
   justify-content: space-between;

   > button {
      flex-grow: 1;

      &.has-more-button {
         margin-right: 1px;
         border-top-right-radius: 0;
         border-bottom-right-radius: 0;
      }
   }

   > div {
      width: 2rem;
      flex: none;

      > button {
         border-top-left-radius: 0;
         border-bottom-left-radius: 0;
      }

      > div {
         transform: none;
         right: 0;
         left: auto;
         width: ${props => props.popoverMenuWidth}px;
      }
   }
`;

const CreateMediaContent: React.FC = () => {
   const {t} = useTranslation();
   const [displayUploadModal, setDisplayUploadModal] = useState(false);
   const [displayYoutubeModal, setDisplayYoutubeModal] = useState(false);
   const [displayConnectMobileModal, setDisplayConnectMobileModal] = useState(false);
   const splitButtonContainerElement = useRef<HTMLDivElement>(null);
   const popoverMenuWidthOverride = splitButtonContainerElement.current?.clientWidth;
   const displayCreateMoreButton = config.IsYouTubeEnabled || !config.featureSwitches.DisableMobileConnect;

   return (
      <CreateMediaContentContainer>
         {!config.featureSwitches.DisableCapture &&
            <ControlButton
               href={Constants.navigation.launchCaptureLink}
               label={t('sidebar.launchCapture')}
               buttonType={TscButtonType.secondary}
               themeName={TscThemeName.dusk}
               openLinkInNewTab={true}
               isFullWidth={true}
               testId="create-media-content-launch-capture-button"
               glyph={<RecorderGlyph/>}
            />
         }
         <SplitButtonContainer ref={splitButtonContainerElement} popoverMenuWidth={popoverMenuWidthOverride}>
            <ControlButton
               label={t('general.upload')}
               themeName={TscThemeName.dusk}
               glyph={<Glyphs.Upload16x16 />}
               glyphPlacement={TscButtonGlyphPlacement.left}
               buttonType={config.featureSwitches.EnableScreencastProEntitlement ? TscButtonType.secondary : TscButtonType.primary}
               testId="create-media-content-create-button"
               className={displayCreateMoreButton ? 'has-more-button' : ''}
               onClick={() => setDisplayUploadModal(true)}
            />
            {displayCreateMoreButton && <PopoverButton
               buttonTitle={t('sidebar.uploadMediaOptions')}
               popoverMenu={<CreateMediaMenu setDisplayYoutubeModal={setDisplayYoutubeModal} setDisplayConnectMobileModal={setDisplayConnectMobileModal} />}
               themeName={TscThemeName.dusk}
               buttonGlyph={<Glyphs.ArrowDown16x16 />}
               buttonType={TscButtonType.primary}
               testId="create-media-content-create-button-more"
               useMobileMenu={false}
               mobileContextMenuTitle={t('sidebar.uploadMediaOptions')}
               mobileContextMenuZindex={cssConstants.zIndex.mobileContextMenu}
            />}
         </SplitButtonContainer>
         {displayUploadModal && <UploadMediaModal onClose={() => setDisplayUploadModal(false)} />}
         {displayYoutubeModal && <AddYoutubeMediaModal onClose={() => setDisplayYoutubeModal(false)} />}
         {displayConnectMobileModal && <ConnectMobileDeviceModal onClose={() => setDisplayConnectMobileModal(false)} />}
      </CreateMediaContentContainer>
   );
};

export default CreateMediaContent;
