import React from 'react';
import {IFolderTreeNode} from './folderTreeNode';
import {IMediaDetails} from './mediaDetails';
import {BehaviorFilters, CollectionPrivacyTypes, collectionRoleTypeAsInteger, CollectionRoleTypes, MigrationExportStatus, StatusPages} from '../constants/Constants';
import {IMediaAnalyticsModel} from './mediaAnalyticsModel';
import {IMigrationModel} from './migrationModel';
import {IMigrationFileModel} from './migrationFileModel';

export interface IBaseAppModel {
   User: IUserModel;
   UserMigrations: IMigrationModel[];
   EnvironmentData: IEnvironmentDataModel;
   FeatureSwitches: IFeatureSwitchesModel;
   UserPermissions: string[];
   OrganizationName: string;
   ApplicationShortName: string;
   ApplicationFullName: string;
   SidebarLogoLinkUrl: string;
   ArePublicVideosEnabled: boolean;
   IsYouTubeEnabled: boolean;
}

export interface IEmbedAppModel extends IBaseAppModel {
   Media: IMediaDetails;
   LoggedInUserIsContentOwner: boolean;
   DisableProductBanner: boolean;
}

export interface ISettingsAppModel extends IBaseAppModel {
   UserSettings: {
      CustomSiteLogoUrl: string;
      EnableProductBanner: boolean;
      EnableUserAutoCaptions: boolean;
      EnableNewUploadConversations: boolean;
   };
}

export interface IWatchAppModel extends IBaseAppModel {
   Media: IMediaDetails;
   Folder: IFolderTreeNode;
   WatchPageParameters: IWatchPageParametersModel;
   ViewersCanShareMedia: boolean;
   ViewersCanDownloadMedia: boolean;
   ViewersCanCopyMedia: boolean;
   ArePublicVideosEnabled: boolean;
   ReferringGroup: string;
   LoggedInUserIsContentOwner: boolean;
   DisableProductBanner: boolean;
}

export interface ILibraryModel extends IBaseAppModel {
   ProfileTechSmithId: string;
   ProfileEmailAddress: string;
   ProfileDisplayName: string;
   LoggedInUserIsProfileOwner: boolean;
   LoggedInUserHasCreatePermission: boolean;
   CanAdministerProfile: boolean;
   CanCreateContent: boolean;
   ViewersCanCopyMedia: boolean;
}

export interface IRegisterModel extends IBaseAppModel {
   RegistrationStep: string;
   StepSuccessRedirect: string;
   UserHasAnyMedia: boolean;
   TrialDeclineUrl: string;
   KnowmiaProPurchaseTrialPageUrl: string;
   TrialExpiredLearnMoreUrl: string;
   KnowmiaProPurchaseExpiredEntitlementUrl: string;
}

export interface ILauncherModel extends IBaseAppModel {
   WindowsRecorder64BitCdnUrl: string;
   WindowsRecorder32BitCdnUrl: string;
   MacRecorderCdnUrl: string;
}

export interface IStatusModel extends IBaseAppModel {
   AllowedEmailDomains?: string;
   ReturnUrl?: string;
   SignInNewTabUrl?: string;
   SignOutRedirectToSignInUrl?: string;
   StatusPage: StatusPages;
}

export interface IMigrationMediaDownloadModel extends IBaseAppModel {
   ExportStatus: MigrationExportStatus;
   ArchiveFiles?: IMigrationFileModel[];
}

export interface IMediaAnalyticsResponseModel {
   UserMediaAnalytics: IMediaAnalyticsModel[];
}

export interface ICollectionModel {
   Id: string;
   MediaCount: number;
   Name: string;
   Privacy: CollectionPrivacyTypes;
   MostRecentMediaThumbnailUrl?: string;
   MostRecentMediaDate?: Date;
   CanLeave: boolean;
   Members?: string[];
   MemberDetails?: IMemberDetailsModel[];
   Role: CollectionRoleTypes;
   DefaultRole: CollectionRoleTypes;
}

export interface ICollectionSummaryModel {
   Id: string;
   Name: string;
   Privacy: string;
   DefaultRole: CollectionRoleTypes;
   InviteLink: string;
}

export interface ICollectionNavigationResponse {
   CollectionName: string;
   MediaCount: number;
   CurrentMediaPosition: number;
   PreviousMediaDetails: IMediaDetails;
   NextMediaDetails: IMediaDetails;
}

export interface IMemberDetailsModel {
   FirstAccessDate: Date;
   DisplayName: string;
   Role: CollectionRoleTypes;
   TechSmithId: string;
   EmailAddress: string;
}

export const compareMembersByRole = (m1: IMemberDetailsModel, m2: IMemberDetailsModel) => m1.Role === m2.Role ? 0 : collectionRoleTypeAsInteger(m1.Role) > collectionRoleTypeAsInteger(m2.Role) ? -1 : 1;

export interface IMemberDetailsWithProfileImageModel extends IMemberDetailsModel {
   profileImageUrl: string;
}

export interface IBehaviorFilterEnum {
   selectedFilter: BehaviorFilters.all | BehaviorFilters.partial | BehaviorFilters.completed;
}

export interface IUserModel {
   TechSmithId: string;
   IsSignedIn: boolean;
   DisplayName: string;
   EmailAddress: string;
   AvatarImageUrl: string;
   Entitlements: IEntitlementStatus[];
   Secret?: string;
   readonly SiteAccessEntitlement: IEntitlementStatus;
}

export interface IEntitlementStatus {
   EntitlementName: string;
   IsActive: boolean;
   IsInTrial: boolean;
   DaysLeftInEntitlement: number;
   HasPurchased: boolean;
   CanStartTrial: boolean;
   ContentExpirationDate: string;
}

export interface IUserProfileModel {
   displayName: string;
   profileImageUrl: string;
   techSmithId: string;
   firstName?: string;
   lastName?: string;
}

export interface IUserProfileWithEmailModel extends IUserProfileModel {
   email: string;
}

export interface IMediaDimensionsModel {
   height: number;
   width: number;
}

export interface IWatchPageParametersModel {
   MaxDescriptionLength: number;
   MaxTitleLength: number;
   MaxConversationTitleLength: number;
}

export interface IFeatureSwitchesModel {
   DisableActivityPage: boolean;
   DisableCaptioningAssigneeOption: boolean;
   DisableChangeOwner: boolean;
   DisableContactAdministratorFooterLink: boolean;
   DisableCopy: boolean;
   DisableFolderShareOption: boolean;
   DisableListedMediaPrivacy: boolean;
   DisableAnalyticsAndQuizEditing: boolean;
   DisableAutomaticTranscription: boolean;
   DisableMediaEditing: boolean;
   DisableMobileConnect: boolean;
   DisableSearch: boolean;
   DisableSmartPlayerAccessToken: boolean;
   DisableTransferForMobileConnect: boolean;
   EnableAIMediaSummary: boolean;
   EnableAutoCaptionVideo: boolean;
   EnableThirdPartyCaptionGeneration: boolean;
   EnableMediaAccessPhrase: boolean;
   EnableScreencastProEntitlement: boolean;
   ForceADACompliantCaptionStyle: boolean;
   HideAccountWidgetMyAccountLink: boolean;
   HideEnterprisePrivacyOptions: boolean;
   PublicVideos: boolean;
   UseEntitlementAuthorization: boolean;
   UseAuth0Authentication: boolean;
   UseSingleConversation: boolean;
   IsScreencast: boolean;
   DisableCapture: boolean;
   EnableReactions: boolean;
   DisableInternalUserCollectionManagement: boolean;
   EnableInAppNotifications: boolean;
}

export enum EnvironmentType {
   local= 'local',
   dev= 'dev',
   stage= 'stage',
   live= 'live',
   test= 'test'
}

export interface IEnvironmentDataModel {
   Environment: EnvironmentType;
   Tenant: string;
   TenantDisplayName: string;
   PresentationRootUrl: string;
   RelayServerRootUrl: string;
   ResultsRootUrl: string;
   SignInUrl: string;
   SignOutUrl: string;
   MyAccountUrl: string;
   ProfileApiUrl: string;
   SiteLogoImageUrl: string;
   SiteLogoLinkUrl: string;
   IsRunningLocally: boolean;
   TranslationResourceUrl: string;
   CustomSiteLogoUrl: string;
   HelpLinkUrl: string;
   CustomerLink: string;
   CustomerLinkLabel: string;
   ApplicationInsightsId: string;
   GoogleAnalyticsId: string;
   GoogleAnalyticsDomain: string;
   UserIqSiteId: string;
   IsLti: boolean;
   CustomerServiceMessage?: string;
   CdnUrl: string;
   SupportsChangeMedia: boolean;
   VideoReviewApiUrl: string;
   TscNotificationEndPointUrl: string;
   QuizzingServiceUrl: string;
   QuizzingServiceCreateInstanceUrl?: string;
   KnowmiaProPurchaseLinkUrl: string;
   ScreencastContentExistsApiUrl: string;
   ScreencastProPurchaseLinkUrl: string;
   ScreencastBaseUrl: string;
   ChangeEmailSupportUrl: string;
}

export interface ICreateCollectionResponse {
   GroupName: string;
   Id: string;
}

export interface IButtonAction {
   Label: string;
   OnClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
