import {IUserProfileModel, IUserProfileWithEmailModel} from '../model/appModel';
import ApiUtil from '../util/ApiUtil';
import Constants from '../constants/Constants';
import config from './config';
import {AxiosResponse} from 'axios';
import i18n from 'i18next';
import accountApi from './accountApi';
import logging from './logging';

const profileBaseUrl = 'api/v1/profiles';

interface IUserProfileResponse {
   displayName: string;
   profileImageUrl: string;
   techSmithId: string;
   _firstName: string;
   _lastName: string;
}

const getUserProfileInfo = async (techSmithId: string): Promise<IUserProfileModel> => {
   if (!techSmithId || techSmithId === Constants.nullTechSmithId) {
      throw Error('TechSmithId is null when getting profile');
   }

   try {
      const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(`${config.environmentData.ProfileApiUrl}/${profileBaseUrl}/${techSmithId}/default`, [Constants.statusCodes.ok, Constants.statusCodes.noContent], null);
      const data: IUserProfileResponse = response.data;

      return {
         displayName: data.displayName,
         profileImageUrl: data.profileImageUrl,
         techSmithId: data.techSmithId,
         // eslint-disable-next-line no-underscore-dangle
         firstName: data._firstName,
         // eslint-disable-next-line no-underscore-dangle
         lastName: data._lastName
      };
   } catch (e) {
      logging.error(`Failed to load profile info for user: ${techSmithId}`, e);

      return {
         displayName: i18n.t('conversations.warnings.failedToLoadUsername'),
         profileImageUrl: null,
         techSmithId: techSmithId,
         firstName: null,
         lastName: null
      };
   }
};

const getUserProfileInfoFromEmail = async (email: string): Promise<IUserProfileWithEmailModel> => {
   const techSmithId = await accountApi.getTechSmithIdFromEmail(email);
   const userProfile: IUserProfileModel = await getUserProfileInfo(techSmithId);

   return Object.assign({}, userProfile, {email: email});
};

const getProfileImageUrl = (techSmithId: string): string => `${config.environmentData.ProfileApiUrl}/${profileBaseUrl}/${techSmithId}/default/picture`;

export default {
   getUserProfileInfo,
   getUserProfileInfoFromEmail,
   getProfileImageUrl
};
