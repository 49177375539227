import React, {useState} from 'react';
import config from '../../service/config';
import {useTranslation} from 'react-i18next';
import {IFolderTreeNode} from '../../model/folderTreeNode';
import {Glyphs, MenuItem} from '@techsmith/tsc-cloud-style-guide';
import ChangeOwnerGlyph from '../../static/svg/ChangeOwner.svg';
import DeleteModal from '../modals/DeleteModal';
import CreateOrRenameFolderModal from '../modals/CreateFolderModal';
import ChangeOwnerModal from '../modals/ChangeOwnerModal';
import MoveModal from '../modals/MoveModal';
import ShareLinkModal from '../modals/ShareLinkModal';
import {foldersStore, updateFoldersData} from '../../context/foldersProvider';
import {IMinimalFolder} from '../../model/minimalFolder';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {updateUserQuotaFromServer} from '../../context/userQuotaProvider';
import {migrationStore} from '../../context/migrationProvider';
import {isWellKnownFolder} from '../../constants/wellKnownFolder';

export const FolderMoreMenuBase: React.FC<IStateMappedProps & IFolderMoreMenuProps> = ({folder, activeFolder, migrationInProgress}) => {
   const {t} = useTranslation();
   const [showShareModal, setShowShareModal] = useState(false);
   const [showMoveModal, setShowMoveModal] = useState(false);
   const [showChangeOwnerModal, setShowChangeOwnerModal] = useState(false);
   const [showRenameModal, setShowRenameModal] = useState(false);
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
   const [showCreateSubfolderModal, setShowCreateSubfolderModal] = useState(false);
   const [destinationFolderHash, setDestinationFolderHash] = useState('');

   const onCreateFolderClick = () => {
      setShowMoveModal(false);
      setShowCreateFolderModal(true);
   };

   const onCreateFolderClose = (updatedFolder: IMinimalFolder) => {
      setShowCreateFolderModal(false);
      updatedFolder && setDestinationFolderHash(updatedFolder.Hash);
      setShowMoveModal(true);
   };

   const onMoveModalClose = () => {
      setShowMoveModal(false);
      setDestinationFolderHash('');
   };

   const updateFolders = async () => {
      await updateFoldersData((config.libraryConfig?.profileTechSmithId || config.feedConfig?.profileTechSmithId) ?? config.user.TechSmithId);
      updateUserQuotaFromServer();
   };

   const shareLink = `${config.environmentData.PresentationRootUrl}/folder/${folder.Hash}`;
   const createParentFolder = isWellKnownFolder(activeFolder?.Hash) ? null : activeFolder?.Hash;

   return (<>
      <MenuItem key="delete" label={t('folders.options.delete')} glyph={<Glyphs.Delete16x16 />} onClick={() => setShowDeleteModal(true)} isDisabled={migrationInProgress} testId="folder-more-delete" />
      <MenuItem key="move" label={t('folders.options.move')} glyph={<Glyphs.FolderMove16x16 />} onClick={() => setShowMoveModal(true)} testId="folder-more-move" />
      {!config.featureSwitches.DisableChangeOwner && <MenuItem key="change-owner" label={t('folders.options.changeOwner')} glyph={<ChangeOwnerGlyph />} onClick={() => setShowChangeOwnerModal(true)} testId="folder-more-owner" />}
      <MenuItem key="rename" label={t('folders.options.rename')} glyph={<Glyphs.Edit16x16 />} onClick={() => setShowRenameModal(true)} testId="folder-more-rename" />
      {!config.featureSwitches.DisableFolderShareOption && <MenuItem key="share" label={t('folders.options.share')} glyph={<Glyphs.Share16x16 />} onClick={() => setShowShareModal(true)} testId="folder-more-share" />}
      {!folder.ParentFolder && <MenuItem key="create-subfolder" label={t('folders.options.createSubfolder')} glyph={<Glyphs.FolderAdd16x16 />} onClick={() => setShowCreateSubfolderModal(true)} testId="folder-more-create" />}
      {showDeleteModal && <DeleteModal onClose={() => setShowDeleteModal(false)} onDelete={updateFolders} foldersToDelete={[folder]} title={t('deleteFolder.title')} warning={t('deleteFolder.warning', {folderName: folder.Name})} />}
      {showMoveModal && <MoveModal destinationFolderHash={destinationFolderHash} onClose={onMoveModalClose} onCreateFolder={folder.ParentFolder ? null : onCreateFolderClick} selectedFolders={[folder]} onMove={updateFolders} />}
      {showChangeOwnerModal && <ChangeOwnerModal onClose={() => setShowChangeOwnerModal(false)} onChangeOwner={updateFolders} selectedFolders={[folder]} />}
      {showRenameModal && <CreateOrRenameFolderModal folderToRename={folder} onClose={() => setShowRenameModal(false)} />}
      {showShareModal && <ShareLinkModal title={t('share.linkTitle')} link={shareLink} text={t('folders.share.shareLinkText')} onClose={() => setShowShareModal(false)} />}
      {showCreateFolderModal && <CreateOrRenameFolderModal onClose={onCreateFolderClose} parentFolder={createParentFolder} />}
      {showCreateSubfolderModal && <CreateOrRenameFolderModal onClose={() => setShowCreateSubfolderModal(false)} parentFolder={folder.Hash} />}
   </>);
};

export interface IFolderMoreMenuProps {
   folder: IFolderTreeNode;
}

export interface IStateMappedProps {
   activeFolder: IFolderTreeNode;
   migrationInProgress: boolean;
}

const mapStatesToProps = (
   {folder}: IFolderMoreMenuProps,
   {activeFolder}: Partial<IStateMappedProps>,
   {migrationInProgress}: Partial<IStateMappedProps>
): IFolderMoreMenuProps & IStateMappedProps => ({folder, activeFolder, migrationInProgress});

export default withMemoizedContexts(mapStatesToProps, foldersStore, migrationStore)(FolderMoreMenuBase);
