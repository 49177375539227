import React from 'react';
import {useTranslation} from 'react-i18next';
import {ControlButton, TscButtonType, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import {MinWidthButtonWrapper} from '../util/StyledElements';
import '../../static/css/modal.less';
import ShareMediaLink from './ShareMediaLink';
import {BasicModalPortal} from '../util/ModalPortalHelper';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';

export const ShareLinkModalBase: React.FC<IShareLinkModalProps & IStateMappedProps> = ({onClose, title, text, link, child, theme}) => {
   const {t} = useTranslation();

   const onCloseClick = () => {
      onClose();
   };

   const shareModalWidth = '27rem';

   return (
      <BasicModalPortal visible={true} title={title} width={shareModalWidth} onClose={onCloseClick} themeName={theme}>
         <div className="modal-body">
            <div className="form-body themeable-section">
               <ShareMediaLink link={link} text={text} />
               {child}
               <div className="button-group">
                  <MinWidthButtonWrapper>
                     <ControlButton
                        label={t('general.close')}
                        buttonType={TscButtonType.secondary}
                        themeName={theme}
                        onClick={onCloseClick}
                     />
                  </MinWidthButtonWrapper>
               </div>
            </div>
         </div>
      </BasicModalPortal>
   );
};

export interface IShareLinkModalProps {
   onClose(): void;
   title: string;
   text: string;
   link: string;
   child?: React.ReactNode;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {title, text, link, child, onClose}: IShareLinkModalProps,
   {theme}: Partial<IStateMappedProps>
): IStateMappedProps & IShareLinkModalProps => ({title, text, link, child, onClose, theme});

export default withMemoizedContexts(mapStatesToProps, themeStore)(ShareLinkModalBase);
