/* eslint-disable no-undefined */
import React, {createContext, ReactElement, useState} from 'react';
import {ISelectedReaction, nullSelectedReactionGroupFactory} from '../model/emojiReactionsModel';

const initialState: ISelectedReaction = nullSelectedReactionGroupFactory();
const store = createContext<ISelectedReaction>(initialState);
const Provider = store.Provider;
let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<ISelectedReaction>> = () => null;

const publicUpdateState = (newState: Partial<ISelectedReaction>) => {
   privateUpdateState(Object.assign({}, privateState, newState));
};

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<ISelectedReaction>(initialState);
   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useSelectedReactionGroupProvider,
   store as selectedReactionGroupStore,
   publicUpdateState as updateSelectedReactionGroupState
};
