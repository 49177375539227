import React, {Suspense, lazy, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Glyphs, TscStyles} from '@techsmith/tsc-cloud-style-guide';
import cssConstants from '../../constants/cssConstants';
import {NonStyledButton} from '../util/StyledElements';
import AlertGlyph from '../../static/svg/alert.svg';
import useWindowIsAtOrBelowSize from '../../hooks/useWindowIsAtOrBelowSize';
import {importReactMarkdown, importRehypeRaw} from '../../hooks/dynamicImport';

const MigrationAlertBarContainer = styled.div`
   background: ${cssConstants.alertBarBackgroundColor};
   width: 100%;
   display: flex;
   flex: 0 0 auto;
   border-bottom: 1px solid ${cssConstants.alertBarBorderColor};
   padding: .5rem 2rem;
   box-sizing: border-box;
   align-items: center;
   justify-content: space-between;
`;

const AlertGlyphWrapper = styled.div`
   display: flex;

   svg {
      height: 2rem;
      width: 2rem;
   }
`;

const AlertMessageWrapper = styled.span`
   margin: 0 2rem;
   color: #fff;
   display: inline;
   letter-spacing: .75px;

   @media screen and (max-width: ${cssConstants.headerBreakpoint}px) {
      margin: 0 1rem;
   }

   p {
      margin: 0;
   }

   a {
      color:inherit;
      text-decoration:underline;

      &:focus, &:hover{
         text-decoration:none;
         outline-style:dashed;
         outline-width:1px;
         outline-offset:2px;
      }

      &:active{
         text-decoration:none;
         outline-style: solid;
         outline-width:1px;
      }
   }
`;

const ExpandAlertDetailsButton = styled(NonStyledButton)`
   font-size: 1rem;
   font-weight: ${cssConstants.semiBoldWeight};
   text-decoration: underline;
   color: ${cssConstants.alertBarLinkTextColor};
   cursor: pointer;
   display: none;
   letter-spacing: .75px;
   font-size: 0.875rem;

   @media screen and (max-width: ${cssConstants.headerBreakpoint}px) {
      display: flex;
   }
`;

const CloseButton = styled(NonStyledButton)`
   svg {
      fill: ${TscStyles.color.ui.dawn.dark};
   }
`;

const MigrationAlertBar: React.FC<IAlertBar> = ({migrationAlertMessage}) => {
   const [isDisplayed, setIsDisplayed] = useState(!!migrationAlertMessage);
   const isSidebarCollapsibleSize = useWindowIsAtOrBelowSize(cssConstants.headerBreakpoint);
   const [isExpandedToggled, setIsExpandedToggled] = useState(false);
   const {t} = useTranslation();
   const isExpanded = !isSidebarCollapsibleSize || isExpandedToggled;
   const displayedServiceMessage = isExpanded ? migrationAlertMessage : '';
   const [rehypeRaw, setRehypeRaw] = useState(null);

   const hideAlertBar = (): void => {
      setIsDisplayed(false);
   };

   const toggleIsExpanded = (): void => {
      setIsExpandedToggled(!isExpandedToggled);
   };

   useEffect(() => {
      void importRehypeRaw().then(module => setRehypeRaw(module));
   }, []);

   const ReactMarkdown = lazy(() => importReactMarkdown());

   const MigrationAlertBarComponent = (
      <MigrationAlertBarContainer id={'migration-alert-bar-container'}>
         <AlertGlyphWrapper>
            <AlertGlyph />
         </AlertGlyphWrapper>
         <AlertMessageWrapper>
            <Suspense fallback={null}>
               {rehypeRaw && <div data-test-id="react-markdown-migration">
                  <ReactMarkdown rehypePlugins={[rehypeRaw.default]}>
                     {displayedServiceMessage}
                  </ReactMarkdown>
               </div>}
            </Suspense>
            <ExpandAlertDetailsButton onClick={toggleIsExpanded} id={'migration-alert-bar-expand-message-button'}>
               {isExpanded ? t('ShowLess') : t('ShowMore')}
            </ExpandAlertDetailsButton>
         </AlertMessageWrapper>
         <CloseButton onClick={hideAlertBar} title={t('general.close')} id={'migration-alert-bar-close-button'}>
            <Glyphs.Close16x16 />
         </CloseButton>
      </MigrationAlertBarContainer>
   );

   return (
      <>
         {isDisplayed && MigrationAlertBarComponent}
      </>
   );
};

export interface IAlertBar {
   migrationAlertMessage: string;
}

export default MigrationAlertBar;
