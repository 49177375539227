import React, {createContext, ReactElement, useState} from 'react';
import {IQuizData} from '../model/quizModel';

const initialState: IQuizData = {
   CreateDate: '',
   HasBeenPublished: false,
   Id: '',
   IsGradable: false,
   QuestionSets: {},
   Title: '',
   ResultsUrl: '',
   EditUrl: '',
   IsQuizzing: false,
   RequireUserId: false
};

const store = createContext<IQuizData>(initialState);
const Provider = store.Provider;
let privateState = initialState;
let privateUpdateState: React.Dispatch<React.SetStateAction<IQuizData>> = () => null;
const publicUpdateState = (newState: Partial<IQuizData>) => {
   privateUpdateState(Object.assign({}, privateState, newState));
};

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<IQuizData>(initialState);
   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useQuizDataProvider,
   store as quizDataStore,
   publicUpdateState as updateQuizData
};
