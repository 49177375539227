import ApiUtil from '../util/ApiUtil';
import Constants from '../constants/Constants';
import {ITypeAheadRecord} from '../model/typeAheadRecordModel';
import {AxiosRequestConfig, CancelTokenSource} from 'axios';

const searchBaseUrl = '/api/v1/search';
const searchUsersEndpoint = `${searchBaseUrl}/users`;
const userRecordType = 'User';

const getTypeAheadSearchResults = async (search: string, cancelTokenSource: CancelTokenSource, recordType?: string): Promise<ITypeAheadRecord[]> => {
   const requestConfig: AxiosRequestConfig = {cancelToken: cancelTokenSource.token};
   let searchUrl = `${searchBaseUrl}?minimal=true&q=${search}&take=20`;
   if (recordType) {
      if (recordType === userRecordType) {
         searchUrl = `${searchUsersEndpoint}?minimal=true&q=${encodeURIComponent(search)}&take=20`;
      } else {
         searchUrl = `${searchUrl}&recordType=${recordType}`;
      }
   }
   const response = await ApiUtil.executeGetWithoutCacheAsync(searchUrl, [Constants.statusCodes.ok, Constants.statusCodes.partial], requestConfig);
   return response.data as ITypeAheadRecord[];
};

export default {
   getTypeAheadSearchResults,
   userRecordType
};
