import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {Radio, TscStyles, TscThemeName, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import cssConstants from '../../constants/cssConstants';

const PrivacyLevelWrapper = styled.div`
   display: flex;
   margin-top: 1rem;
`;

const SvgWrapper = styled.div<{themeName: TscThemeNames}>`
   padding-left: 1rem;
   svg {
      fill: ${props => props.themeName === TscThemeName.dusk ? TscStyles.color.text.lightMedium : TscStyles.color.text.medium};
   }
`;

const PrivacyTitle = styled.span`
   display: block;
   font-size: 0.875rem;
   font-weight: 600;
   margin-bottom: 0.5rem;

   & :first-child:after {
      content: ' ';
   }
`;

const PrivacyDescriptionText = styled.span<{theme: TscThemeNames}>`
   display: block;
   font-size: 0.875rem;
   font-weight: 400;
   color: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.text.lightMedium : TscStyles.color.text.medium};
   @media screen and (max-width: ${cssConstants.contentBreakpoint}px) {
      display: none;
   }
`;

const PrivacyTitleBadge = styled.span`
   border-radius: 10rem;
   background-color: ${TscStyles.color.message.caution};
   color: ${TscStyles.color.text.dark};
   padding: 2px 0.5rem;
   white-space: nowrap;
`;

const PrivacyDescriptionWrapper = styled.div`
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   padding-left: 1rem;
   width: 100%;
`;

const PrivacyRadioLabel = styled.label<{disabled: boolean}>`
   flex-grow: 1;
   display: flex;
   cursor: ${props => props.disabled ? 'auto' : 'pointer'};
`;

const disabledClickHandler = (): null => null;

const PrivacyOption: React.FC<IPrivacyOptionProps> = ({onClick, selected, header, text, glyph, radioTestId, theme, titleBadgeText, disabled = false}) => (
   <PrivacyLevelWrapper className="form-field-group">
      <Radio
         onChange={disabled ? disabledClickHandler : onClick}
         label={header}
         testId={radioTestId}
         isSelected={selected}
         themeName={theme}
         sharedId={radioTestId}
         disabled={disabled}
      />
      <PrivacyRadioLabel disabled={disabled} htmlFor={radioTestId}>
         <SvgWrapper themeName={theme}>
            {glyph}
         </SvgWrapper>
         <PrivacyDescriptionWrapper>
            <PrivacyTitle>
               <span>{header}</span>
               {!!titleBadgeText && (
                  <PrivacyTitleBadge>
                     {titleBadgeText}
                  </PrivacyTitleBadge>
               )}
            </PrivacyTitle>
            <PrivacyDescriptionText theme={theme}>
               {text}
            </PrivacyDescriptionText>
         </PrivacyDescriptionWrapper>
      </PrivacyRadioLabel>
   </PrivacyLevelWrapper>
);

export default PrivacyOption;

export interface IPrivacyOptionProps {
   onClick: () => void;
   selected: boolean;
   header: string;
   text: string;
   glyph: ReactNode;
   radioTestId: string;
   theme: TscThemeNames;
   disabled?: boolean;
   titleBadgeText?: string;
}
