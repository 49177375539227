import i18next from 'i18next';
import {IMediaDetails} from '../model/mediaDetails';
import {toast} from 'react-toastify';
import {IFolderTreeNode} from '../model/folderTreeNode';
import config from './config';
import {EntryPoint} from '../constants/Constants';

const displayAddToCollectionsSuccess = (collectionName: string) => {
   toast.success(i18next.t('collections.share.itemSuccess', {collectionName: collectionName}));
};

const getLibraryFolderName = () => config.entryPoint !== EntryPoint.library || config.libraryConfig?.loggedInUserIsProfileOwner ? i18next.t('MyLibrary') : i18next.t('LibraryForUser', {userDisplayName: config.libraryConfig?.profileDisplayName});

const displayMoveToFolderSuccess = (mediaList: IMediaDetails[], folderList: IFolderTreeNode[], destination: string) => {
   let source;
   if (mediaList.length) {
      source = mediaList[0].ParentFolder?.Name;
   } else {
      source = folderList[0].ParentFolder?.Name;
   }
   if (!source) {
      source = getLibraryFolderName();
   }

   if (!destination) {
      destination = getLibraryFolderName();
   }
   
   toast.success(i18next.t('folders.share.itemSuccess', {destination: destination}));
};

const displaySuccess = (successMessage: string, autoC: false|number = false) => {
   toast.success(successMessage, {autoClose: autoC});
};

const displayError = (errorMessage: string, autoC: false|number = false) => {
   toast.error(errorMessage, {autoClose: autoC});
};

export default {
   displayAddToCollectionsSuccess,
   displayMoveToFolderSuccess,
   displayError,
   displaySuccess
};
