import React from 'react';
import {TscButtonType} from '@techsmith/tsc-cloud-style-guide';
import {useTranslation} from 'react-i18next';
import {AlertModalPortal} from '../util/ModalPortalHelper';

const CollectionMembersWarningModal: React.FC<ICollectionChangeSelfWarningModalProps> = ({modalType, onConfirm, onClose}) => {
   const {t} = useTranslation();

   let title;
   let message;
   let confirmButtonLabel;
   if (modalType === CollectionMembersWarningModalType.RemoveSelf) {
      title = t('collection.modal.membersWarning.leaveTitle');
      message = t('collection.modal.membersWarning.leaveMessage');
      confirmButtonLabel = t('collection.modal.membersWarning.leaveButtonLabel');
   } else if (modalType === CollectionMembersWarningModalType.DemoteSelf) {
      title = t('collection.modal.membersWarning.changeRoleTitle');
      message = t('collection.modal.membersWarning.changeRoleMessage');
      confirmButtonLabel = t('collection.modal.membersWarning.changeRoleButtonLabel');
   } else if (modalType === CollectionMembersWarningModalType.DiscardChanges) {
      title = t('collection.modal.membersWarning.discardChangesTitle');
      message = t('collection.modal.membersWarning.discardChangesMessage');
      confirmButtonLabel = t('collection.modal.membersWarning.discardChangesButtonLabel');
   }

   return (
      <AlertModalPortal
         title={title}
         onClose={onClose}
         onConfirm={onConfirm}
         confirmButtonType={TscButtonType.destructive}
         confirmContent={confirmButtonLabel}
         onDismiss={onClose}
         dismissButtonType={TscButtonType.secondary}
         dismissContent={t('general.cancel')}
      >
         {message}
      </AlertModalPortal>
   );
};

export default CollectionMembersWarningModal;

export enum CollectionMembersWarningModalType {
   RemoveSelf,
   DemoteSelf,
   DiscardChanges
}

export interface ICollectionChangeSelfWarningModalProps {
   modalType: CollectionMembersWarningModalType;
   onConfirm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
   onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
