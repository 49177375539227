import React, {ReactComponentElement} from 'react';
import {useTranslation} from 'react-i18next';
import {ControlButton, TscButtonType, Label, StatusIndicator, TscStyles, TscThemeName, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import styled from 'styled-components';
import {userQuotaStore} from '../../context/userQuotaProvider';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {EntitlementType, IUserQuotas} from '../../model/userQuotas';
import config from '../../service/config';

const videoCountProgressBarHoverColorStop1 = '#F69B00';
const videoCountProgressBarHoverColorStop2 = '#D65E3A';

interface IUserQuotasUIColors {
   containerBg: string;
   labelText: string;
   progressBarBg: string;
   progressBarOutline: string;
   progressBarHoverColorStop1: string;
   progressBarHoverColorStop2: string;
}

const userQuotasUIThemes = {
   [TscThemeName.dawn]: {
      containerBg: TscStyles.color.ui.dawn.lightMedium,
      labelText: TscStyles.color.text.mediumDark,
      progressBarBg: TscStyles.color.ui.dawn.medium,
      progressBarOutline: TscStyles.color.ui.dawn.lightMedium,
      progressBarHoverColorStop1: videoCountProgressBarHoverColorStop1,
      progressBarHoverColorStop2: videoCountProgressBarHoverColorStop2
   } as IUserQuotasUIColors,
   [TscThemeName.dusk]: {
      containerBg: TscStyles.color.ui.dusk.medium,
      labelText: TscStyles.color.text.light,
      progressBarBg: TscStyles.color.ui.dusk.lightMedium,
      progressBarOutline: TscStyles.color.ui.dusk.medium,
      progressBarHoverColorStop1: videoCountProgressBarHoverColorStop1,
      progressBarHoverColorStop2: videoCountProgressBarHoverColorStop2
   } as IUserQuotasUIColors
};

interface IUserQuotasStyledComponentProps {
   theme: IUserQuotasUIColors;
}

export const UserQuotasWrapper = styled.div<{theme: IUserQuotasUIColors}>`
   display: flex;
   flex-direction: column;
   margin: 0;
   padding: .75rem 1rem;
   color: ${TscStyles.color.text.dark};
   background-color: ${(props: IUserQuotasStyledComponentProps) => props.theme.containerBg};
   border: none;
   border-radius: ${TscStyles.border.radius.lg};
`;

export const UsageIndicatorWrapper = styled.div<{theme: IUserQuotasUIColors}>`
   display: flex;
   flex-direction: column;

   .usage-indicator-label {
      color: ${(props: IUserQuotasStyledComponentProps) => props.theme.labelText};
      font-size: 0.875rem;
      font-weight: ${TscStyles.font.weight.semibold};
   }

   .current-plan-label {
      margin: 0 auto;
      color:  ${(props: IUserQuotasStyledComponentProps) => props.theme.labelText};
      font-size: .75rem;
      font-weight: ${TscStyles.font.weight.semibold};
   }

   .usage-indicator {
      height: .5rem;
      margin: .5rem 0;
      border-radius: ${TscStyles.border.radius.lg};
      border: none;

      .usage-indicator-progress {
         border-radius: ${TscStyles.border.radius.lg};
         border: none;
         ::-webkit-progress-bar {
            border: none;
            background-color: ${(props: IUserQuotasStyledComponentProps) => props.theme.progressBarBg};
            border-radius: ${TscStyles.border.radius.lg};
            outline: 2px ${(props: IUserQuotasStyledComponentProps) => props.theme.progressBarOutline} solid;
         }

         ::-webkit-progress-value {
            background: linear-gradient(45deg, ${(props: IUserQuotasStyledComponentProps) => props.theme.progressBarHoverColorStop1}, ${(props: IUserQuotasStyledComponentProps) => props.theme.progressBarHoverColorStop2});
            border-radius: ${TscStyles.border.radius.lg};
         }

         ::-moz-progress-bar {
            background: linear-gradient(45deg, ${(props: IUserQuotasStyledComponentProps) => props.theme.progressBarHoverColorStop1}, ${(props: IUserQuotasStyledComponentProps) => props.theme.progressBarHoverColorStop2});
            border-radius: ${TscStyles.border.radius.lg};
         }

         ::-ms-fill {
            border: none;
         }
      }

      .usage-indicator-count-container {
         display: none;
      }
   }
`;

export const UsageLabelsContainer = styled.div<{theme: IUserQuotasUIColors}>`
   display: flex;

   .video-count-status {
      font-size: 0.875rem;
      color:  ${(props: IUserQuotasStyledComponentProps) => props.theme.labelText};
      margin-left: auto;
      margin-right: 0;
   }
`;

export const CallToActionButtonContainer = styled.div`
   margin: .5rem 0 0 0;
`;

export const UserQuotasBase: React.FC<Partial<IUserQuotasStateMappedProps & IUserQuotasProps>> = ({className, theme, QuotaEntitlementType, CurrentVideoCount, VideoCountLimit, UserHasQuotaInformation}) => {
   const {t} = useTranslation();

   const cautionThreshold = .75;
   const warningThreshold = .90;

   const getScreencastTierLabel = (): string => {
      if (QuotaEntitlementType === EntitlementType.free) {
         return t('screencastTierPlanLabels.free');
      } else if (QuotaEntitlementType === EntitlementType.pro) {
         return t('screencastTierPlanLabels.pro');
      }
      return t('screencastTierPlanLabels.unlimited');
   };

   const getScreencastTierCallToActionButton = (): ReactComponentElement<any> => {
      if (QuotaEntitlementType === EntitlementType.free) {
         return (<CallToActionButtonContainer>
            <ControlButton buttonType={TscButtonType.primaryScreencast}
               isFullWidth={true}
               label={t('userQuotas.callToActionFreeTierLabel')}
               href={config.environmentData.ScreencastProPurchaseLinkUrl}
               openLinkInNewTab={true}
               testId={'upgrade-tier-button-link'}/>
         </CallToActionButtonContainer>);
      }
      return null;
   };

   const showQuotas = (): boolean => UserHasQuotaInformation && QuotaEntitlementType === EntitlementType.free;

   const hasScreencastFreeEntitlement = QuotaEntitlementType === EntitlementType.free;
   const videoUsageDetails = !hasScreencastFreeEntitlement ?
      t('userQuotas.usageDetailsUnlimited', {current: Math.round(CurrentVideoCount)}) :
      t('userQuotas.usageDetails', {current: CurrentVideoCount, max: Math.round(VideoCountLimit)});

   return showQuotas() ?
      <UserQuotasWrapper className={className} theme={userQuotasUIThemes[theme]}>
         <UsageIndicatorWrapper theme={userQuotasUIThemes[theme]}>
            <UsageLabelsContainer theme={userQuotasUIThemes[theme]}>
               <Label labelText={t('userQuotas.videoCountLabel')} className={'usage-indicator-label'} data-test-id={'video-count-label'}/>
               <Label labelText={videoUsageDetails} className={'video-count-status'} data-test-id={'user-quota-storage-usage-indicator-label'}/>
            </UsageLabelsContainer>
            <StatusIndicator
               value={CurrentVideoCount}
               max={VideoCountLimit}
               cautionCount={(VideoCountLimit * cautionThreshold)}
               warningCount={(VideoCountLimit * warningThreshold)}
               className={'usage-indicator'}
               data-test-id={'user-quota-storage-usage-indicator'} />
            <Label labelText={t('userQuotas.planInformation', {planLabel: getScreencastTierLabel()})} className={'current-plan-label'}/>
         </UsageIndicatorWrapper>
         {getScreencastTierCallToActionButton()}
      </UserQuotasWrapper> : <></>;
};

export interface IUserQuotasProps {
   className?: string;
   theme: TscThemeNames;
}

export interface IUserQuotasStateMappedProps {
   QuotaEntitlementType: EntitlementType;
   CurrentVideoCount: number;
   VideoCountLimit: number;
   UserHasQuotaInformation: boolean;
}

const mapStatesToProps = (
   {className, theme}: IUserQuotasProps,
   {QuotaEntitlementType, CurrentVideoCount, VideoCountLimit, UserHasQuotaInformation}: Partial<IUserQuotas>
): IUserQuotasStateMappedProps & IUserQuotasProps => ({className, theme, QuotaEntitlementType, CurrentVideoCount, VideoCountLimit, UserHasQuotaInformation});

export default withMemoizedContexts(mapStatesToProps, userQuotaStore)(UserQuotasBase);
