import React from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import {DragAndDropItemTypes} from '../model/dragAndDropItemTypes';
import DropFolderGlyph from '../static/svg/DropFolderGlyph.svg';
import DropMediaGlyph from '../static/svg/DropMediaGlyph.svg';
import DropMixedGlyph from '../static/svg/DropMixedGlyph.svg';

export const generateDragPreviewImage = (dragType: DragAndDropItemTypes) => {
   switch (dragType) {
      case DragAndDropItemTypes.Media: {
         return `data:image/svg+xml;base64,${btoa(renderToStaticMarkup(<DropMediaGlyph />))}`;
      }
      case DragAndDropItemTypes.Folder: {
         return `data:image/svg+xml;base64,${btoa(renderToStaticMarkup(<DropFolderGlyph />))}`;
      }
      case DragAndDropItemTypes.Mixed: {
         return `data:image/svg+xml;base64,${btoa(renderToStaticMarkup(<DropMixedGlyph />))}`;
      }
      default: {
         return null;
      }
   }
};
