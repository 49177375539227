import React from 'react';
import styled from 'styled-components';
import {Tabs, TscThemeName, TscStyles} from '@techsmith/tsc-cloud-style-guide';
import cssConstants from '../../constants/cssConstants';
import config from '../../service/config';
import MobileNavMenu from './MobileNavMenu';
import {getNavLinkTabs, getScreencastHeaderTabs} from '../../util/NavLinkTabsGenerator';

const NavLinkTabsContainer = styled.div`
   display: flex;
   color: ${TscStyles.color.text.mediumDark};
   height: inherit;
   overflow-x: hidden;
   overflow-y: hidden;
   align-items: center;
   padding: 0 1rem;

   span {
      color: ${TscStyles.color.text.light};
      font-size: .875rem;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: .06em;
   }

   div {
      height: inherit;

      a {
         height: 3rem;
         margin-top: .25rem;
         padding: 0 0.25rem;
         outline-offset: -0.125rem;

         &::after {
            margin: 0 .125rem;
         }
      }
   }

   @media screen and (max-width: ${cssConstants.headerBreakpoint}px) {
      display: none;
   }
`;

const MobileMenuButtonWrapper = styled.div`
   display: none;
   align-items: center;

   @media screen and (max-width: ${cssConstants.headerBreakpoint}px) {
      display: flex;
   }

   > button {
      padding: 0 0.25rem;
   }
`;

const NavLinkTabs: React.FC<INavLinkTabsProps> = ({hideTabs}) => (
   <>
      <MobileMenuButtonWrapper>
         <MobileNavMenu tabs={getNavLinkTabs(false)} />
      </MobileMenuButtonWrapper>
      {!hideTabs && <NavLinkTabsContainer>
         <Tabs isAnchor={true} tabs={config.featureSwitches.IsScreencast ? getScreencastHeaderTabs() : getNavLinkTabs(true)} selectedColor={TscStyles.color.brand.screencast} themeName={TscThemeName.dusk} />
      </NavLinkTabsContainer>}
   </>
);

export interface INavLinkTabsProps {
   hideTabs: boolean;
}

export default NavLinkTabs;
