import React, {createContext, ReactElement, useState} from 'react';
import {IEmojiReactionData} from '../model/emojiReactionsModel';
import conversationApi from '../service/conversationApi';

interface IReactionsProvider {
   reactions: IEmojiReactionData[];
}

const initialState: IReactionsProvider = {
   reactions: []
};

const store = createContext<IReactionsProvider>(initialState);
const Provider = store.Provider;

let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<IReactionsProvider>> = () => null;
const publicUpdateState = (newState: Partial<IReactionsProvider>) => privateUpdateState(Object.assign({}, privateState, newState));
const updateReactionsFromServer = async (conversationId: string) => publicUpdateState({reactions: await conversationApi.getReactions(conversationId)});

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<IReactionsProvider>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useReactionsProvider,
   store as reactionsStore,
   publicUpdateState as updateReactionsState,
   updateReactionsFromServer
};
