import React from 'react';
import styled from 'styled-components';
import {TscStyles} from '@techsmith/tsc-cloud-style-guide';
import cssConstants from '../../constants/cssConstants';
import config from '../../service/config';
import AlertBar from './AlertBar';
import MigrationAlertBar from './MigrationAlertBar';
import NavLinkTabs from './NavLinkTabs';
import SearchBar from './SearchBar';
import SettingsAndAccountWidget from './SettingsAndAccountWidget';
import SiteLogo from './SiteLogo';
import {GradientBorder} from '../util/StyledElements';
import TrialDetails from './TrialDetails';
import NotificationsWidget from './NotificationsWidget';
import {useTranslation} from 'react-i18next';
import {migrationStore} from '../../context/migrationProvider';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {setupCSSHeightObserver} from '../../util/CSSHeightObserver';

export const HeaderContainer = styled.div`
   display: flex;
   position: sticky;
   top: 0px;
   flex-direction: column;
   width: 100%;
   flex: 0 0 auto;
   z-index: ${cssConstants.zIndex.header};
   font-family: Figtree, Helvetica Neue, Helvetica, Arial, sans-serif;
   font-size: 0.875rem;
   box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
`;

export const StyledHeader = styled.header`
   display: flex;
   flex-direction: row;
   background-color: ${cssConstants.headerBackgroundColor};
   color: ${TscStyles.color.text.light};
   height: ${cssConstants.headerHeight};
   padding: 0 1rem;
   justify-content: space-between;
   align-items: center;
   box-sizing: border-box;
`;

export const HeaderSection = styled.div`
   display: flex;
   white-space: nowrap;
   flex: 0 1 33.33%;
   align-items: center;

   // IE 11 hax - Forces header sections to shrink "close to" correctly
   @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1;
   }
`;

const LeftSection = styled(HeaderSection)<{hideLogoOnShrink: boolean}>`
   justify-content: flex-start;
   order: 1;

   @media screen and (max-width: ${cssConstants.headerBreakpoint}px) {
      ${props => props.hideLogoOnShrink ?
      'display: none' : `
         margin-left: 0.75rem;
         flex: 1 1 33.33%;
         order: 2;
         `
}
   }

   @media screen and (max-width: ${cssConstants.headerLogoHideBreakpoint}px) {
      display: none;
      margin-left: 0.75rem;
      flex: 1 1 33.33%;
      order: 2;
   }
`;

const MiddleSection = styled(HeaderSection)`
   justify-content: center;
   height: 100%;
   flex: 1 0 33.33%;
   order: 2;

   @media screen and (max-width: ${cssConstants.headerBreakpoint}px) {
      justify-content: flex-start;
      flex: 0 1 auto;
      order: 1;
   }
`;

// the PopoverButton__MenuContainer is just to bump the settings menus down by 1-2 px to cover the addition of the single-px stripe we
// put across under the header.  coincidentally, the menus were lining up before we did that, this forces it to continue displaying like
// that even though these are really just standard popover buttons that are not designed to align with specific elements.
const RightSection = styled(HeaderSection)<{searchIsFocused: boolean}>`
   justify-content: flex-end;
   order: 3;

   @media screen and (min-width: ${cssConstants.contentBreakpoint - 1}px) {
      div[class^='PopoverButton__MenuContainer'] {
         border-radius: ${TscStyles.border.radius.lg};
         top: calc(100% + 11px);
         overflow: hidden; 
      }
   }

   > * {
      margin: 0 0.25rem;

      &:first-child {
         margin-left: 0;
      }

      &:last-child {
         margin-right: 0;
      }
   }

   @media screen and (max-width: ${cssConstants.headerSearchFocusBreakpoint}px) {
      > * {
         transition: visibility 0s linear 0.3s, opacity 0.3s, width 0.2s ease-in-out;
         transition-delay: 0s;
         visibility: visible;
         opacity: 1;

         :not(:first-child) {
            ${props => props.searchIsFocused && 'opacity: 0;'};
            ${props => props.searchIsFocused && 'width: 0;'};
            ${props => props.searchIsFocused && 'visibility: hidden;'};
            ${props => props.searchIsFocused && 'overflow: hidden;'};
            ${props => props.searchIsFocused && 'margin: 0;'};
            ${props => props.searchIsFocused && 'padding: 0;'};
         }
      }
   }
`;

export const HeaderBase: React.FC<IHeaderProps & IStateMappedProps> = ({hideSearch, hideThemePicker, hideEntitlementInfo, hideNavLinkTabs, migrationInProgress}) => {
   const showTrialDetailsComponent = config.user.SiteAccessEntitlement.IsInTrial && !hideEntitlementInfo;
   const hideLogoOnShrink = !hideSearch || !hideEntitlementInfo;
   const [searchIsFocused, setSearchIsFocused] = React.useState(false);
   const {t} = useTranslation();
   const headerRef = React.useRef<HTMLDivElement>(null);

   const showNotificationsWidget = !!config.user.IsSignedIn &&
      config.featureSwitches.EnableInAppNotifications &&
      (config.featureSwitches.IsScreencast || config.user.SiteAccessEntitlement.IsActive);

   setupCSSHeightObserver(headerRef, cssConstants.variables.headerHeight);

   return (
      <HeaderContainer ref={headerRef}>
         {config.environmentData.CustomerServiceMessage && <AlertBar customerServiceMessage={config.environmentData.CustomerServiceMessage} />}
         {migrationInProgress && <MigrationAlertBar migrationAlertMessage={t('migration.message.alertBanner')} />}

         <StyledHeader>
            <LeftSection hideLogoOnShrink={hideLogoOnShrink}>
               <SiteLogo isSideBar={false}/>
            </LeftSection>
            <MiddleSection>
               {config.user.IsSignedIn && <NavLinkTabs hideTabs={hideNavLinkTabs} />}
            </MiddleSection>
            <RightSection searchIsFocused={searchIsFocused}>
               {!!showTrialDetailsComponent && <TrialDetails numberOfDaysLeft={config.user.SiteAccessEntitlement.DaysLeftInEntitlement} purchaseLink={!config.user.SiteAccessEntitlement.HasPurchased && config.environmentData.KnowmiaProPurchaseLinkUrl} />}
               {!hideSearch && <SearchBar onSearchFocus={() => {
                  setSearchIsFocused(true);
               }} onSearchBlur={() => {
                  setSearchIsFocused(false);
               }} />}
               {showNotificationsWidget && <NotificationsWidget
                  currentUserId={config.user.TechSmithId}
               />}
               <SettingsAndAccountWidget hideThemePicker={hideThemePicker} />
            </RightSection>
         </StyledHeader>
         <GradientBorder />
      </HeaderContainer>
   );
};

export interface IHeaderProps {
   hideSearch: boolean;
   hideThemePicker: boolean;
   hideEntitlementInfo: boolean;
   hideNavLinkTabs: boolean;
}

export interface IStateMappedProps {
   migrationInProgress: boolean;
}

const mapStatesToProps = (
   {hideSearch, hideThemePicker, hideEntitlementInfo, hideNavLinkTabs}: IHeaderProps,
   {migrationInProgress}: Partial<IStateMappedProps>
): IHeaderProps & IStateMappedProps => ({hideSearch, hideThemePicker, hideEntitlementInfo, hideNavLinkTabs, migrationInProgress});

export default withMemoizedContexts(mapStatesToProps, migrationStore)(HeaderBase);
