import {AxiosResponse} from 'axios';
import UserNotFoundError from '../class/userNotFoundError';
import Constants from '../constants/Constants';
import ApiUtil from '../util/ApiUtil';
import i18n from 'i18next';
import logging from './logging';

const baseUrl = '/api/v1/account';

interface UserResponse {
   TechSmithId: string;
}

const getTechSmithIdFromEmail = async (email: string): Promise<string> => {
   try {
      const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(`${baseUrl}/user/${email}`, [Constants.statusCodes.ok]);
      const data: UserResponse = response.data;
      return data.TechSmithId;
   } catch {
      const errorMessage = i18n.t('general.userNotFoundErrorEmail', {0: email});
      logging.error(errorMessage);
      throw new UserNotFoundError(errorMessage);
   }
};

const requestVerificationEmail = async (email: string): Promise<void> => {
   await ApiUtil.executePostAsync(`${baseUrl}/verificationemail`, [Constants.statusCodes.ok], {emailAddress: email});
};

export default {
   getTechSmithIdFromEmail,
   requestVerificationEmail
};
