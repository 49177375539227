import React, {useEffect, useRef, useState} from 'react';
import {AlertModal, BasicModal, IAlertModalProps, IBasicModalProps} from '@techsmith/tsc-cloud-style-guide';
import {createPortal} from 'react-dom';
import cssConstants from '../../constants/cssConstants';
import Constants from '../../constants/Constants';
import logging from '../../service/logging';

const modalRootId = 'modal-root';

const ModalPortal: React.FC = ({children}) => {
   const portalRef = useRef(null);
   const [isMounted, setIsMounted] = useState(false);

   const insertNewModalRoot = (): HTMLElement => {
      const modalRoot = document.createElement('div');
      modalRoot.setAttribute('id', modalRootId);
      const mainElement = document.getElementsByClassName(Constants.mainElementClassName);
      if (mainElement.length > 0) {
         mainElement[0].insertBefore(modalRoot, null);
      } else {
         logging.error('Could not find main element to insert modal');
      }
      return modalRoot;
   };

   useEffect(() => {
      if (!portalRef.current) {
         portalRef.current = document.getElementById(modalRootId) || insertNewModalRoot();
      }

      setIsMounted(true); // Setting state to trigger re-render
   }, []);

   return isMounted ? createPortal(children, portalRef.current) : null;
};

const AlertModalPortal = (props: IAlertModalProps) => <ModalPortal><AlertModal zIndex={cssConstants.zIndex.alertModal} {...props} /></ModalPortal>;
const BasicModalPortal = (props: IBasicModalProps) => <ModalPortal><BasicModal zIndex={cssConstants.zIndex.basicModal} {...props} /></ModalPortal>;

export {AlertModalPortal, BasicModalPortal};
