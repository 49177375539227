import {IBaseAppModel, ILauncherModel, IEmbedAppModel, ILibraryModel, IRegisterModel, IWatchAppModel, ISettingsAppModel, IStatusModel, IMigrationMediaDownloadModel} from './model/appModel';
import decode from 'entity-decode';
import displayHeader from './indexHeader';
import * as localization from './service/localization';
import {offerRefresh} from './util/ImportHelper';

interface MyWindow extends Window {
   getPwcConfig(): string;
   displayHeader(): void;
   pwcLoadContinuationPromise(): Promise<void>|undefined;
}

declare let window: MyWindow;

if (!window.getPwcConfig || typeof window.getPwcConfig !== 'function') {
   throw new Error('Cannot initialize presentation-web-client app, the function window.getPwcConfig was not found.');
}

const config = JSON.parse(decode(window.getPwcConfig()));

const loadWatch = document.getElementById('root');
const loadFeed = window.document.getElementById('feed-root');
const loadLibrary = window.document.getElementById('library-root');
const loadRegister = window.document.getElementById('register-root');
const loadLauncher = window.document.getElementById('launcher-root');
const loadEmbed = window.document.getElementById('embed-root');
const loadSettings = window.document.getElementById('settings-root');
const loadStatus = window.document.getElementById('status-root');
const loadWhatsNew = window.document.getElementById('whats-new-root');
const loadNoAccess = window.document.getElementById('no-access-root');
const loadMigrationMediaDownload = window.document.getElementById('migration-media-download-root');
const baseAppModel: IBaseAppModel = config;
// The expectation is that URLs will not end with a trailing slash. Enforce that here.
baseAppModel.EnvironmentData.CdnUrl = baseAppModel.EnvironmentData.CdnUrl?.replace(/\/$/, '');
baseAppModel.EnvironmentData.MyAccountUrl = baseAppModel.EnvironmentData.MyAccountUrl?.replace(/\/$/, '');
baseAppModel.EnvironmentData.PresentationRootUrl = baseAppModel.EnvironmentData.PresentationRootUrl?.replace(/\/$/, '');
baseAppModel.EnvironmentData.ProfileApiUrl = baseAppModel.EnvironmentData.ProfileApiUrl?.replace(/\/$/, '');
baseAppModel.EnvironmentData.RelayServerRootUrl = baseAppModel.EnvironmentData.RelayServerRootUrl?.replace(/\/$/, '');
baseAppModel.EnvironmentData.ResultsRootUrl = baseAppModel.EnvironmentData.ResultsRootUrl?.replace(/\/$/, '');
baseAppModel.EnvironmentData.QuizzingServiceUrl = baseAppModel.EnvironmentData.QuizzingServiceUrl?.replace(/\/$/, '');
baseAppModel.EnvironmentData.TscNotificationEndPointUrl = baseAppModel.EnvironmentData.TscNotificationEndPointUrl?.replace(/\/$/, '');
baseAppModel.EnvironmentData.VideoReviewApiUrl = baseAppModel.EnvironmentData.VideoReviewApiUrl?.replace(/\/$/, '');

// @ts-ignore
// eslint-disable-next-line camelcase, no-undef
__webpack_public_path__ = `${baseAppModel.EnvironmentData.CdnUrl || ''}/`;

// eslint-disable-next-line @typescript-eslint/no-floating-promises
localization.init(baseAppModel).then(async () => {
   await (window.pwcLoadContinuationPromise ?? Promise.resolve());

   if (loadWatch) {
      import(/* webpackChunkName: "watch"*/ './index').then(displayApp => {
         displayApp.default(baseAppModel as IWatchAppModel);
      }).catch(offerRefresh);
   } else if (loadEmbed) {
      import(/* webpackChunkName: "embed"*/ './indexEmbed').then(displayEmbed => {
         displayEmbed.default(baseAppModel as IEmbedAppModel);
      }).catch(offerRefresh);
   } else if (loadFeed) {
      import(/* webpackChunkName: "feed"*/ './indexFeed').then(displayFeed => {
         displayFeed.default(baseAppModel as ILibraryModel);
      }).catch(offerRefresh);
   } else if (loadLibrary) {
      import(/* webpackChunkName: "library"*/ './indexLibrary').then(displayLibrary => {
         displayLibrary.default(baseAppModel as ILibraryModel);
      }).catch(offerRefresh);
   } else if (loadRegister) {
      import(/* webpackChunkName: "register"*/ './indexRegister').then(displayRegister => {
         displayRegister.default(baseAppModel as IRegisterModel);
      }).catch(offerRefresh);
   } else if (loadLauncher) {
      import(/* webpackChunkName: "launcher"*/ './indexLauncher').then(displayLauncher => { // tslint:disable-line space-in-parens
         displayLauncher.default(config as ILauncherModel);
      }).catch(offerRefresh);
   } else if (loadStatus) {
      import(/* webpackChunkName: "status"*/ './indexStatus').then(displayStatus => { // tslint:disable-line space-in-parens
         displayStatus.default(config as IStatusModel);
      }).catch(offerRefresh);
   } else if (loadMigrationMediaDownload) {
      import(/* webpackChunkName: "migrationMedia"*/ './indexMigrationMediaDownload').then(migrationMediaDownload => { // tslint:disable-line space-in-parens
         migrationMediaDownload.default(config as IMigrationMediaDownloadModel);
      }).catch(offerRefresh);
   } else if (loadSettings) {
      import(/* webpackChunkName: "status"*/ './indexSettings').then(displaySettings => { // tslint:disable-line space-in-parens
         displaySettings.default(config as ISettingsAppModel);
      }).catch(offerRefresh);
   } else if (loadWhatsNew) {
      import(/* webpackChunkName: "status"*/ './indexWhatsNew').then(displayWhatsNew => { // tslint:disable-line space-in-parens
         displayWhatsNew.default(config as IBaseAppModel);
      }).catch(offerRefresh);
   } else if (loadNoAccess) {
      import(/* webpackChunkName: "status"*/ './indexNoAccess').then(displayNoAccess => { // tslint:disable-line space-in-parens
         displayNoAccess.default(config as IBaseAppModel);
      }).catch(offerRefresh);
   } else {
      displayHeader(baseAppModel);
   }
});

export default {};
