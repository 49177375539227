import React from 'react';
import {useTranslation} from 'react-i18next';
import {Glyphs, TscPopoverOrigin, PopoverButton, TscThemeName, TscButtonType} from '@techsmith/tsc-cloud-style-guide';
import SettingsMenu from './SettingsMenu';
import cssConstants from '../../constants/cssConstants';

export interface ISettingsProps {
   hideThemePicker?: boolean;
}

const Settings: React.FC<ISettingsProps> = ({hideThemePicker}) => {
   const {t} = useTranslation();

   return (<PopoverButton
      buttonType={TscButtonType.tertiary} // TODO:  is this right
      buttonGlyph={<Glyphs.Settings16x16 />}
      origin={TscPopoverOrigin.bottomRight}
      popoverMenu={<SettingsMenu hideThemePicker={hideThemePicker} />}
      themeName={TscThemeName.dusk}
      showArrow={false}
      closeMenuOnClick={true}
      buttonTitle={t('Settings')}
      testId="settings"
      useMobileMenu={true}
      mobileContextMenuZindex={cssConstants.zIndex.mobileContextMenu}
      mobileContextMenuTitle={t('Settings')}
      width={cssConstants.glyphButtonWidth}
   />);
};

export default Settings;
