import React, {createContext, ReactElement, useState} from 'react';
import quizApi from '../service/quizApi';
import {IMediaAnalyticsModel} from '../model/mediaAnalyticsModel';
import profileApi from '../service/profileApi';

interface IAnalyticsData {
   analyticsData: IMediaAnalyticsModel[];
}

const initialState: IAnalyticsData = {
   analyticsData: null
};

const store = createContext<IAnalyticsData>(initialState);
const Provider = store.Provider;

let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<IAnalyticsData>> = () => null;
const publicUpdateState = (newState: IMediaAnalyticsModel[]) => privateUpdateState(Object.assign({}, privateState, {analyticsData: newState}));
const updateAnalyticsDataFromServer = async (mediaId: string) => publicUpdateState(await ensureUserDisplayNamePopulated((await quizApi.getMediaAnalytics(mediaId)).UserMediaAnalytics));

const ensureUserDisplayNamePopulated = async (analytics: IMediaAnalyticsModel[]): Promise<IMediaAnalyticsModel[]> => await Promise.all(analytics.map(async user => {
   if (!user.DisplayName && !user.FirstName && !user.LastName) {
      const userInfo = await profileApi.getUserProfileInfo(user.TechSmithId);
      return Object.assign({}, user, {DisplayName: userInfo.displayName});
   }

   return user;
}));

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<IAnalyticsData>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useAnalyticsDataProvider,
   store as analyticsDataStore,
   publicUpdateState as UpdateAnalyticsDataState,
   updateAnalyticsDataFromServer
};
