import {IMinimalFolder} from './minimalFolder';
import Optional from './optional';
import {ICollectionModel} from './appModel';
import {IBaseUser, nullBaseUserFactory} from './baseUser';
import {CaptioningState, CaptioningTypes} from './captionModels';
import {IBaseLibrary} from './library';
import {IWorkerState} from './workerState';
import {CreationProducts} from '../constants/Constants';

type IMediaDetailsFieldTypes = string | number | boolean | CaptioningState | ICollectionModel[] | IMediaChapters | Optional<string> | Optional<IMinimalFolder> | Optional<Date> | string[] | Optional<IBaseLibrary> | IWorkerState;

export enum MediaPrivacy {
   private = 'Private',
   unknown = 'Unknown',
   public = 'Public',
   listed = 'Listed',
   unlisted = 'Unlisted'
}

export interface ITransferMediaDetails {
   Duration: string;
   ComputerName: string;
   Status: number;
   Progress: number;
}

export interface IFeedMediaSummary extends IMediaDetails {
   CollectionTitle: string;
   CollectionUrl: string;
   CollectionShareDateTime: Date;
}

export interface IMediaSummary {
   Hash: string;
   StorageFileId: string;
   OriginalStorageFileId: string;
   QuizId: string;
   Title: string;
   Privacy: MediaPrivacy;
   ShareDateTime: Date;
   UploadDateTime: Date;
   Owner: IBaseUser;
   ViewCount: number;
   ParentFolder: Optional<IMinimalFolder>;
}

export interface IMediaChapter {
   timeInMilliseconds: number;
   label: string;
}

export interface IMediaChapters {
   version: string;
   chapters: IMediaChapter[];
}

export interface IMediaDetails {
   GroupHashes: string[];
   OwnerLibrary: Optional<IBaseLibrary>;
   MediaId: string;
   OriginalStorageFileId: string;
   MediaUrn: string;
   OriginatorId: string;
   ParentFolder: Optional<IMinimalFolder>;
   QuizId: string;
   CreationProduct: CreationProducts;
   UploadDateTime: Date;
   SharedDateTime: Date;
   RecordedDateTime: Date;
   AddedToGroupDateTime: Optional<Date>;
   ThumbnailUrl: string;
   ContentUrl: string;
   ContentType: string;
   HlsTokenInjectionProxyContentUrl: string;
   IsDownloadable: boolean;
   DownloadEnabled: boolean;
   WasCopied: boolean;
   Title: string;
   IsReady: boolean;
   IsVideo: boolean;
   IsImage: boolean;
   IsDecorated: boolean;
   IsQuizEditingSupported: boolean;
   Url: string;
   PosterId: string;
   PosterUrl: string;
   SpriteId: string;
   SpriteUrl: string;
   XmpId: string;
   XmpUrl: string;
   IsEncoding: boolean;
   EncodingProgress: number; // todo: maybe null
   HasEncodingError: boolean;
   XmpUrn: string;
   Privacy: MediaPrivacy;
   Chapters?: IMediaChapters;
   Hash: string;
   ViewCount: number;
   Author: string;
   Description: string;
   Duration: string;
   SizeInBytes: number;
   Width: number;
   Height: number;
   Rotate: number;
   FlipXAxis: boolean;
   YouTubeUrl: Optional<string>;
   YouTubeAuthor: Optional<string>;
   IsYouTube: boolean;
   ProjectPublishState: string;
   CaptioningAssignedToEmail: Optional<string>;
   CaptioningState: CaptioningState;
   CaptioningType: CaptioningTypes;
   AudioDescriptionId: string;
   AudioDescriptionUrl: string;
   DownloadUrl: string;
   HasPublishedCaptions: boolean;
   HasAccessPhrase: boolean;
   AllowViewerToFastForwardVideo: boolean;
   PreviewThumbnailsUri: string;
   PreviewThumbnailsInterval: number;
   PreviewThumbnailsMaxColumns: number;
   PreviewThumbnailsTechSmithFileId: string;
   PreviewThumbnailsThumbHeight: number;
   PreviewThumbnailsThumbWidth: number;
   ExcludeFromQuota?: boolean;
   [key: string]: IMediaDetailsFieldTypes;
}

export const nullMediaSummaryFactory = (mediaSummary: Partial<IMediaSummary> = {}): IMediaSummary => {
   const nullMediaSummary = {
      Hash: '',
      StorageFileId: '',
      OriginalStorageFileId: '',
      QuizId: '',
      Title: '',
      Privacy: MediaPrivacy.unknown,
      ShareDateTime: new Date(),
      UploadDateTime: new Date(),
      Owner: nullBaseUserFactory(),
      ViewCount: 0,
      ParentFolder: {}
   };

   return Object.assign({}, nullMediaSummary, mediaSummary);
};
