export interface IBaseUser {
   TechSmithId: string;
   EmailAddress: string;
   DisplayName: string;
}

export const nullBaseUserFactory = (userData: Partial<IBaseUser> = {}): IBaseUser => {
   const nullUserData = {
      TechSmithId: '',
      EmailAddress: '',
      DisplayName: ''
   };

   return Object.assign({}, nullUserData, userData);
};
