// Derived from https://stackoverflow.com/questions/41102060/typescript-extending-error-class

class MemberAlreadyExistsError extends Error {
   constructor(message?: string) {
      super(message); // 'Error' breaks prototype chain here
      this.name = 'MemberAlreadyExistsError';
      Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
   }
}

export default MemberAlreadyExistsError;