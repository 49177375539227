import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ControlButton, TscButtonType, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import cssConstants from '../../constants/cssConstants';
import {MinWidthButtonWrapper, StyledWarning} from '../util/StyledElements';
import '../../static/css/modal.less';
import {IWorkerState} from '../../model/workerState';
import UserEmailInput from '../details/UserEmailInput';
import styled from 'styled-components';
import {BasicModalPortal} from '../util/ModalPortalHelper';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';
import folderApi from '../../service/folderApi';
import {IFolderTreeNode} from '../../model/folderTreeNode';
import {IMediaDetails} from '../../model/mediaDetails';
import mediaApi from '../../service/mediaApi';

const ButtonContainer = styled.div`
   margin-top: 1rem;
`;

export const ChangeOwnerModalBase: React.FC<IChangeOwnerModalProps & IStateMappedProps> = ({onClose, onChangeOwner, selectedFolders, selectedMedia, theme}) => {
   const {t} = useTranslation();
   const [email, setEmail] = useState('');
   const [hasValidEmail, setHasValidEmail] = useState(false);

   const [workerState, setWorkerState] = useState<IWorkerState>({
      isWorking: false,
      hasError: false
   });

   const onSubmit = async () => {
      setWorkerState({isWorking: true, hasError: false});
      try {
         const changeOwnerFolderPromises = selectedFolders.map(f => folderApi.changeOwner(f.Hash, email));
         const changeOwnerMediaPromises = selectedMedia.map(m => mediaApi.changeOwner(m.Hash, email));
         await Promise.all(changeOwnerFolderPromises.concat(changeOwnerMediaPromises));

         onChangeOwner();

         onClose(true);
      } catch (e) {
         setWorkerState({isWorking: false, hasError: true});
      }
   };

   const onEmailChange = (newEmail: string, isValid: boolean) => {
      setEmail(newEmail);
      setHasValidEmail(isValid);
   };

   return (
      <BasicModalPortal
         isLoading={workerState.isWorking}
         visible={true}
         title={t('changeOwner.modalTitle')}
         width={cssConstants.defaultModalWidth}
         onClose={() => onClose(false)}
         themeName={theme}
      >
         <div className="modal-body">
            <div className="form-body themeable-section">
               {workerState.hasError && <StyledWarning>{t('general.errorText')}</StyledWarning>}
               <>
                  <label htmlFor="change-owner-modal">{t('changeOwner.modalPrompt')}</label>
                  <UserEmailInput textInputId="change-owner-modal" onEmailChange={onEmailChange} />
                  <ButtonContainer className="button-group">
                     <MinWidthButtonWrapper>
                        <ControlButton
                           label={t('general.close')}
                           buttonType={TscButtonType.secondary}
                           themeName={theme}
                           onClick={() => onClose(false)}
                        />
                        <ControlButton
                           label={t('changeOwner.submitButtonText')}
                           buttonType={TscButtonType.primary}
                           themeName={theme}
                           onClick={onSubmit}
                           disabled={!hasValidEmail}
                        />
                     </MinWidthButtonWrapper>
                  </ButtonContainer>
               </>
            </div>
         </div>
      </BasicModalPortal>
   );
};

ChangeOwnerModalBase.defaultProps = {
   selectedFolders: [],
   selectedMedia: []
};
export interface IChangeOwnerModalProps {
   onClose(changeOwnerPerformed: boolean): void;
   onChangeOwner(): void;
   selectedFolders?: IFolderTreeNode[];
   selectedMedia?: IMediaDetails[];
}

export interface IStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {onClose, onChangeOwner, selectedFolders, selectedMedia}: IChangeOwnerModalProps,
   {theme}: Partial<IStateMappedProps>
): IStateMappedProps & IChangeOwnerModalProps => ({onClose, onChangeOwner, selectedFolders, selectedMedia, theme});

export default withMemoizedContexts(mapStatesToProps, themeStore)(ChangeOwnerModalBase);
