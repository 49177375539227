import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ICollectionModel} from '../../model/appModel';
import {CollectionPrivacyTypes, CollectionRoleTypes} from '../../constants/Constants';
import {Glyphs, MenuItem} from '@techsmith/tsc-cloud-style-guide';
import CollectionLeaveGlyph from '../../static/svg/leave-16x16.svg';
import DeleteModal from '../modals/DeleteModal';
import LeaveCollectionModal from '../modals/LeaveCollectionModal';
import {removeCollectionFromState, getCollectionDetailsById} from '../../context/collectionsProvider';
import ShareCollectionModal from '../modals/ShareCollectionModal';

export const CollectionMoreMenuBase: React.FC<ICollectionMoreMenuProps> = ({collection, hideShareOption}) => {
   const {t} = useTranslation();
   const [showShareModal, setShowShareModal] = useState(false);
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [showLeaveModal, setShowLeaveModal] = useState(false);
   const [shareModalCollection, setShareModalCollection] = useState<ICollectionModel>();

   const openShareModal = async (selectedCollection: ICollectionModel) => {
      setShareModalCollection(await getCollectionDetailsById(selectedCollection.Id));
      setShowShareModal(true);
   };

   return (<>
      {!hideShareOption && collection.Privacy !== CollectionPrivacyTypes.invite && <MenuItem key="share" label={t('collections.options.share')} glyph={<Glyphs.Share16x16/>} onClick={() => openShareModal(collection)} />}
      {collection.Role === CollectionRoleTypes.manager && <MenuItem key="delete" label={t('collections.options.delete')} glyph={<Glyphs.Delete16x16/>} onClick={() => setShowDeleteModal(true)} />}
      {collection.CanLeave && <MenuItem key="leave" label={t('collections.options.leave')} glyph={<CollectionLeaveGlyph/>} onClick={() => setShowLeaveModal(true)} />}
      {!hideShareOption && showShareModal && <ShareCollectionModal onClose={() => setShowShareModal(false)} collection={shareModalCollection} />}
      {showLeaveModal && <LeaveCollectionModal collection={collection} onClose={() => setShowLeaveModal(false)}/>}
      {showDeleteModal && <DeleteModal onClose={() => setShowDeleteModal(false)} onDelete={() => removeCollectionFromState(collection, true)} collectionsToDelete={[collection]} title={t('collections.delete.title')} warning={t('collections.delete.warning', {collectionName: collection.Name})} />}
   </>);
};

export interface ICollectionMoreMenuProps {
   collection: ICollectionModel;
   hideShareOption: boolean;
}

export default CollectionMoreMenuBase;
