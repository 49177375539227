import {MutableRefObject, useEffect, useState} from 'react';
import {useDebounce} from 'usehooks-ts';

export const setupCSSHeightObserver = (ref: MutableRefObject<HTMLDivElement>, cssVariable: string) => {
   const debounceMs = 50;
   const [height, setHeight] = useState(0);
   const debouncedHeight = useDebounce(height, debounceMs); // 50ms debounce
   
   const calculateHeight = (target: HTMLElement) => {
      const style = window.getComputedStyle(target);
      return parseInt(style.getPropertyValue('margin-top'), 10) + parseInt(style.getPropertyValue('margin-bottom'), 10) + target.offsetHeight;
   };

   useEffect(() => {
      document.documentElement.style.setProperty(cssVariable, `${debouncedHeight}px`);
   }, [debouncedHeight]);

   useEffect(() => {
      const observer = new ResizeObserver((changes: [ResizeObserverEntry]) => setHeight(calculateHeight(changes[0].target as HTMLElement)));
      observer.observe(ref.current);
      return () => observer.disconnect();
   }, []);
};
