import React, {Suspense, lazy} from 'react';
import {INotificationServiceEventResponse, INotificationServiceEventsResponse, NotificationModel} from '@techsmith/notifications-client';
import {Glyphs, TscStyles, TscThemeName} from '@techsmith/tsc-cloud-style-guide';
import userApi from '../../service/userApi';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import logging from '../../service/logging';
import config from '../../service/config';
import {importNotificationsClientPlugin} from '../../hooks/dynamicImport';
import styled from 'styled-components';

const FallbackWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 32px;
   height: 32px;

   svg {
      padding: .5rem;
      width: 16px;
      height: 16px;
   }
`;

const apiGetNotificationsOverride = async (_: string, __: string, cursor?: string, limit?: number): Promise<INotificationServiceEventsResponse> => await userApi.getNotifications(cursor, limit);

const apiGetUnreadNotificationsCountOverride = async (): Promise<number> => await userApi.getNotificationsCount();

const apiMarkAllAsReadOverride = async (): Promise<void> => await userApi.markAllNotificationsRead();

const apiMarkAsReadOverride = async (notificationId: string): Promise<void> => await userApi.markNotificationRead(notificationId);

const notificationTranslationHelper = async (t: TFunction<'translation'>, notificationResponse: INotificationServiceEventResponse, notificationType: string, hideDisplayName?: boolean, urlOverride?: string|null): Promise<NotificationModel> => {
   const notificationModel: NotificationModel = Object.assign({}, notificationResponse, {
      header: t(`notification.${notificationType}.header`, notificationResponse.props),
      body: t(`notification.${notificationType}.body`, notificationResponse.props)
   });
   if (hideDisplayName) {
      notificationModel.hideDisplayName = true;
   }
   // note: null and undefined are different cases, explicitly passing in null to this func means 'disable' the url link from the
   // original message, not passing it in (leaving as undefined) means leave the original message url intact with no changes
   // eslint-disable-next-line no-undefined
   if (urlOverride !== undefined) {
      notificationModel.url = urlOverride;
   }
   return Promise.resolve(notificationModel);
};

const notificationTranslationOverride = async (notificationResponse: INotificationServiceEventResponse, t: TFunction<'translation'>): Promise<NotificationModel> => {
   // note: we can modify the url props here to affect where these link, or disable it altogether
   switch (notificationResponse.eventType.toLowerCase()) {
      case 'captioningAssigned'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'captioningAssigned', true, notificationResponse.props.CallToActionUrl as string);
      case 'captionsPublished'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'captionsPublished', true, `${notificationResponse.props.MediaUrl as string}?view=editDetails`);
      case 'collectionInvite'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'collectionInvite', true, notificationResponse.props.CallToActionUrl as string);
      case 'collectionMediaAdded'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'collectionMediaAdded', false, notificationResponse.props.CollectionUrl as string);
      case 'encodingFailed'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'encodingFailed', true, notificationResponse.props.CallToActionUrl as string);
      case 'expirationWarning'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'expirationWarning', true, notificationResponse.props.CallToActionUrl as string);
      case 'featureSwitchChanged'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'featureSwitchChanged', true, null);
      case 'firstUpload'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'firstUpload', true, notificationResponse.props.MediaUrl as string);
      case 'firstView'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'firstView', true, notificationResponse.props.MediaUrl as string);
      case 'mediaReady'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'mediaReady', true, notificationResponse.props.MediaUrl as string);
      case 'newComment'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'newComment', false);
      case 'newCommentReply'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'newCommentReply', false);
      case 'quotaReached'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'quotaReached', true, notificationResponse.props.CallToActionUrl as string);
      case 'quotaWarning'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'quotaWarning', true, notificationResponse.props.CallToActionUrl as string);
      case 'transcriptionAutomaticComplete'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'transcriptionAutomaticComplete', true, notificationResponse.props.CallToActionUrl as string);
      case 'transcriptionAutomaticFailed'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'transcriptionAutomaticFailed', true, notificationResponse.props.MediaUrl as string);
      case 'transcriptionThirdPartyDenied'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'transcriptionThirdPartyDenied', true, notificationResponse.props.MediaUrl as string);
      case 'transcriptionThirdPartyFailed'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'transcriptionThirdPartyFailed', true, notificationResponse.props.MediaUrl as string);
      case 'transcriptionThirdPartyRequested'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'transcriptionThirdPartyRequested', true, notificationResponse.props.CallToActionUrl as string);
      case 'uploadFailed'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'uploadFailed', true);
      case 'userAdd'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'userAdd', true);
      case 'userDelete'.toLowerCase():
         return await notificationTranslationHelper(t, notificationResponse, 'userDelete', true);
      default:
         logging.warn(`An unmapped notification of has been detected, marking as read, notification info [${JSON.stringify(notificationResponse)}]`);
         return Object.assign({}, notificationResponse, {
            hideNotification: true,
            header: notificationResponse.eventType,
            body: `You received a message of unexpected type ${notificationResponse.eventType} with props ${JSON.stringify(notificationResponse.props)}`
         });
   }
};

const NotificationsWidget: React.FC<INotificationsWidgetProps> = ({currentUserId}) => {
   const {t} = useTranslation();
   
   const NotificationsClientPlugin = lazy(() => importNotificationsClientPlugin());

   return (
      <div className="notifications-button">
         <Suspense fallback={<FallbackWrapper><Glyphs.Alert16x16/></FallbackWrapper>}>
            <NotificationsClientPlugin themeName={TscThemeName.dusk} // theme is hardcoded to dusk because header is dusk
               serviceName={config.featureSwitches.IsScreencast ? 'Screencast' : 'Knowmia'}
               currentUserId={currentUserId}
               palette={{
                  unreadBackgroundGradientStartRgb: TscStyles.color.action.screencastPrimaryStop1,
                  unreadBackgroundGradientStopRgb: TscStyles.color.action.screencastPrimaryStop2,
                  unreadHighlightRgb: TscStyles.color.brand.screencast,
                  unreadCountBackgroundRgba: `${TscStyles.color.brand.screencast}33`
               }}
               serverProxyConfig={{
                  apiGetNotificationsOverride: apiGetNotificationsOverride,
                  apiGetUnreadNotificationsCountOverride: apiGetUnreadNotificationsCountOverride,
                  apiMarkAllAsReadOverride: apiMarkAllAsReadOverride,
                  apiMarkAsReadOverride: apiMarkAsReadOverride
               }}
               notificationTranslation={n => notificationTranslationOverride(n, t)}
            />
         </Suspense>
      </div>
   );
};

export interface INotificationsWidgetProps {
   currentUserId: string;
}

export default NotificationsWidget;
