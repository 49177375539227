import {MediaPrivacy} from './mediaDetails';
import Optional from './optional';
import {IMinimalFolder} from './minimalFolder';
import {IComment} from './commentModel';

export interface ISelectedConversationData {
   VideoReviewId: string;
   Title: string;
   ConversationHash: string;
   SelectedComment?: IComment;
}

export interface IDisableConversation {
   isCommentingDisabled: boolean;
   isAnnotatingDisabled: boolean;
}

export interface IConversationData extends ISelectedConversationData{
   CommenterCount?: number;
   CommentCount?: number;
   DateCreated?: Date;
   Media: IConversationMediaDetails;
}

export interface IConversationMediaDetails {
   Hash: string;
   StorageFileId: string;
   OriginalStorageFileId: string;
   QuizId: string;
   Title: string;
   Privacy: MediaPrivacy;
   ShareDateTime: string;
   UploadDateTime: string;
   Owner: {
      TechSmithId: string;
      EmailAddress: string;
      DisplayName: string;
      FirstAccess: string;
      LastAccess: string;
   };
   ViewCount: number;
   ParentFolder: Optional<IMinimalFolder>;
}

export const nullConversationDataFactory = (conversationData: Partial<IConversationData> = {}): IConversationData => {
   const nullConversationData = {
      CommenterCount: 0,
      CommentCount: 0,
      DateCreated: new Date(),
      Media: {
         Hash: '',
         StorageFileId: '',
         OriginalStorageFileId: '',
         QuizId: '',
         Title: '',
         Privacy: '',
         ShareDateTime: '',
         UploadDateTime: '',
         Owner: {
            TechSmithId: '',
            EmailAddress: '',
            DisplayName: '',
            FirstAccess: '',
            LastAccess: ''
         },
         ViewCount: 0,
         ParentFolder: ''
      },
      VideoReviewId: '',
      Title: '',
      ConversationHash: ''
   };

   return Object.assign({}, nullConversationData, conversationData);
};