import React, {useEffect, useRef, useState} from 'react';
import {
   ControlButton,
   TscButtonType,
   TscThemeName,
   TscThemeNames,
   TscStyles
} from '@techsmith/tsc-cloud-style-guide';
import {useTranslation} from 'react-i18next';
import LocalMediaImporter from '../ReplaceMediaModal/LocalMediaImporter';
import '../../../static/css/modal.less';
import {FileUploadStates, IFileUploadCandidate, MediaTypes} from '../../../model/fileUploadCandidate';
import uploadApi from '../../../service/uploadApi';
import Axios, {AxiosProgressEvent} from 'axios';
import styled from 'styled-components';
import UploadProgressTile, {TileWrapper} from './UploadProgressTile';
import {MinWidthButtonWrapper, StyledAnchor} from '../../util/StyledElements';
import Constants, {LinkIDs} from '../../../constants/Constants';
import withMemoizedContexts from '../../../context/contextContainerHoC';
import {themeStore} from '../../../context/themeProvider';
import {addLibraryMedia} from '../../../context/libraryProvider';
import {convertToMediaDetails} from '../../../model/baseMedia';
import {IMediaDetails} from '../../../model/mediaDetails';
import {foldersStore} from '../../../context/foldersProvider';
import {IFolderTreeNode} from '../../../model/folderTreeNode';
import logging from '../../../service/logging';
import {updateLocalVideoCount, userQuotaStore} from '../../../context/userQuotaProvider';
import {doesMediaTypeBelongInWellKnownFolder, isWellKnownFolder, IWellKnownFolder} from '../../../constants/wellKnownFolder';
import {getLinkBasedOnPlatform} from '../../../util/LinkHelper';
import {AlertModalPortal, BasicModalPortal} from '../../util/ModalPortalHelper';

const MAX_CONCURRENT_UPLOADS = 3;

const ModalBody = styled.div<{mediaQueueVisible: boolean}>`
   #media-import-drop-zone {
      margin-bottom: ${props => props.mediaQueueVisible ? '0.5rem' : '2rem'};

      >div {
         height: 5.5rem;

         >svg {
            transform: scale(1.25);
         }
      }
   }
`;

const UploadProgressTilesWrapper = styled.div`
   margin-bottom: 1.5rem;
   margin-top: 0.25rem;

   ${TileWrapper} {
      margin-bottom: 0.75rem;
   }

   ${TileWrapper}:last-of-type {
      margin-bottom: 0;
   }
`;

const BoldText = styled.span<{theme: TscThemeNames}>`
   font-weight: ${TscStyles.font.weight.semibold};
   color: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.text.light : TscStyles.color.text.medium};
   font-size: 0.875rem;
`;

const FooterWrapper = styled.div`
   display: flex;
   justify-content: space-between;
`;

const MediaRequirementsAnchor = styled(StyledAnchor)`
   font-size: 0.875rem;
   align-self: center;
`;

const currentUploads: IFileUploadCandidate[] = [];

export const UploadMediaModalBase: React.FC<IUploadMediaModalProps & IStateMappedProps> = ({onClose, theme, activeFolder, UserHasQuotaInformation}) => {
   const {t} = useTranslation();
   const [state, setState] = useState<IUploadMediaState>({
      fileCandidates: [],
      showWarnAboutCancel: false,
      mediaToAddToLibrary: null
   });
   const fileCandidatesRef = useRef(state.fileCandidates);
   const isPromisePending = state.fileCandidates.some(candidate => candidate.cancelTokenSource);

   const updateState = (updatedValues: Partial<IUploadMediaState>): void => {
      if (updatedValues.fileCandidates) {
         fileCandidatesRef.current = updatedValues.fileCandidates;
      }

      setState(prevState => Object.assign({}, prevState, updatedValues));
   };

   useEffect(() => () => {
      cancelPendingUploads();
   }, []);

   useEffect(() => {
      const uploadQueue = Object.assign([], state.fileCandidates.filter(c => c.state === FileUploadStates.ready));

      if (currentUploads.length < MAX_CONCURRENT_UPLOADS && !!uploadQueue.length) {
         const newUpload = uploadQueue.shift();
         currentUploads.push(newUpload);
         void processUpload(newUpload);
      }
   }, [state.fileCandidates, currentUploads]);

   const shouldAddMediaToLibraryAfterUploading = (media: IMediaDetails): boolean => {
      const isMyLibrary = activeFolder?.Hash === null;
      return !isWellKnownFolder(activeFolder?.Hash)
         || doesMediaTypeBelongInWellKnownFolder(media.ContentType, isMyLibrary ? IWellKnownFolder.root : activeFolder?.Hash as IWellKnownFolder);
   };
   
   useEffect(() => {
      if (state.mediaToAddToLibrary) {
         if (shouldAddMediaToLibraryAfterUploading(state.mediaToAddToLibrary)) {
            addLibraryMedia(state.mediaToAddToLibrary);
         }
      }
   }, [state.mediaToAddToLibrary]);

   const updateCandidate = (candidate: IFileUploadCandidate) => {
      const fileCandidatesCopy = Object.assign([], fileCandidatesRef.current);
      const candidateIndex = fileCandidatesCopy.findIndex(c => c.id === candidate.id);
      fileCandidatesCopy[candidateIndex] = Object.assign(candidate);
      updateState({fileCandidates: fileCandidatesCopy});
   };

   const handleProgressEvent = (progressEvent: AxiosProgressEvent, candidate: IFileUploadCandidate) => {
      const percentCompleted = Math.min(Math.round((progressEvent.loaded * 100) / progressEvent.total), 100);
      candidate.uploadProgress = percentCompleted;

      if (percentCompleted === 100) {
         candidate.state = FileUploadStates.processing;
      }

      updateCandidate(candidate);
   };

   const removeCandidateFromCurrentUploads = (candidate: IFileUploadCandidate) => {
      currentUploads.splice(currentUploads.findIndex(c => c.id === candidate.id), 1);
   };

   const processUpload = async (candidate: IFileUploadCandidate) => {
      try {
         candidate.cancelTokenSource = Axios.CancelToken.source();
         candidate.state = FileUploadStates.uploading;
         const folderHash = activeFolder?.Hash && !isWellKnownFolder(activeFolder?.Hash) ? activeFolder?.Hash : Constants.defaultUploadFolderHash;
         const newMedia = await uploadApi.uploadMedia(folderHash, candidate.file, e => handleProgressEvent(e, candidate), candidate.cancelTokenSource);
         candidate.state = FileUploadStates.completed;
         candidate.cancelTokenSource = null;
         candidate.uploadProgress = 100;
         updateState({mediaToAddToLibrary: convertToMediaDetails(newMedia)});
         if (UserHasQuotaInformation && candidate.mediaType === MediaTypes.video) {
            await updateLocalVideoCount();
         }
      } catch (e) {
         if (e.status === Constants.statusCodes.unsupportedMediaType) {
            candidate.cancelTokenSource = null;
            candidate.uploadProgress = 0;
            candidate.state = FileUploadStates.rejectedByServerError;
            candidate.uploadErrorStatusCode = e.status;
         } else if (!Axios.isCancel(e)) {
            logging.error(`Error uploading file name: ${candidate.file.name}, file type: ${candidate.file.type}, file size: ${candidate.file.size} bytes. Error ${e.message as string}`, e);
            candidate.cancelTokenSource = null;
            candidate.uploadProgress = 0;
            candidate.state = FileUploadStates.networkError;
            candidate.uploadErrorStatusCode = e.status;
         }
      } finally {
         updateCandidate(candidate);
         removeCandidateFromCurrentUploads(candidate);
      }
   };

   const cancelUpload = (candidate: IFileUploadCandidate) => {
      candidate.state = FileUploadStates.cancelled;
      candidate.cancelTokenSource.cancel();
      candidate.cancelTokenSource = null;
      updateCandidate(candidate);
   };

   const restartUpload = async (candidate: IFileUploadCandidate) => {
      if (candidate.state === FileUploadStates.cancelled || candidate.state === FileUploadStates.networkError) {
         candidate.uploadProgress = 0;
         candidate.state = FileUploadStates.ready;
         candidate.uploadErrorStatusCode = null;
         await processUpload(candidate);
      }
   };

   const handleFileSelection = async (fileCandidates: IFileUploadCandidate[]) => {
      const safeCurrentCandidates = fileCandidatesRef.current ?? [];
      const newUploads = fileCandidates.filter(f => !safeCurrentCandidates.find(ff =>
         ff.file.size === f.file.size &&
         ff.file.name === f.file.name &&
         ff.file.lastModified === f.file.lastModified &&
         ff.state !== FileUploadStates.completed
      ));
      const existingUploads = fileCandidates.filter(f => safeCurrentCandidates.find(ff =>
         ff.file.size === f.file.size &&
         ff.file.name === f.file.name &&
         ff.file.lastModified === f.file.lastModified &&
         ff.state !== FileUploadStates.completed
      ));
      await Promise.all(existingUploads.map(restartUpload)); // this function guards against uploads that are not in a state to restart
      updateState({fileCandidates: safeCurrentCandidates.concat(newUploads) || fileCandidates});
   };

   const cancelPendingUploads = () => {
      fileCandidatesRef.current.forEach(c => c.cancelTokenSource?.cancel());
   };

   const handleClose = () => {
      if (isPromisePending) {
         updateState({showWarnAboutCancel: true});
      } else {
         onClose();
      }
   };

   const onCancelWarnConfirm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      updateState({showWarnAboutCancel: false});
      cancelPendingUploads();
      onClose();
   };

   const onCancelWarnClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      updateState({showWarnAboutCancel: false});
   };

   const cancelUploadAlert: React.JSX.Element = (
      <AlertModalPortal
         title={t('uploadMedia.cancel.title')}
         onConfirm={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onCancelWarnConfirm(e)}
         confirmButtonType={TscButtonType.destructive}
         confirmContent={t('uploadMedia.cancel.title')}
         onDismiss={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onCancelWarnClose(e)}
         dismissButtonType={TscButtonType.secondary}
         dismissContent={t('general.back')}
         onClose={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onCancelWarnClose(e)}
      >{t('uploadMedia.cancel.warning')}</AlertModalPortal>
   );

   return (
      <>
         <BasicModalPortal title={t('uploadMedia.action')} onClose={handleClose} themeName={theme} testId="upload-media-modal">
            <ModalBody className="modal-body" mediaQueueVisible={!!state.fileCandidates?.length}>
               <div className="form-body themeable-section">
                  <LocalMediaImporter onFileSelect={handleFileSelection} allowMultiple={true} ignoreQuotaLimits={false} />
                  {!!state.fileCandidates?.length && <>
                     <BoldText theme={theme}>{t('uploadMedia.mediaQueue')}</BoldText>
                     <UploadProgressTilesWrapper>
                        {state.fileCandidates.map((candidate, index) =>
                           <UploadProgressTile key={index} uploadCandidate={candidate} cancelUpload={() => cancelUpload(candidate)} restartUpload={() => restartUpload(candidate)} />
                        )}
                     </UploadProgressTilesWrapper>
                  </>}
                  <FooterWrapper>
                     <MediaRequirementsAnchor href={getLinkBasedOnPlatform(LinkIDs.helpFileFormats)} target={'_blank'} rel={'noopener noreferrer nofollow'}>{t('uploadMedia.mediaRequirements')}</MediaRequirementsAnchor>
                     <MinWidthButtonWrapper>
                        <ControlButton
                           label={t('general.close')}
                           buttonType={TscButtonType.secondary}
                           themeName={theme}
                           onClick={handleClose}
                           testId="upload-media-modal-close-button"
                        />
                     </MinWidthButtonWrapper>
                  </FooterWrapper>
               </div>
            </ModalBody>
         </BasicModalPortal>
         {state.showWarnAboutCancel && cancelUploadAlert}
      </>
   );
};

export interface IUploadMediaModalProps {
   onClose(): void;
}

interface IUploadMediaState {
   fileCandidates: IFileUploadCandidate[];
   showWarnAboutCancel: boolean;
   mediaToAddToLibrary: IMediaDetails;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
   activeFolder: IFolderTreeNode;
   UserHasQuotaInformation: boolean;
}

const mapStatesToProps = (
   {onClose}: IUploadMediaModalProps,
   {theme}: Partial<IStateMappedProps>,
   {activeFolder}: Partial<IStateMappedProps>,
   {UserHasQuotaInformation}: Partial<IStateMappedProps>
): IStateMappedProps & IUploadMediaModalProps => ({onClose, theme, activeFolder, UserHasQuotaInformation});

export default withMemoizedContexts(mapStatesToProps, themeStore, foldersStore, userQuotaStore)(UploadMediaModalBase);
