import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpBackend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
import {IBaseAppModel} from '../model/appModel';

const localStorageTest = () => {
   const test = 'a';
   try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
   } catch (e) {
      return false;
   }
};

export const init = (config: IBaseAppModel) => {
   const additionalNamespaces = ['collections'];

   return i18n
      .use(LanguageDetector)
      .use(ChainedBackend)
      .use(initReactI18next)
      .init({
         fallbackLng: 'en-US',
         load: 'currentOnly',
         keySeparator: '.', // allow nesting in the translation json
         interpolation: {
            escapeValue: false // recommended by their quick start guide, since react already escapes values for us
         },
         defaultNS: 'default',
         ns: ['default', ...additionalNamespaces],
         fallbackNS: additionalNamespaces,
         backend: {
            backends: localStorageTest() ? [LocalStorageBackend, HttpBackend] : [HttpBackend],
            backendOptions: localStorageTest() ? [
               {versions: {'en-US': '375223'}},
               {loadPath: `${config.EnvironmentData.CdnUrl}/{{lng}}/{{ns}}.json`}
            ] : [
               {loadPath: `${config.EnvironmentData.CdnUrl}/{{lng}}/{{ns}}.json`}
            ]
         },
         debug: config.EnvironmentData.IsRunningLocally
      });
};
