import React, {createContext, ReactElement} from 'react';
import useSWR, {mutate} from 'swr';
import Constants from '../constants/Constants';
import {EntitlementType, IUserQuotas} from '../model/userQuotas';
import config from '../service/config';
import userApi from '../service/userApi';
import {GetKey, GetSessionStorage, SetSessionStorage} from '../util/StorageHelper';

const cacheKey = 'UserQuotaInfo';

const initialState: IUserQuotas = {
   QuotaEntitlementType: EntitlementType.free,
   CurrentBandwidth: 0,
   CurrentStorage: 0,
   CurrentVideoCount: 0,
   VideoCountLimit: 1,
   VideoCountExceeded: false,
   UserHasQuotaInformation: false
};

const store = createContext(initialState);
const Provider = store.Provider;
let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<IUserQuotas>> = () => null;
let publicUpdateStateFromServer: () => void = () => null;

const publicUpdateState = async (newState: Partial<IUserQuotas>) => {
   privateUpdateState(Object.assign({}, privateState, newState));
   await mutate(cacheKey, privateState, false);
   SetSessionStorage(GetKey(Constants.swrKey, cacheKey), JSON.stringify(privateState));
};

const updateLocalVideoCount = async () => {
   const updatedVideoCount = privateState.CurrentVideoCount + 1;
   const newState = {
      CurrentVideoCount: updatedVideoCount,
      VideoCountExceeded: updatedVideoCount === privateState.VideoCountLimit
   };
   await publicUpdateState(newState);
};

const fetchUserQuotas = async () => {
   if (!config.featureSwitches.EnableScreencastProEntitlement || !config.user.IsSignedIn) {
      return initialState;
   }
   try {
      const userQuotasResponse = await userApi.getQuotas();
      const newQuotaState: IUserQuotas = {
         QuotaEntitlementType: userQuotasResponse.QuotaEntitlementType,
         CurrentBandwidth: userQuotasResponse.CurrentBandwidth,
         CurrentStorage: userQuotasResponse.CurrentStorage,
         CurrentVideoCount: userQuotasResponse.CurrentVideoCount,
         VideoCountLimit: userQuotasResponse.VideoCountLimit,
         VideoCountExceeded: userQuotasResponse.IsVideoCountAtLimit,
         UserHasQuotaInformation: true
      };
      return newQuotaState;
   } catch (e) {
      if (e.status && e.status === Constants.statusCodes.unauthorized) {
         return initialState;
      }
      throw e;
   }
};

const useProvider = (children: ReactElement) => {
   const sessionState = GetSessionStorage(GetKey(Constants.swrKey, cacheKey));
   const fallbackData = sessionState ? JSON.parse(sessionState) as IUserQuotas : initialState;
   const {data: state, mutate: updateState} = useSWR(cacheKey, () => fetchUserQuotas(), {
      revalidateOnFocus: false,
      fallbackData: fallbackData
   });

   privateUpdateState = updateState;
   privateState = state;
   // specifically set to calling mutate w/out variables as this will trigger the default action
   publicUpdateStateFromServer = () => updateState();

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   updateLocalVideoCount,
   useProvider as useUserQuotaProvider,
   store as userQuotaStore,
   publicUpdateState as updateUserQuota,
   publicUpdateStateFromServer as updateUserQuotaFromServer
};
