import React, {createContext, ReactElement, useState} from 'react';
import {IConversationData} from '../model/conversationModel';
import conversationApi from '../service/conversationApi';

interface IConversationsProvider {
   conversations: IConversationData[];
   conversationPaneOpen: boolean;
}

const initialState: IConversationsProvider = {
   conversations: null,
   conversationPaneOpen: true
};

const store = createContext<IConversationsProvider>(initialState);
const Provider = store.Provider;

let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<IConversationsProvider>> = () => null;
const publicUpdateState = (newState: Partial<IConversationsProvider>) => privateUpdateState(Object.assign({}, privateState, newState));
const updateConversationsFromServer = async (mediaHash: string) => publicUpdateState({conversations: await conversationApi.getConversations(mediaHash)});

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<IConversationsProvider>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useConversationsProvider,
   store as conversationsStore,
   publicUpdateState as updateConversationsState,
   updateConversationsFromServer
};
