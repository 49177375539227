import React from 'react';
import {useTranslation} from 'react-i18next';
import Constants, {LinkIDs} from '../../constants/Constants';
import config from '../../service/config';
import {MenuItem} from '@techsmith/tsc-cloud-style-guide';
import {getLinkBasedOnPlatform} from '../../util/LinkHelper';

const HelpLinksMenu: React.FC = () => {
   const {t} = useTranslation();
   const showSecurityPolicyLink = config.isAdmin;
   const showTouLink = config.isAdmin || !config.featureSwitches.IsScreencast;
   const showTosLink = config.isAdmin || config.featureSwitches.UseEntitlementAuthorization;
   const tosLink = config.isAdmin ? Constants.navigation.adminTermsOfServiceLink : Constants.navigation.termsOfServiceLink;

   return (
      <>
         <MenuItem key={`${t('helpLinks.links.tutorials')}-link`} label={t('helpLinks.links.tutorials')} href={getLinkBasedOnPlatform(LinkIDs.helpTutorials)} openInNewTab={true}/>
         <MenuItem key={`${config.environmentData.CustomerLinkLabel}-link`} label={config.environmentData.CustomerLinkLabel} href={config.environmentData.CustomerLink} openInNewTab={true}/>
         {config.featureSwitches.IsScreencast && <MenuItem key="tech-support-link" label={t('helpLinks.links.techSupport')} href={getLinkBasedOnPlatform(LinkIDs.helpSupport)} openInNewTab={true}/>}
         {config.featureSwitches.IsScreencast && <MenuItem key="whats-new-link" label={t('helpLinks.links.whatsNew')} href={Constants.navigation.whatsNew} />}
         {config.featureSwitches.IsScreencast && <MenuItem key="feedback-link" label={t('helpLinks.links.feedback')} href={Constants.navigation.knowmiaFeedbackLink} openInNewTab={true}/>}
         <MenuItem key="privacy-policy-link" label={t('helpLinks.links.privacyPolicy')} href={t('sites.privacyPolicyLink')} openInNewTab={true}/>
         <MenuItem key="acknowledgements-link" label={t('helpLinks.links.acknowledgements')} href={Constants.navigation.acknowledgementsLink} />
         {showTouLink && <MenuItem key="terms-of-use-link" label={t('helpLinks.links.termsOfUse')} href={Constants.navigation.termsOfUseLink} openInNewTab={true}/>}
         {showTosLink && <MenuItem key="terms-of-service-link" label={t('helpLinks.links.termsOfService')} href={tosLink} openInNewTab={true}/>}
         {!config.featureSwitches.DisableContactAdministratorFooterLink && <MenuItem key="contact-admin-link" label={t('helpLinks.links.contactAdmin')} href={Constants.navigation.contactAdminLink} openInNewTab={true}/>}
         {showSecurityPolicyLink && <MenuItem key="security-policy-link" label={t('helpLinks.links.securityPolicy')} href={Constants.navigation.securityPolicyLink} openInNewTab={true}/>}
      </>
   );
};

export default HelpLinksMenu;
