import {TextInput, TscThemeName} from '@techsmith/tsc-cloud-style-guide';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import styled from 'styled-components';
import Constants from '../../constants/Constants';
import cssConstants from '../../constants/cssConstants';
import config from '../../service/config';
import {BasicModalPortal} from '../util/ModalPortalHelper';
import {ButtonStyledAsAnchor, StyledAnchor} from '../util/StyledElements';

const CenteredFlexWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 1rem 1rem 2.5rem 1rem;
`;

const QrCodeWrapper = styled.div<{isPro: boolean}>`
   margin-top: 0.75rem;
   margin-bottom: ${props => props.isPro ? '0' : '0.75rem'};
`;

const TranslationTextWrapper = styled.div<{isColumn: boolean}>`
   display: flex;
   flex-direction: ${props => props.isColumn ? 'column' : 'row'};
   text-align: center;

   a {
      margin-left: 0.25rem;
   }
`;

const TextInputWrapper = styled.div`
   padding: 0 1.5rem;
   width: 100%;
   box-sizing: border-box;
`;

const ConnectMobileDevice: React.FC<IConnectMobileDeviceProps> = ({onClose}) => {
   const {t} = useTranslation();
   const [displayLegacyData, setDisplayLegacyData] = useState(false);

   const getBottomContent = () => {
      if (displayLegacyData) {
         return (
            <>
               <span>{t('connectMobileDevice.manuallyConnect')}</span>
               <TextInputWrapper>
                  <TextInput testId="manually-connect-text-input" themeName={TscThemeName.dawn} value={config.environmentData.RelayServerRootUrl} readOnly={true} />
               </TextInputWrapper>
            </>
         );
      }
      return (
         <TranslationTextWrapper isColumn={true}>
            <span>{t('connectMobileDevice.qrCodeNotWorking')}</span>
            <span>{t('connectMobileDevice.tryUpdatingFuse')}</span>
            <ButtonStyledAsAnchor onClick={() => setDisplayLegacyData(!displayLegacyData)}>{t('connectMobileDevice.loadQrForOlderVersions')}</ButtonStyledAsAnchor>
         </TranslationTextWrapper>
      );
   };

   const getQrSrc = () => {
      const path = displayLegacyData ? Constants.navigation.legacyConnectMobileQrCodePath : Constants.navigation.connectMobileQrCodePath;
      return config.environmentData.IsRunningLocally ? `${Constants.localDevServerUrl}${path}` : path;
   };

   return (
      <BasicModalPortal title={t('connectMobileDevice.title')} onClose={onClose} themeName={TscThemeName.dawn} testId="connect-mobile-device-modal" width={cssConstants.defaultModalWidth}>
         <div className="modal-body">
            <CenteredFlexWrapper className="themeable-section">
               <TranslationTextWrapper isColumn={false}>
                  <Trans i18nKey="connectMobileDevice.scanWithFuse">
                     Scan with
                     <StyledAnchor data-test-id="techsmith-fuse-link" href={Constants.navigation.knowmiaImportFromMobileTutorialLink} target="_blank" rel="noopener noreferrer nofollow">TechSmith Fuse</StyledAnchor>
                  </Trans>
               </TranslationTextWrapper>
               <QrCodeWrapper isPro={config.featureSwitches.DisableTransferForMobileConnect}>
                  <img data-test-id="qr-code" src={getQrSrc()} alt={t('connectMobileDevice.qrCode')} />
               </QrCodeWrapper>
               {!config.featureSwitches.DisableTransferForMobileConnect && getBottomContent()}
            </CenteredFlexWrapper>
         </div>
      </BasicModalPortal>
   );
};

export interface IConnectMobileDeviceProps {
   onClose(): void;
}

export default ConnectMobileDevice;
