import React, {createContext, ReactElement, useState} from 'react';
import config from '../service/config';
import {History} from 'history';
import {useHistory} from 'react-router-dom';
import Constants, {EntryPoint} from '../constants/Constants';
import logging from '../service/logging';
import {ICollectionModel} from '../model/appModel';
import userApi from '../service/userApi';
import collectionApi from '../service/collectionApi';

interface ICollectionsProvider {
   activeCollection: ICollectionModel;
   collections: ICollectionModel[];
   sidebarCollections: ICollectionModel[];
   history: History;
   isUpdating: boolean;
   isUpdatingSidebarCollections: boolean;
   collectionCursor?: string;
   canLoadMoreCollections: boolean;
}

const initialState: ICollectionsProvider = {
   activeCollection: null,
   collections: [],
   sidebarCollections: [],
   history: null,
   isUpdating: false,
   isUpdatingSidebarCollections: false,
   collectionCursor: null,
   canLoadMoreCollections: true
};

const store = createContext<ICollectionsProvider>(initialState);
const Provider = store.Provider;
let privateState = initialState;
let privateUpdateState: React.Dispatch<React.SetStateAction<ICollectionsProvider>> = () => null;
const publicUpdateState = (newState: Partial<ICollectionsProvider>) => privateUpdateState((prevState: ICollectionsProvider) => Object.assign({}, prevState, newState));

const getCollectionForUser = async (collectionId: string, withoutCache?: boolean) => {
   let collection: ICollectionModel;
   try {
      collection = await collectionApi.getCollectionById(collectionId, withoutCache);
   } catch (e) {
      logging.error(`Failed to get collection: ${collectionId}`, e);
      return null;
   }
   return collection;
};

export const updateSidebarCollections = async () => {
   if (!privateState.isUpdatingSidebarCollections) {
      publicUpdateState({isUpdatingSidebarCollections: true});
      
      let newCollections: ICollectionModel[];
      if (config.user.IsSignedIn) {
         try {
            newCollections = await userApi.getCollections(config.user.TechSmithId, null, null, false);
         } catch (e) {
            logging.error('Failed to update collections data from server: ', e);
         }
      }

      if (newCollections?.length) {
         publicUpdateState({
            sidebarCollections: newCollections
         });
      }
      publicUpdateState({isUpdatingSidebarCollections: false});
   }
};

const findMatchingCollectionAndSendActiveCollectionUpdate = async (collectionId: string, withoutCache?: boolean) => {
   let matchingCollection = privateState?.collections.find(c => c.Id === collectionId);
   matchingCollection = matchingCollection?.Members?.length ? matchingCollection : await getCollectionForUser(collectionId, withoutCache);
   publicUpdateState({activeCollection: matchingCollection});
};

export const refreshActiveCollection = async () => {
   if (privateState.activeCollection?.Id) {
      await findMatchingCollectionAndSendActiveCollectionUpdate(privateState.activeCollection?.Id, true);
   }
};

export const updateActiveCollectionById = async (collectionId: string) => {
   if (collectionId !== privateState.activeCollection?.Id) {
      await findMatchingCollectionAndSendActiveCollectionUpdate(collectionId);
   }
};

export const getCollectionDetailsById = async (collectionId: string, withoutCache?: boolean) => await getCollectionForUser(collectionId, withoutCache);

export const updateCollections = async () => {
   if (!privateState.isUpdating && config.user.IsSignedIn) {
      publicUpdateState({isUpdating: true});
      let newCollections: ICollectionModel[]; // Declare the variable here
      try {
         newCollections = await userApi.getCollections(config.user.TechSmithId, privateState.collectionCursor, Constants.collectionsPaginationBatchSize, true);
      } catch (e) {
         logging.error('Failed to update collections data from server: ', e);
      }
      if (newCollections?.length) {
         const updatedCollections = privateState.collections.concat(newCollections);
         const newLastLoadedCollectionId = newCollections[newCollections.length - 1].Id;
         publicUpdateState({
            collections: updatedCollections,
            collectionCursor: newLastLoadedCollectionId,
            canLoadMoreCollections: newCollections.length >= Constants.mediaPaginationBatchSize
         });
      } else {
         publicUpdateState({canLoadMoreCollections: false});
      }
      publicUpdateState({isUpdating: false});
   }
};

export const clearActiveCollection = () => {
   publicUpdateState({activeCollection: null});
};

export const clearActiveCollectionAndUpdateRouteToMyCollections = () => {
   privateState.history?.push(Constants.navigation.myCollectionsLink);
   clearActiveCollection();
};

export const clearActiveCollectionAndUpdateRouteToMyFeed = () => {
   privateState.history?.push(Constants.navigation.myFeedLink);
   clearActiveCollection();
};

export const updateActiveCollection = async (collection: ICollectionModel) => {
   const collectionsCopy = Object.assign([], privateState.collections);
   const sidebarCollectionsCopy = Object.assign([], privateState.sidebarCollections);

   const collectionIndex = collection ? privateState.collections.findIndex(c => c.Id === collection.Id) : -1;

   let matchingCollection: ICollectionModel;
   if (collectionIndex !== -1) {
      matchingCollection = collectionsCopy[collectionIndex];
      matchingCollection = matchingCollection?.Members?.length ? matchingCollection : await getCollectionForUser(collection.Id, true);
      collectionsCopy[collectionIndex] = matchingCollection;
   }

   const sidebarCollectionIndex = privateState.sidebarCollections.findIndex(c => c.Id === collection.Id);
   if (!matchingCollection && sidebarCollectionIndex !== -1) {
      matchingCollection = sidebarCollectionsCopy[sidebarCollectionIndex];
      matchingCollection = matchingCollection?.Members?.length ? matchingCollection : await getCollectionForUser(collection.Id, true);
   }
   sidebarCollectionsCopy[sidebarCollectionIndex] = matchingCollection;
   const shouldUpdateHistory = collection?.Id !== privateState.activeCollection?.Id;
   if (shouldUpdateHistory) {
      updateHistory(collection);
   }

   publicUpdateState({activeCollection: sidebarCollectionsCopy[sidebarCollectionIndex], collections: collectionsCopy, sidebarCollections: sidebarCollectionsCopy});
};

const updateHistory = (collection: ICollectionModel) => {
   if (config.entryPoint === EntryPoint.library) {
      privateState.history?.push(collection?.Id ? `${Constants.navigation.myCollectionsLink}/${collection.Id}` : Constants.navigation.myCollectionsLink);
   } else {
      privateState.history?.push(collection?.Id ? `${Constants.navigation.collectionsViewBasePath}/${collection.Id}` : Constants.navigation.myFeedLink);
   }
};

export const addCollectionToState = (collection: ICollectionModel) => {
   const collectionsCopy = Object.assign([], privateState.collections);
   collectionsCopy.unshift(collection);
   const sidebarCollectionsCopy = Object.assign([], privateState.sidebarCollections);
   sidebarCollectionsCopy.unshift(collection);
   publicUpdateState({collections: collectionsCopy, sidebarCollections: sidebarCollectionsCopy});
};

export const removeCollectionFromState = (collectionToRemove: ICollectionModel, isCollectionBeingDeleted: boolean) => {
   const collectionIndex = privateState.collections.findIndex(c => c.Id === collectionToRemove.Id);
   const sidebarCollectionIndex = privateState.sidebarCollections.findIndex(c => c.Id === collectionToRemove.Id);
   if (sidebarCollectionIndex !== -1) {
      const sidebarCollectionsCopy = Object.assign([], privateState.sidebarCollections);
      sidebarCollectionsCopy.splice(sidebarCollectionIndex, 1);
      publicUpdateState({sidebarCollections: sidebarCollectionsCopy});
   } else {
      logging.error(`Collection - ${collectionToRemove.Id} not found in local sidebarCollections state object`);
   }

   if (collectionIndex !== -1) {
      const collectionsCopy = Object.assign([], privateState.collections);
      collectionsCopy.splice(collectionIndex, 1);
      publicUpdateState({collections: collectionsCopy});
   } else {
      logging.error(`Collection - ${collectionToRemove.Id} not found in local collections state object`);
   }

   // If the collection is being deleted and is the active collection, clear the active collection and navigate to My Collections
   if (isCollectionBeingDeleted && collectionToRemove?.Id === privateState.activeCollection?.Id) {
      publicUpdateState({activeCollection: null});
      privateState.history.push(Constants.navigation.myCollectionsLink);
   }
};

const useProvider = (children: ReactElement) => {
   initialState.history = useHistory();
   const [state, updateState] = useState<ICollectionsProvider>(initialState);
   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useCollectionsProvider,
   store as collectionsStore
};
