import config from '../service/config';
import {AxiosRequestConfig, AxiosPromise, AxiosResponse} from 'axios';

const executeRequest = async<T>(callFunc: (apiUrl: string, acceptedStatusCodes: number[], data?: object, requestConfig?: AxiosRequestConfig) => AxiosPromise<T>, apiUrl: string, acceptedStatusCodes: number[], data?: object, requestConfig?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
   if (config.user.TechSmithId && config.user.Secret) {
      const authRequestConfig = Object.assign({}, requestConfig, {auth: {username: config.user.TechSmithId, password: config.user.Secret}});
      return await callFunc(apiUrl, acceptedStatusCodes, data, authRequestConfig);
   }

   return await callFunc(apiUrl, acceptedStatusCodes, data, requestConfig);
};

export default {
   executeRequest
};
