import React from 'react';
import styled from 'styled-components';
import {TscStyles} from '@techsmith/tsc-cloud-style-guide';
import cssConstants from '../../constants/cssConstants';

export const Loader = styled.div `
   background:rgba(0,0,0,.25);
   width: 30%;
   max-width:40rem;
   height: 1.25rem;
   border-radius: ${TscStyles.border.radius.md};
   position:relative;
   overflow:hidden;
   margin: 0.25rem auto;
   display: flex;
   background: ${TscStyles.color.ui.dusk.medium};
 `;

const LoaderProgress = styled.div<{progress: number}>`
   --s: 60%;
   --l: 50%;
   position:absolute;
   top:0;
   left:0;
   bottom:0;
   width: calc( ${props => props.progress} * 1%);
   background: ${cssConstants.progressLoaderGradientOrange};
   background-position: left center;
   background-size:600% 100%;
   background-repeat: repeat;
   animation: infinite loader 15000ms linear;
   transition: all 500ms ease;
   box-shadow: inset 0 0 .25rem rgba(255,255,255,.15);

   @keyframes loader{
      100%{
         background-position: -600% center;
      }
   }
 `;

const ProgressLoader: React.FC<IProgressLoaderProps> = ({progressStyle, containerStyle, uploadProgress}) => (
   <Loader data-test-id="progress-loader" style={containerStyle}>
      <LoaderProgress progress={uploadProgress || 1} style={progressStyle} />
   </Loader>
);

export default ProgressLoader;

interface IProgressLoaderProps {
   progressStyle?: React.CSSProperties;
   containerStyle?: React.CSSProperties;
   uploadProgress: number;
}
