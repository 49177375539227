const download = (filename: string, fileContent: any, type: string): Promise<void> => {
   const blob = new Blob([fileContent], {type});

   const tsNavigator = navigator as any;

   if (tsNavigator.msSaveOrOpenBlob) {
      // old IE
      tsNavigator.msSaveOrOpenBlob(blob, filename);
   } else {
      const anchor = document.createElement('a');
      if (typeof anchor.download !== 'undefined') {
         // modern HTML5 feature
         document.body.appendChild(anchor);
         anchor.setAttribute('href', URL.createObjectURL(blob));
         anchor.setAttribute('download', filename);
         anchor.click();
      } else {
         // iOS safari and other browsers that don't have anchor.download
         window.location.href = `data:application/octet-stream,${encodeURIComponent(fileContent)}`;
      }
   }

   return Promise.resolve();
};

export default {download};
