// todo: may be nice to understand this exclusion more and use better TS here
// eslint-disable-next-line @typescript-eslint/ban-types
const createEvent = (name: string, detail: {} = null) => {
   if (window.CustomEvent) {
      return new CustomEvent(name, {detail});
   }

   const event = new Event(name);
   (event as any).detail = detail;
   return event;
};

export {createEvent};
