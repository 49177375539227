import React from 'react';
import {StyledWarning, StyledAnchor} from './StyledElements';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

const WarningFlexWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   white-space: pre-wrap;
`;

export interface WarningWithFaqLinkProps {
   linkUrl: string;
   children?: React.ReactNode;
}

const WarningWithFaqLink: React.FC<WarningWithFaqLinkProps> = ({linkUrl, children}) => {
   const {t} = useTranslation();
   return (
      <WarningFlexWrapper>
         <StyledWarning>
            {children}
            {typeof children === typeof 'string' && ' '}
            <StyledAnchor href={linkUrl} target="_blank" rel="noopener noreferrer nofollow">{t('MoreHelpText')}</StyledAnchor>
         </StyledWarning>
      </WarningFlexWrapper>
   );
};

export default WarningWithFaqLink;
