import React, {createContext, ReactElement, useState} from 'react';
import Constants, {AnnotationColors, AnnotationModes, AnnotationTools} from '../constants/Constants';

interface IAnnotationManager {
   annotationMode: AnnotationModes;
   annotationColor: AnnotationColors;
   annotationTool: AnnotationTools;
   annotationStrokeWidth: number;
}

const initialState = {
   annotationMode: AnnotationModes.none,
   annotationColor: Constants.annotation.defaultColor,
   annotationTool: AnnotationTools.none,
   annotationStrokeWidth: Constants.annotation.strokeWidth
};

const store = createContext<IAnnotationManager>(initialState);
const Provider = store.Provider;

let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<IAnnotationManager>> = () => null;
const publicUpdateState = (newState: Partial<IAnnotationManager>) => privateUpdateState(Object.assign({}, privateState, newState));

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<IAnnotationManager>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useAnnotationManagerProvider,
   store as annotationManagerStore,
   publicUpdateState as updateAnnotationManagerState
};
