import React, {createContext, ReactElement, useState} from 'react';
import useSWR, {mutate} from 'swr';
import {IMediaDetails, MediaPrivacy} from '../model/mediaDetails';
import {IBaseLibrary} from '../model/library';
import {CaptioningState, CaptioningTypes} from '../model/captionModels';
import {nullBaseUserFactory} from '../model/baseUser';
import config from '../service/config';
import mediaApi from '../service/mediaApi';
import Constants, {CreationProducts, EntryPoint} from '../constants/Constants';
import logging from '../service/logging';
import {IWorkerState} from '../model/workerState';

export interface IMediaStore extends IMediaDetails {
   MediaStoreWorkerState: IWorkerState;
}

const initialState: IMediaStore = {
   GroupHashes: [] as string[],
   CollectionIds: [] as string[],
   OwnerLibrary: {User: nullBaseUserFactory()} as IBaseLibrary,
   MediaId: '',
   OriginalStorageFileId: '',
   MediaUrn: '',
   OriginatorId: '',
   ParentFolder: null,
   QuizId: '',
   UploadDateTime: null,
   SharedDateTime: null,
   RecordedDateTime: null,
   AddedToGroupDateTime: null,
   ThumbnailUrl: '',
   CreationProduct: CreationProducts.WEBUPLOAD,
   ContentUrl: '',
   ContentType: '',
   Chapters: null,
   HlsTokenInjectionProxyContentUrl: '',
   IsDownloadable: false,
   DownloadEnabled: false,
   WasCopied: false,
   Title: '',
   IsReady: false,
   IsVideo: false,
   IsImage: false,
   IsDecorated: false,
   IsQuizEditingSupported: false,
   Url: '',
   PosterId: '',
   PosterUrl: '',
   SpriteId: '',
   SpriteUrl: '',
   XmpId: '',
   XmpUrl: '',
   IsEncoding: false,
   EncodingProgress: 0,
   HasEncodingError: false,
   XmpUrn: '',
   Privacy: MediaPrivacy.unknown,
   Hash: '',
   ViewCount: 0,
   Author: '',
   Description: '',
   Duration: '',
   SizeInBytes: 0,
   Width: 0,
   Height: 0,
   Rotate: 0,
   FlipXAxis: false,
   YouTubeUrl: '',
   YouTubeAuthor: '',
   IsYouTube: false,
   CaptioningAssignedToEmail: '',
   ProjectPublishState: '',
   CaptioningState: CaptioningState.Unknown,
   CaptioningType: CaptioningTypes.Unknown,
   AudioDescriptionId: '',
   AudioDescriptionUrl: '',
   DownloadUrl: '',
   HasPublishedCaptions: false,
   AllowViewerToFastForwardVideo: false,
   HasAccessPhrase: false,
   PreviewThumbnailsUri: '',
   PreviewThumbnailsInterval: 0,
   PreviewThumbnailsMaxColumns: 0,
   PreviewThumbnailsTechSmithFileId: '',
   PreviewThumbnailsThumbHeight: 0,
   PreviewThumbnailsThumbWidth: 0,
   MediaStoreWorkerState: {
      isWorking: true,
      hasError: false
   }
};

const mediaDetailsRefreshInterval = 3000;
const cacheKeyPrefix = 'MediaDetails';
const store = createContext<IMediaStore>(initialState);
const Provider = store.Provider;
let privateState = initialState;
let privateUpdateState: React.Dispatch<React.SetStateAction<IMediaStore>> = () => null;
const publicUpdateState = (newState: Partial<IMediaStore>) => privateUpdateState(Object.assign({}, privateState, newState));
let publicUpdateStateFromServer: () => void = () => null;

const invalidateCache = async (hash: string) => {
   const cleanState: IMediaStore = {...initialState, Hash: hash};
   await mutate(`arg@"${cacheKeyPrefix}"@"${hash}"`, cleanState, true);
};

const setMediaDimensionsToDefaultWhenLessThanOne = (mediaDetails: IMediaDetails) => {
   if (mediaDetails) {
      mediaDetails.Width = mediaDetails.Width > 0 ? mediaDetails.Width : Constants.defaultMediaWidth;
      mediaDetails.Height = mediaDetails.Height > 0 ? mediaDetails.Height : Constants.defaultMediaHeight;
      mediaDetails.CollectionIds = mediaDetails.GroupHashes;
   }
};

let updateMediaHash: (hash?: string) => Promise<void> = () => null;

const updateMediaDataFromServer = async (hash: string) => {
   try {
      if (!hash) {
         if (config.entryPoint === EntryPoint.embed || config.entryPoint === EntryPoint.watch) {
            logging.error('updateMediaDataFromServer was called without any media specified ');
            return {...initialState, MediaStoreWorkerState: {isWorking: false, hasError: true}};
         }
         return initialState;
      }

      const mediaDetailsFromServer = await mediaApi.getMediaDetails(hash);
      setMediaDimensionsToDefaultWhenLessThanOne(mediaDetailsFromServer);
      return {...mediaDetailsFromServer, MediaStoreWorkerState: {isWorking: false, hasError: false}};
   } catch (e) {
      logging.error('Failed to update media details data from server: ', e);
      throw e;
   }
};

const useProvider = (children: ReactElement) => {
   const mediaFromConfig = config.watchConfig?.media ?? config.embedConfig?.media;
   // the initial state is either the app configured media (and worker state is not working and has no error) or it is initialState where isWorking is effectively true
   const providerInitialState = mediaFromConfig ? {...mediaFromConfig, MediaStoreWorkerState: {isWorking: false, hasError: false}} : initialState;
   setMediaDimensionsToDefaultWhenLessThanOne(providerInitialState);

   const [mediaHash, setMediaHash] = useState(providerInitialState.Hash);
   const [refreshInterval, setRefreshInterval] = useState(mediaDetailsRefreshInterval);

   const {data: state, mutate: updateState} = useSWR([cacheKeyPrefix, mediaHash], () => updateMediaDataFromServer(mediaHash), {
      revalidateOnFocus: false,
      refreshInterval: refreshInterval,
      onSuccess: data => setRefreshInterval(data.HasEncodingError || data.IsReady && data.Height > 0 && data.Width > 0 ? 0 : mediaDetailsRefreshInterval),
      errorRetryCount: 3,
      errorRetryInterval: refreshInterval,
      onError: () => setRefreshInterval(0),
      fallbackData: providerInitialState
   });

   privateUpdateState = updateState;
   privateState = state;

   publicUpdateStateFromServer = () => updateState({...state}, true); // calling swr mutate with null will trigger update from callback

   updateMediaHash = async (hash?: string) => {
      if (config.entryPoint === EntryPoint.embed || config.entryPoint === EntryPoint.watch) {
         logging.error(`Cannot modify the media source on ${EntryPoint.watch} or ${EntryPoint.embed} page, SmartPlayer currently does not support dynamically reloading`);
         return;
      }
      setMediaHash(hash);
      await invalidateCache(hash);
   };

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useMediaProvider,
   store as mediaStore,
   publicUpdateState as updateMediaState,
   publicUpdateStateFromServer as updateMediaStateFromServer,
   invalidateCache as invalidateMediaCache,
   updateMediaHash
};
