import React, {createContext, ReactElement, useState} from 'react';
import {IDisableConversation} from '../model/conversationModel';

const initialState: IDisableConversation = {isAnnotatingDisabled: true, isCommentingDisabled: true};
const store = createContext<IDisableConversation>(initialState);
const Provider = store.Provider;
let privateState = initialState;
let privateUpdateState: React.Dispatch<React.SetStateAction<IDisableConversation>> = () => null;
const publicUpdateState = (newState: Partial<IDisableConversation>) => privateUpdateState(Object.assign({}, privateState, newState));

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<IDisableConversation>(initialState);
   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useDisableConversationProvider,
   store as disableConversationStore,
   publicUpdateState as updateDisableConversationState
};
