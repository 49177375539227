/* eslint-disable no-undefined */
import React, {createContext, ReactElement, useState} from 'react';

interface ISelfIdentifiedUserProvider {
   showSelfIdentifyModal: boolean;
   userIsSelfIdentified: boolean;
}

const initialState: ISelfIdentifiedUserProvider = {
   showSelfIdentifyModal: false,
   userIsSelfIdentified: false
};

const store = createContext<ISelfIdentifiedUserProvider>(initialState);
const Provider = store.Provider;
let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<ISelfIdentifiedUserProvider>> = () => null;

const publicUpdateState = (newState: Partial<ISelfIdentifiedUserProvider>) => {
   privateUpdateState(Object.assign({}, privateState, newState));
};

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<ISelfIdentifiedUserProvider>(initialState);
   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useSelfIdentifiedUserProvider,
   store as selfIdentifiedUserStore,
   publicUpdateState as updateSelfIdentifiedUserProviderState
};
