const toRgba = (color: string, alpha: number) => {
   if (!color) {
      return '';
   }
   const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
   const rgb = result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
   } : null;
   alpha = Math.min(Math.max(0.0, alpha), 1.0);
   return rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})` : '';
};

export default {
   toRgba
};
