import {CreationProducts} from '../constants/Constants';
import {IBaseUser} from './baseUser';
import {IBaseLibrary} from './library';
import {IMediaDetails, MediaPrivacy, IMediaChapters} from './mediaDetails';
import {IMinimalFolder} from './minimalFolder';

export enum DownloadState {
   Default = 'Default',
   False = 'False',
   True = 'True'
}

export enum MediaContentType {
   Unknown = 'Unknown',
   Audio = 'Audio',
   Image = 'Image',
   Video = 'Video',
   YouTube = 'YouTube'
}

export interface IBaseMedia {
   Hash: string;
   StorageFileId: string;
   OriginalStorageFileId: string;
   QuizId: string;
   Title: string;
   DownloadState: DownloadState;
   AllowViewerToFastForwardVideoState: boolean;
   Privacy: MediaPrivacy;
   ShareDateTime: Date;
   UploadDateTime: Date;
   OwnerLibrary: IBaseLibrary;
   CreationProduct: CreationProducts;
   Creator: IBaseUser;
   ViewCount: number;
   ContentType: MediaContentType;
   HasPublishedCaptions: boolean;
   ParentFolder: IMinimalFolder;
   Chapters?: IMediaChapters;
}

export const convertToMediaDetails = (baseMedia: IBaseMedia) => ({
   Hash: baseMedia.Hash,
   MediaId: baseMedia.StorageFileId,
   OriginalStorageFileId: baseMedia.OriginalStorageFileId,
   QuizId: baseMedia.QuizId,
   Title: baseMedia.Title,
   DownloadEnabled: baseMedia.DownloadState === DownloadState.True,
   AllowViewerToFastForwardVideo: baseMedia.AllowViewerToFastForwardVideoState,
   Privacy: baseMedia.Privacy,
   CreationProduct: baseMedia.CreationProduct,
   SharedDateTime: baseMedia.ShareDateTime,
   UploadDateTime: baseMedia.UploadDateTime,
   OwnerLibrary: baseMedia.OwnerLibrary,
   OriginatorId: baseMedia.Creator.TechSmithId,
   ViewCount: baseMedia.ViewCount,
   ContentType: baseMedia.ContentType,
   HasPublishedCaptions: baseMedia.HasPublishedCaptions,
   ParentFolder: baseMedia.ParentFolder,
   Chapters: baseMedia.Chapters
} as IMediaDetails);
