import React, {createContext, ReactElement, useState} from 'react';
import {ICaptionData} from '../model/captionModels';

interface ICaptionDataProvider {
   captionData: ICaptionData[];
}

const initialState: ICaptionDataProvider = {captionData: []};

const store = createContext(initialState);
const Provider = store.Provider;

let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<ICaptionDataProvider>> = () => null;
const publicUpdateState = (newState: ICaptionData[]) => privateUpdateState(Object.assign({}, privateState, {captionData: newState}));

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<ICaptionDataProvider>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useCaptionDataProvider,
   store as captionDataStore,
   publicUpdateState as updateCaptionDataState
};
