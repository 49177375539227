import React, {useContext, useMemo, memo} from 'react';

const withMemoizedContexts = <TPassedProps, TStateProps, TProps>(mapStatesToProps: (passedProps: TPassedProps, ...stateProps: Partial<TStateProps>[]) => TProps, ...stores: React.Context<any>[]) => {
   if (stores.length < 1) {
      throw new Error('Must supply at least one store to withMemoizedContexts');
   }

   const uniqueStores = new Set(stores);
   // this check was added after inadvertently adding the same store twice to a component caused component useEffects to silently not trigger on update
   if (uniqueStores.size !== stores.length) {
      throw new Error('Duplicate context provided to withMemoizedContexts, this an cause unexpected side-effects and was likely unintentional');
   }

   return (WrappedComponent: React.ComponentType<TProps>) => {
      const useWrapper = memo((props: TPassedProps) => {
         // eslint-disable-next-line @typescript-eslint/no-unsafe-return, react-hooks/rules-of-hooks
         const states = stores.map(store => useContext(store));
         const newProps = mapStatesToProps(props, ...states);
         // eslint-disable-next-line react-hooks/exhaustive-deps
         return useMemo(() => <WrappedComponent {...newProps} />, [...Object.values(newProps)]);
      });
      useWrapper.displayName = `withMemoizedContexts(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
      return useWrapper;
   };
};
export default withMemoizedContexts;
