interface IReviewer {
   EmailAddress?: string;
   Id: string;
   Role: string;
   Status?: string;
   IsUserDeleted: boolean;
   WasUserRoleRemoved: boolean;
   ProfileImageUrl: string;
   DisplayName: string;
   UserType: string;
}

interface IReviewVersion {
   MediaSourceId: string;
   Id: string;
   VersionNumber: number;
   CreatedDate: Date;
   Description: string;
   IsDeleted: boolean;
}

interface IReviewConversation {
   Id: string;
   RequestDate: Date;
   ReviewByDate?: Date;
   HasComments: boolean;
   Title: string;
   PrivacyType: string;
   Reviewers: IReviewer[];
   ReviewVersions: IReviewVersion[];
   AccessGroups: [];
}

interface IOwner {
   Id?: string;
   Theme: string;
   UserType: string;
   DisplayName?: string;
   EnableCommentHotkey: boolean;
   NotificationSettings?: string;
}

export interface IEmojiReactionData {
   ReactionExternalId: string;
   CreateDate: Date;
   EmojiId: string;
   EmojiSkin: number;
   Time: number;
   ReviewConversation: IReviewConversation;
   ConversationUrl: string;
   Owner: IOwner;
   InitialDisplay?: boolean;
}

export interface IReactionGroup {
   startTime: number;
   endTime: number;
   reactions: IEmojiReactionData[];
}

export interface ISelectedReaction {
   selectedReactionGroup?: IReactionGroup;
   selectedReactionMarkerButton?: HTMLButtonElement;
}

export const nullSelectedReactionGroupFactory = (): ISelectedReaction => ({selectedReactionGroup: null, selectedReactionMarkerButton: null});
