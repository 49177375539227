import React, {useState, useRef, useEffect} from 'react';
import {TscStyles, TextInput, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import styled from 'styled-components';
import {MenuButton, StyledWarning} from '../util/StyledElements';
import searchApi from '../../service/searchApi';
import Axios from 'axios';
import Constants from '../../constants/Constants';
import {useTranslation} from 'react-i18next';
import {ITypeAheadRecord} from '../../model/typeAheadRecordModel';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';

const TypeAheadWrapper = styled.div<{widthInPixels?: number}>`
   background-color: ${TscStyles.color.ui.dawn.light};
   border: 1px solid ${TscStyles.color.ui.dawn.mediumDark};
   border-top: none;
   border-radius: 0 0 ${TscStyles.border.radius.md} ${TscStyles.border.radius.md};
   color: ${TscStyles.color.text.dark};
   width: 100%;
   box-sizing: border-box;
   box-shadow: rgba(0, 0, 20, 0.25) 1px 1px 12px;
   z-index: 1;

   div:last-of-type {
      border-radius: 0 0 ${TscStyles.border.radius.md} ${TscStyles.border.radius.md};
   }

   position: absolute;
   width: ${props => props.widthInPixels ? `${props.widthInPixels}px` : '100%'};
   max-height: 9rem;
   overflow-y: auto;
`;

const TypeAheadRecord = styled(MenuButton)`
   height: 2.5rem;
   padding: 0.5rem;
   width: 100%;
   box-sizing: border-box;
   cursor: pointer;
   display: block;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;

   &:hover {
      background-color: ${TscStyles.color.ui.dawn.medium};
   }
`;

const emailRegExp = new RegExp(Constants.emailValidationRegexp);

export const UserEmailInputBase: React.FC<IUserEmailInputProps & IStateMappedProps> = ({textInputId, onEmailChange, initialEmail, theme, disabled = false}) => {
   const {t} = useTranslation();
   const [email, setEmail] = useState(initialEmail || '');
   const [displayTypeAheadSuggestions, setDisplayTypeAheadSuggestions] = useState(false);
   const [typeAheadRecords, setTypeAheadRecords] = useState<ITypeAheadRecord[]>([]);
   const cancelTokenSourceRef = useRef(null);
   const [showValidationError, setShouldShowValidationError] = useState(false);
   const inputChangeTimeoutRef = useRef(-1);
   const containerRef = useRef(null);

   useEffect(() => {
      window.addEventListener('focusout', handleFocusOut);
      window.addEventListener('focusin', handleFocusIn);

      return () => {
         cancelTokenSourceRef.current?.cancel();
         window.removeEventListener('focusout', handleFocusOut);
         window.removeEventListener('focusin', handleFocusIn);
      };
   }, []);

   const handleFocusOut = (e: FocusEvent) => {
      const inputElement = document.getElementById(textInputId);
      if ((inputElement && e.target === inputElement) || !(containerRef.current as Node).contains(e.relatedTarget as Node)) {
         setDisplayTypeAheadSuggestions(false);
      }
   };

   const handleFocusIn = (e: FocusEvent) => {
      const inputElement = document.getElementById(textInputId);
      if (inputElement && e.target === inputElement) {
         setDisplayTypeAheadSuggestions(true);
      }
   };

   const validateEmailAddress = (inputEmail: string) => emailRegExp.test(inputEmail);

   useEffect(() => {
      const isValidEmail = validateEmailAddress(email);
      const correspondingTypeAheadRecord = typeAheadRecords.find(r => r.email === email);
      onEmailChange(email, isValidEmail, correspondingTypeAheadRecord);

      if (email && !isValidEmail) {
         inputChangeTimeoutRef.current = window.setTimeout(() => {
            setShouldShowValidationError(true);
         }, 1000);
      } else {
         setShouldShowValidationError(false);
      }

      return () => {
         clearTimeout(inputChangeTimeoutRef.current);
      };
   }, [email]);

   const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      setEmail(inputValue);
      cancelTokenSourceRef.current?.cancel();

      if (inputValue.length === 0) {
         setDisplayTypeAheadSuggestions(false);
         return;
      }

      const localCancelTokenSource = Axios.CancelToken.source();
      cancelTokenSourceRef.current = localCancelTokenSource;

      let fetchedTypeAheadRecords: ITypeAheadRecord[] = [];
      try {
         fetchedTypeAheadRecords = await searchApi.getTypeAheadSearchResults(inputValue, localCancelTokenSource, searchApi.userRecordType);
      } catch (error) {
         // This can get canceled. It's ok. Suppress console errors.
         return;
      }

      cancelTokenSourceRef.current = null;
      fetchedTypeAheadRecords && setTypeAheadRecords(fetchedTypeAheadRecords);
      setDisplayTypeAheadSuggestions(true);
   };

   const onTypeAheadRecordClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const button: Partial<HTMLButtonElement> = e.target;
      setEmail(button.textContent);
      setDisplayTypeAheadSuggestions(false);
   };

   return (
      <div ref={containerRef}>
         <TextInput sharedId={textInputId} placeholder={t('general.emailInputPlaceholder')} value={email} onChange={onChange} disabled={disabled} themeName={theme} testId="user-email-input" ariaLabel={t('general.emailInputPlaceholder')} />
         {(!!typeAheadRecords.length && displayTypeAheadSuggestions) &&
            <TypeAheadWrapper widthInPixels={containerRef.current?.clientWidth} >
               {typeAheadRecords.map(record => <TypeAheadRecord onMouseDown={onTypeAheadRecordClick} key={`type-ahead-record-${record.email}`}>{record.email}</TypeAheadRecord>)}
            </TypeAheadWrapper>
         }
         {showValidationError && <StyledWarning id="error-message">{t('general.invalidEmailError')}</StyledWarning>}
      </div>
   );
};

export interface IUserEmailInputProps {
   textInputId: string;
   onEmailChange: (email: string, isValid: boolean, typeAheadRecord?: ITypeAheadRecord) => void;
   initialEmail?: string;
   disabled?: boolean;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {textInputId, initialEmail, disabled, onEmailChange}: IUserEmailInputProps,
   {theme}: Partial<IStateMappedProps>
): IStateMappedProps& IUserEmailInputProps => ({textInputId, initialEmail, disabled, onEmailChange, theme});

export default withMemoizedContexts(mapStatesToProps, themeStore)(UserEmailInputBase);
