const GetKey = (...args: string[]): string => args.join('-');

const GetSessionStorage = (key: string): (string | null) => typeof sessionStorage !== 'undefined' ? sessionStorage.getItem(key) : null;
const SetSessionStorage = (key: string, value: string): void => typeof sessionStorage !== 'undefined' && sessionStorage.setItem(key, value);
const ClearSessionStorage = (key: string): void => typeof sessionStorage !== 'undefined' && sessionStorage.removeItem(key);

const GetLocalStorage = (key: string): (string | null) => typeof localStorage !== 'undefined' ? localStorage.getItem(key) : null;
const SetLocalStorage = (key: string, value: string): void => typeof localStorage !== 'undefined' && localStorage.setItem(key, value);
const ClearLocalStorage = (key: string): void => typeof localStorage !== 'undefined' && localStorage.removeItem(key);

export {GetKey, GetLocalStorage, SetLocalStorage, ClearLocalStorage, GetSessionStorage, SetSessionStorage, ClearSessionStorage};