import StringUtil from '../util/StringUtil';
import Analytics, {AISeverityLevel} from './analytics';
import config from './config';

const shouldLogToConsole = () => {
   const currentEnvironment = config?.environmentData?.Environment ?? '';
   return currentEnvironment.toLowerCase() === 'local' || (currentEnvironment.toLowerCase() !== 'test' && StringUtil.isNullOrWhiteSpace(config?.environmentData?.ApplicationInsightsId));
};

const shouldLogToAI = () => !StringUtil.isNullOrWhiteSpace(config?.environmentData?.ApplicationInsightsId);

const trace = (message: string) => {
   // eslint-disable-next-line no-console
   shouldLogToConsole() && console.log(message);
   shouldLogToAI() && Analytics.aiTrace(message, AISeverityLevel.Information);
};

const warn = (message: string, ex?: Error) => {
   if (ex) {
      shouldLogToConsole() && console.warn(message, ex);
      shouldLogToAI() && Analytics.aiException(message, ex, AISeverityLevel.Warning);
      return;
   }
   shouldLogToConsole() && console.warn(message);
   shouldLogToAI() && Analytics.aiTrace(message, AISeverityLevel.Warning);
};

const error = (message: string, ex?: Error) => {
   if (ex) {
      shouldLogToConsole() && console.error(message, ex);
      shouldLogToAI() && Analytics.aiException(message, ex, AISeverityLevel.Error);
      return;
   }
   shouldLogToConsole() && console.error(message);
   shouldLogToAI() && Analytics.aiTrace(message, AISeverityLevel.Error);
};

export default {
   trace,
   warn,
   error
};
