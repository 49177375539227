import {TscStyles} from '@techsmith/tsc-cloud-style-guide';
import {isMobile} from 'react-device-detect';

export default {
   zIndex: {
      smartPlayerQuiz: 12,
      viewPageElement: 13,
      mobileContextMenu: 14,
      conversation: 14,
      subHeader: 14,
      header: 15,
      sidebar: 16,
      migrationModal: 16,
      basicModal: 17,
      alertModal: 18,
      skipToContentPopUp: 999
   },
   contextMenuMaxHeight: '20rem',
   defaultTextAreaHeight: 36,
   defaultModalWidth: '25.5rem',
   manageCollectionModalWidth: '35rem',
   defaultButtonWidth: '8rem',
   smallButtonWidth: '4rem',
   glyphButtonWidth: '2rem',
   contentWidth: '64rem',
   conversationPanelWidth: '22.5rem',
   contentWidthWithPadding: '62rem',
   regularWeight: 400,
   semiBoldWeight: 600,
   headerHeight: '3.25rem',
   headerBreakpoint: 1000,
   headerLogoHideBreakpoint: 400,
   headerSearchFocusBreakpoint: 600,
   subHeaderHeight: '2rem',
   // eslint-disable-next-line no-magic-numbers
   tableBreakpoint: (sidePaneOpen: boolean) => sidePaneOpen ? 1180 : 900,
   mobileHeightBreakpoint: 500,
   conversationsBreakpoint: 800,
   tabBreakpoint: 520,
   contextMenuBreakpoint: TscStyles.breakpoints.contextMenuBreakpoint,
   contentBreakpoint: 490,
   marketingPaneBreakpoint: 800,
   marketingPaneShowDetailTextBreakpoint: 645,
   snagitMarketingPaneHeight: '3.5rem',
   adminTabsBarBreakpoint: 1100,
   accessibilityTabButtonBreakpoint: 600,
   viewerTabsBarBreakpoint: 200,
   ltiTabsBarBreakpoint: 480,
   mediaDetailsButtonTextBreakPoint: 670,
   mediaDetailsButtonTextBreakPointWithConversationPaneOpen: 1000,
   mobileLibrarySectionMenuBreakPoint: 550,
   settingsContentBreakpoint: 640,
   whatsNewMobileBreakpoint: 500,
   mediaViewToggleSingleButtonBreakpoint: 820,
   mediaListViewHideOneColumnBreakpoint: 800,
   mediaListViewHideTwoColumnsBreakpoint: 700,
   mediaListViewHideThreeColumnsBreakpoint: 600,
   alertBarBackgroundColor: '#35352A',
   alertBarBorderColor: '#FFC82E',
   alertBarLinkTextColor: '#00A1F3',
   submenuBackgroundColor: '#353d47',
   dawnPageBackgroundColor: '#f3f2f1',
   headerBackgroundColor: '#1f242a',
   layoutBorderAccentColor: '#F9A531',
   collectionNavigationPaneNavigationDirectionColor: 'rgba(245, 246, 248, 0.80)',
   collectionNavigationPaneBackgroundColorDawn: 'rgba(0, 0, 0, 0.05)',
   collectionNavigationPaneBackgroundColorDusk: 'rgba(255, 255, 255, 0.05)',
   collectionNavigationPreviewBackground: 'rgba(21, 25, 28, 0.70)',
   brandGradient: 'linear-gradient(to right, rgb(247, 156, 0) 0%, rgb(239, 130, 0) 50%, rgb(201, 88, 54) 100%)',
   progressLoaderGradientOrange: 'linear-gradient(to right, rgb(247, 156, 0), rgb(239, 130, 0), rgb(201, 88, 54), rgb(239, 130, 0), rgb(247, 156, 0))',
   progressLoaderGradientGreen: 'linear-gradient(to right, #29A74F, #33DF67, #29A74F)',
   marketingPaneBgDawn: 'radial-gradient(143.85% 111.8% at 100% 0%, rgba(0, 71, 255, 0.10) 0%, rgba(0, 194, 255, 0.00) 100%), radial-gradient(120.44% 71.17% at 0.83% 0%, rgba(247, 10, 141, 0.10) 0%, rgba(247, 10, 141, 0.00) 100%), rgb(255, 255, 255)',
   marketingPaneBgDusk: 'radial-gradient(70% 100% at 100% 100%, rgba(0, 71, 255, 0.25) 0%, rgba(0, 194, 255, 0) 100%), radial-gradient(80% 100% at 0% 100%, rgba(247, 10, 141, 0.18) 0%, rgba(247, 10, 141, 0) 100%), rgb(0, 0, 0)',
   marketingPaneBgCamtasiaDawn: 'radial-gradient(100% 120.0% at 110% 0%, rgba(153, 229, 0, 0.12) 0%, rgba(85, 127, 0, 0.00) 100%), radial-gradient(100% 120.0% at -10% 100%, rgba(150, 115, 255, 0.15) 0%, rgba(150, 115, 255, 0.00) 100%), rgb(0, 0, 0)',
   marketingPaneBgCamtasiaDusk: 'radial-gradient(100% 120.0% at 100% 100%, rgba(153, 229, 0, 0.12) 0%, rgba(85, 127, 0, 0.00) 100%), radial-gradient(100% 120.0% at 0% 0%, rgba(150, 115, 255, 0.15) 0%, rgba(150, 115, 255, 0.00) 100%), rgb(0, 0, 0)',
   marketingPaneBorderDawn: '1px solid rgba(0, 0, 0, 0.15)',
   marketingPaneBorderDusk: '1px solid rgba(255, 255, 255, 0.1)',
   marketingPaneBorderCamtasiaDawn: '1px solid rgba(255, 255, 255, 0.11)',
   marketingPaneBorderCamtasiaDusk: '1px solid rgba(255, 255, 255, 0.11)',
   marketingPaneBoxShadowDawn: '0 0 0.375rem 0.0625rem rgb(255, 255, 255) inset, 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.05)',
   marketingPaneBoxShadowDusk: '0 .0625rem .375rem 0 rgba(0, 0, 0, 0.20), 0 0 .375rem .0625rem rgba(255, 255, 255, 0.13) inset',
   marketingPaneBoxShadowCamtasia: '0 0 .375rem .0625rem rgba(255, 255, 255, 0.10) inset, 0 .0625rem .375rem 0 rgba(0, 0, 0, 0.25)',
   noAccessPageBackground: 'radial-gradient(200.94% 210% at 4.22% -11.16%, rgba(130, 99, 255, 0.1) 0%, rgba(73, 255, 135, 0.08) 100%)',
   noAccessCardBoxShadow: '0 0.1875rem 0.75rem 0 rgba(0, 0, 0, 0.15), 0 0 0.25rem 0 rgba(0, 0, 0, 0.15), 0 0 0.25rem 0 rgba(255, 255, 255, 1) inset',
   noAccessGoHomeButtonBg: 'rgba(32, 35, 38, 1)',
   noAccessGoHomeButtonBgHover: 'rgba(43, 52, 52, 1)',
   noAccessGoHomeButtonBgActive: 'rgba(32, 35, 38, 1)',
   noAccessGoHomeButtonShadow: '0 1.5px 3px 0 rgba(0, 0, 0, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.15), 0 -3px 10px 0 rgba(255, 255, 255, 0.15) inset, 0 1.5px 3px 0 rgba(255, 255, 255, 0.3) inset',
   noAccessGoHomeButtonShadowHover: '0 0 0 1px rgba(43, 52, 52, 1) inset, 0px 1.5px 3px 0px rgba(0, 0, 0, 0.30), 0px 5px 12px 0px rgba(0, 0, 0, 0.20), 0px 2px 6px 0px rgba(255, 255, 255, 0.45) inset',
   noAccessGoHomeButtonShadowActive: '0 0 0 1px rgba(0, 0, 0, 1) inset, 0px 1px 2px 0px rgba(255, 255, 255, 0.3) inset, 0px -3px 10px 0px rgba(255, 255, 255, 0.15) inset, 0px 0px 3px 0px rgba(0, 0, 0, 0.3), 0px 0px 1px 0px rgba(0, 0, 0, 0.3)',
   noAccessSignOutButtonBg: 'rgba(248, 249, 250, 1)',
   noAccessSignOutButtonBgHover: 'rgba(255, 255, 255, 1)',
   noAccessSignOutButtonBgActive: 'rgba(248, 249, 250, 1)',
   noAccessSignOutButtonBorder: 'rgba(255, 255, 255, 1)',
   noAccessSignOutButtonShadow: '0px -3px 10px 0px #FFF inset, 0px 1px 2px 0.5px #FFF inset, 0px 0px 1px 0.5px rgba(0, 0, 0, 0.15), 0px 1.5px 5px 0px rgba(0, 0, 0, 0.10)',
   noAccessSignOutButtonShadowHover: '0px -3px 10px 0px #FFF inset, 0px 1px 4px 0px #FFF inset, 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 10px 0px rgba(0, 0, 0, 0.15)',
   noAccessSignOutButtonShadowActive: '0px 0px 3px 0px rgba(0, 0, 0, 0.15), 0px 0px 1px 0px rgba(0, 0, 0, 0.15), 0px 1px 4px 0px rgba(255, 255, 255, 1) inset, 0px -3px 10px 0px rgba(255, 255, 255, 1) inset',
   sidebarAccentColor: TscStyles.color.ui.dusk.mediumDark,
   sidebarWidth: '17rem',
   mobileNavMenuWidth: '20rem',
   batchSelectionIndicatorBreakpoint: 1200,
   smartPlayerMinWidth: isMobile ? '320px' : '430px',
   smartPlayerQuizSetupWidth: isMobile ? '320px' : '590px',
   smartPlayerQuizSetupHeight: isMobile ? '194px' : '331px',
   // constants stored here are used across multiple components to manage css math, non-specific names are in rems
   viewPageMeasurements: {
      layoutGridVerticalMargin: '.75rem',
      layoutGridHorizontalMargin: '.75rem',
      layoutGridGap: '.75rem',
      layoutReactionsHeight: '7rem',
      mediaTabHeaderHeight: '2.75rem',
      managementTabHorizontalMargin: '.375rem',
      managementTabHorizontalPadding: '.25rem',
      marketingBannerVerticalHeightReservation: '4rem' // actual height is 2.5-3.5rem depending on size, add 0.5rem to give spacing to video so it doesnt butt up against tabs
   },
   reactionsButtonHoverOpacity: 0.6,
   reactionsButtonEmbedHoverOpacity: 0.25,
   reactionsSummaryBackgroundOpacity: .85,
   variables: {
      headerHeight: '--headerHeight',
      marketingBannerHeight: '--marketingBannerHeight',
      mediaDetailsHeight: '--mediaDetailsHeight',
      zoom: '--zoom'
   }
};
