import {TextInput, ControlButton, TscButtonType, TscThemeName, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import copy from 'copy-to-clipboard';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';
import StringUtil from '../../util/StringUtil';

const InputWrapper = styled.div`
   margin-top: 0.125rem;
`;

const LinkInput = styled(TextInput)`
      margin-right: 0.5rem;
`;

export const CopyLinkFormCollectionBase: React.FC<ICopyLinkFormCollectionProps & IStateMappedProps> = ({link, text, theme, setCurrentCopy, currentCopy}) => {
   const {t} = useTranslation();
   const [copyButtonText, setCopyButtonText] = useState<string>(t('general.copy'));

   useEffect(() => {
      setCopyButtonText(!StringUtil.isNullOrWhiteSpace(currentCopy) && currentCopy === link ? t('general.copied') : t('general.copy'));
   }, [currentCopy]);

   const onCopyClick = () => {
      copy(link);
      setCopyButtonText(t('general.copied'));
      if (setCurrentCopy) {
         setCurrentCopy(link);
      }
   };

   const onLinkFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      event.target.select();
   };

   const onLinkClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      event.currentTarget.select();
   };

   const shareLinkInput = <LinkInput ariaLabel="Share URL" value={link} readOnly={true} onFocus={onLinkFocus} onClick={onLinkClick} testId="share-media-url" themeName={theme}/>;

   return (
      <div className="form-field-group">
         <label>{text}</label>
         <InputWrapper className="button-group">
            {shareLinkInput}
            <ControlButton
               label={copyButtonText}
               buttonType={TscButtonType.primary}
               themeName={TscThemeName.dawn}
               onClick={onCopyClick}
               testId="share-link-copy-button"
               width={'5rem'}
            />
         </InputWrapper>
      </div>
   );
};

export interface ICopyLinkFormCollectionProps {
   link: string;
   text: string;
   setCurrentCopy?(e: string): void;
   currentCopy: string;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {link, text, setCurrentCopy, currentCopy}: ICopyLinkFormCollectionProps,
   {theme}: Partial<IStateMappedProps>
): ICopyLinkFormCollectionProps & IStateMappedProps => ({link, text, theme, setCurrentCopy, currentCopy});

export default withMemoizedContexts(mapStatesToProps, themeStore)(CopyLinkFormCollectionBase);
