import React, {createContext, ReactElement, useState} from 'react';
import {MigrationName, MigrationState} from '../constants/Constants';
import {IMigrationModel} from '../model/migrationModel';
import {IWorkerState} from '../model/workerState';
import config from '../service/config';
import userApi from '../service/userApi';

const pollRetryInMs = 5000;

interface IMigrationProvider {
   migrationStoreWorkerState: IWorkerState;
   migrationInProgress: boolean;
   migrations: IMigrationModel[];
   isPolling: boolean;
}

const initialWorkerState: IWorkerState = {
   isWorking: false,
   hasError: false
};

const initialMigrations: IMigrationModel[] = [{
   MigrationName: MigrationName.none,
   MigrationState: MigrationState.none,
   UserAcknowledged: false
}];

const initialState: IMigrationProvider = {
   migrations: initialMigrations,
   migrationInProgress: false,
   migrationStoreWorkerState: initialWorkerState,
   isPolling: false
};

const store = createContext<IMigrationProvider>(initialState);
let privateState = initialState;
let privateUpdateState: React.Dispatch<React.SetStateAction<IMigrationProvider>> = () => null;
const publicUpdateState = (newState: Partial<IMigrationProvider>) => privateUpdateState(Object.assign({}, privateState, newState));
const Provider = store.Provider;
const isMigrationInProgress = (migration: IMigrationModel) => migration.MigrationState === MigrationState.inprogress;

const useProvider = (children: ReactElement) => {
   const loadedMigrations = config.migrations?.length > 0 ? config.migrations : initialMigrations;
   const migrationInProgress = loadedMigrations.some(isMigrationInProgress);
   const loadedInitialState = {...initialState, migrations: loadedMigrations, migrationInProgress: migrationInProgress};
   const [state, updateState] = useState<IMigrationProvider>(loadedInitialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

const acknowledgeMigration = async (migrationName: string) => {
   const index = privateState.migrations.findIndex(m => m.MigrationName === migrationName);

   // Could not find migration to acknowledge
   if (index === -1) {
      return;
   }

   publicUpdateState({migrationStoreWorkerState: {isWorking: true, hasError: false}});
   const updatedMigration = {...privateState.migrations[index]};
   
   try {
      await userApi.acknowledgeMigration(config.user.TechSmithId, updatedMigration);
   } catch {
      // Migration likely changed by another process; polling should detect updated migration
      publicUpdateState({migrationStoreWorkerState: initialWorkerState});
      return;
   }

   updatedMigration.UserAcknowledged = true;
   const updatedMigrations = [...privateState.migrations];
   updatedMigrations[index] = updatedMigration;
   publicUpdateState({migrations: updatedMigrations, migrationStoreWorkerState: initialWorkerState, migrationInProgress: updatedMigrations.some(isMigrationInProgress)});
};

const pollInProgressMigrations = async (): Promise<void> => {
   if (privateState.isPolling || !privateState.migrations.some(m => m.MigrationState === MigrationState.inprogress)) {
      return;
   }

   publicUpdateState({isPolling: true});

   let migrations: IMigrationModel[] = [];
   let migrationInProgress = true;

   try {
      migrations = await userApi.getMigrationStatus(config.user.TechSmithId);
      migrationInProgress = migrations.some(isMigrationInProgress);
      publicUpdateState({migrations, migrationInProgress});
   } catch (error) {
      // any errors including a 404 will result in polling discontinuing below
      migrationInProgress = false;
   }

   if (migrationInProgress) {
      window.setTimeout(() => {
         publicUpdateState({isPolling: false});
         void pollInProgressMigrations();
      }, pollRetryInMs);
   } else {
      publicUpdateState({isPolling: false});
   }
};

export {
   useProvider as useMigrationProvider,
   store as migrationStore,
   publicUpdateState as updateMigrationState,
   acknowledgeMigration,
   pollInProgressMigrations
};
