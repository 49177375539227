import React from 'react';
import {CollectionPrivacyOptionModel} from '../model/collectionPrivacyOptionModel';
import config from '../service/config';
import i18n from 'i18next';
import {Glyphs} from '@techsmith/tsc-cloud-style-guide';
import {CollectionPrivacyTypes} from '../constants/Constants';

export const getCollectionPrivacyOptions = () => {
   let publicTitle;
   let publicDescription;
   let privateTitle;
   let privateDescription;
   const isPublicOptionEnabled = config.arePublicVideosEnabled || config.isAdmin;
   const isListedOptionEnabled = !config.featureSwitches.DisableListedMediaPrivacy || config.isAdmin;

   const privacyExtras = {
      organizationName: config.organizationName,
      applicationShortName: config.applicationShortName
   };

   if (config.featureSwitches.HideEnterprisePrivacyOptions) {
      publicTitle = i18n.t('privacy.collection.public.singleuser.title');
      publicDescription = i18n.t('privacy.collection.public.singleuser.description', privacyExtras);
      privateTitle = i18n.t('privacy.collection.invite.singleuser.title');
      privateDescription = i18n.t('privacy.collection.invite.singleuser.description');
   } else {
      publicDescription = i18n.t('privacy.collection.public.enterprise.description', privacyExtras);
      publicTitle = i18n.t('privacy.collection.public.enterprise.title');
      privateTitle = i18n.t('privacy.collection.invite.enterprise.title');
      privateDescription = i18n.t('privacy.collection.invite.enterprise.description');
   }

   const optionsMap = new Map<CollectionPrivacyTypes, CollectionPrivacyOptionModel>();

   optionsMap.set(CollectionPrivacyTypes.public, {
      value: CollectionPrivacyTypes.public,
      label: publicTitle,
      subtext: publicDescription,
      glyph: <Glyphs.PrivacyLink16x16/>,
      disabled: !isPublicOptionEnabled,
      badgeText: !isPublicOptionEnabled ? i18n.t('privacy.adminOnlyText') : ''
   });

   !config.featureSwitches.HideEnterprisePrivacyOptions && optionsMap.set(CollectionPrivacyTypes.organization, {
      value: CollectionPrivacyTypes.organization,
      label: i18n.t('privacy.collection.listedOrg.title', privacyExtras),
      subtext: i18n.t('privacy.collection.listedOrg.description', privacyExtras),
      glyph: <Glyphs.PrivacyOrgAll16x16/>,
      disabled: !isListedOptionEnabled,
      badgeText: !isListedOptionEnabled ? i18n.t('privacy.adminOnlyText') : ''
   });

   !config.featureSwitches.HideEnterprisePrivacyOptions && optionsMap.set(CollectionPrivacyTypes.link, {
      value: CollectionPrivacyTypes.link,
      label: i18n.t('privacy.collection.unlistedOrg.title', privacyExtras),
      subtext: i18n.t('privacy.collection.unlistedOrg.description', privacyExtras),
      glyph: <Glyphs.PrivacyOrgLink16x16 />,
      disabled: false
   });

   optionsMap.set(CollectionPrivacyTypes.invite, {
      value: CollectionPrivacyTypes.invite,
      label: privateTitle,
      subtext: privateDescription,
      glyph: <Glyphs.Lock16x16 />,
      disabled: false
   });

   return optionsMap;
};
