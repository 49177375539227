import {CancelTokenSource} from 'axios';
import {newGuid} from '../util/Guid';

export enum MediaTypes {
   video,
   image,
   audio,
   unknown
}

export enum FileUploadStates {
   rejectedType,
   fileSizeError,
   unknownType,
   networkError,
   rejectedByServerError,
   initialized,
   ready,
   uploading,
   processing,
   completed,
   cancelled
}

export interface IFileUploadCandidate {
   id: string;
   file: File;
   uploadProgress: number;
   state: FileUploadStates;
   mediaType: MediaTypes;
   cancelTokenSource?: CancelTokenSource;
   uploadErrorStatusCode?: number;
}

export const nullFileUploadCandidate = (data: Partial<IFileUploadCandidate> = {}): IFileUploadCandidate => {
   const nullFileUploadCandidateData: IFileUploadCandidate = {
      id: newGuid(),
      file: null,
      state: FileUploadStates.initialized,
      uploadProgress: 0,
      mediaType: MediaTypes.unknown
   };

   return Object.assign({}, nullFileUploadCandidateData, data);
};
