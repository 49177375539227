/* parts of this file are vendor snippets so we are liberal on our linting exclusions */
/* eslint-disable camelcase, no-sequences, no-underscore-dangle, @typescript-eslint/no-unsafe-return */

import {IExceptionTelemetry, IMetricTelemetry, SeverityLevel as AISeverityLevel} from '@microsoft/applicationinsights-common';
import Optional from '../model/optional';
import StringUtil from '../util/StringUtil';
import config from './config';
import {EnvironmentType} from '../model/appModel';
import {importAppInsightsReact, importAppInsightsWeb} from '../hooks/dynamicImport';
import {Initialization} from '@microsoft/applicationinsights-web/types/Initialization';

export interface IUAEventParams {
   [key: string]: string | number;
}

export interface IUaEventOptions {
   eventName: string;
   eventParams: IUAEventParams | null;
}

// this component manages initializing analytics services like google-analytics, application-insights
// it does not render any visible component in itself
let appInsights: Optional<Initialization> = null;
let gaEnabled = false;
let aiEnabled = false;
// this array is used to queue google analytics events to send after initialization if they are triggered prior to initialization
let gaEventQueue: IUaEventOptions[] = [];
const filteredAiDependencyHosts = [
   /google-analytics\.com$/i
];

const logInitializationAppropriately = (message: string) => {
   const shouldLogToToConsole = config.environmentData.Environment === EnvironmentType.local || (config.environmentData.Environment !== EnvironmentType.test && !aiEnabled);
   // eslint-disable-next-line no-console
   shouldLogToToConsole && console.log(message);
   aiEnabled && aiTrace(message, AISeverityLevel.Information);
};

const addGA4Event = (event: IUaEventOptions) => {
   const eventObject = {
      event: 'ga4.trackEvent',
      eventName: event.eventName,
      eventParams: {}
   };

   if (event.eventParams) {
      eventObject.eventParams = event.eventParams;
   }

   // @ts-ignore
   if (typeof window.dataLayer !== 'undefined' && Array.isArray(window.dataLayer)) {
      // @ts-ignore
      window.dataLayer.push(eventObject);
   } else {
      console.info('Not sending event to GA4 because dataLayer is not defined or not an array');
   }
};

const initGoogleAnalytics = () => {
   // Google tag manager controls adding dataLayer to the window object
   // @ts-ignore
   if (typeof window.dataLayer === 'undefined' && !Array.isArray(window.dataLayer)) {
      logInitializationAppropriately('Not initializing Google Analytics GA4, because dataLayer is not defined or not an array');
      return;
   }

   gaEnabled = true;

   for (const event of gaEventQueue) {
      addGA4Event(event);
   }
   gaEventQueue = [];
};

const initApplicationInsights = async () => {
   if (StringUtil.isNullOrWhiteSpace(config.environmentData.ApplicationInsightsId)) {
      logInitializationAppropriately('Not initializing ApplicationInsights because configuration id not set');
      return;
   }

   const {ReactPlugin} = await importAppInsightsReact();
   const {ApplicationInsights} = await importAppInsightsWeb();

   const reactPlugin = new ReactPlugin();

   appInsights = new ApplicationInsights({
      config: {
         instrumentationKey: config.environmentData.ApplicationInsightsId,
         extensions: [reactPlugin],
         extensionConfig: {
            [reactPlugin.identifier]: {debug: false}
         }
      }
   });

   appInsights.loadAppInsights();

   appInsights.addTelemetryInitializer(envelope => {
      const telemetryItem = envelope.baseData;

      if (telemetryItem) {
         telemetryItem.properties = telemetryItem.properties || {};
         telemetryItem.properties.Environment = config.environmentData.Environment;
         telemetryItem.properties.Tenant = config.environmentData.Tenant;
         telemetryItem.properties.user = telemetryItem.properties.user || {};
         telemetryItem.properties.user.IsSignedIn = config.user.IsSignedIn;
         telemetryItem.properties.user.TechSmithId = config.user.TechSmithId;
      }
   });

   appInsights.addTelemetryInitializer(envelope => {
      let sendItem = true;

      if (envelope.baseType === 'RemoteDependencyData') {
         const ajaxUri = envelope.baseData.target;
         // https://www.ietf.org/rfc/rfc3986.txt (AppendixB)
         const urlParts = ajaxUri.match(RegExp('^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?'));
         // eslint-disable-next-line no-magic-numbers
         const host = urlParts.length >= 5 ? urlParts[4] : ''; // authority/host is the 4th matchgroup above
         filteredAiDependencyHosts.forEach(h => {
            if (h.test(host)) {
               sendItem = false;
            }
         });
      }

      return sendItem;
   });

   appInsights.trackPageView({
      name: 'WatchPageApp'
   });

   aiEnabled = true;
};

const initialize = async () => {
   await initApplicationInsights();
   initGoogleAnalytics();
};

const gaEvent = (event: IUaEventOptions) => {
   if (gaEnabled) {
      addGA4Event(event);
   } else {
      gaEventQueue.push(event);
   }
};

const aiException = (message: string, exception: Error, severityLevel: AISeverityLevel) => {
   if (aiEnabled && appInsights) {
      const exceptionInfo: IExceptionTelemetry = {
         exception: exception,
         severityLevel: severityLevel
      };
      if (!StringUtil.isNullOrWhiteSpace(message)) {
         exceptionInfo.properties = exceptionInfo.properties || {};
         exceptionInfo.properties.Message = message;
      }
      appInsights.trackException(exceptionInfo);
   }
};

const aiTrace = (message: string, severityLevel: AISeverityLevel) => {
   if (aiEnabled && appInsights) {
      appInsights.trackTrace({
         message,
         severityLevel
      });
   }
};

const aiEvent = (event: IAnalyticsAIEvent) => {
   if (aiEnabled && appInsights) {
      appInsights.trackEvent({
         name: event.name,
         properties: event.label || event.value ? {
            [event.label || 'value']: event.value || '1'
         } : {}
      });
   }
};

const aiMeasurement = (measurement: IAnalyticsAIMeasurement) => {
   if (aiEnabled && appInsights) {
      appInsights.trackEvent({
         name: measurement.name,
         measurements: {
            [measurement.label || 'value']: measurement.value
         }
      });
      appInsights.trackEvent(measurement);
   }
};

const aiMetric = (metric: IMetricTelemetry) => {
   if (aiEnabled && appInsights) {
      appInsights.trackMetric(metric);
   }
};

const aiConfigureCustomDimensions = (customDims: object, customDimsContainerName = '') => {
   if (aiEnabled && appInsights) {
      appInsights.addTelemetryInitializer(envelope => {
         const telemetryItem = envelope.baseData;

         if (telemetryItem) {
            telemetryItem.properties = telemetryItem.properties || {};
            let customDimsContainer = telemetryItem.properties;
            if (!StringUtil.isNullOrWhiteSpace(customDimsContainerName)) {
               telemetryItem.properties[customDimsContainerName] = telemetryItem.properties[customDimsContainerName] || {};
               customDimsContainer = telemetryItem.properties[customDimsContainerName];
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            customDimsContainer = Object.assign(customDimsContainer, customDims);
         }
      });
   }
};

export default {
   initialize,
   gaEvent,
   aiException,
   aiTrace,
   aiEvent,
   aiMeasurement,
   aiMetric,
   aiConfigureCustomDimensions
};

export {AISeverityLevel};

export interface IAnalyticsAIEvent {
   name: string;
   label?: string;
   value?: string;
}

export interface IAnalyticsAIMeasurement {
   name: string;
   label?: string;
   value: number;
}
