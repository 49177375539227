import {TscThemeName, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import React, {createContext, ReactElement, useState} from 'react';
import Constants from '../constants/Constants';
import logging from '../service/logging';

const getTheme = () => {
   try {
      const themeCookie: string[] = document.cookie.split(';').filter(item => item.trim().startsWith(`${Constants.themeKey}=`));
      if (themeCookie?.length) {
         return themeCookie[0].split('=')[1].trim();
      }
      return null;
   } catch (e) {
      logging.error('Failed to get theme cookie: ', e);
      return null;
   }
};

const getThemeFromCookieOrBrowserSettings = () => {
   const prefersLightTheme = window.matchMedia('(prefers-color-scheme: light)').matches;
   const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
   const storedUserTheme = getTheme();
   if (storedUserTheme) {
      return storedUserTheme === TscThemeName.dawn ? TscThemeName.dawn : TscThemeName.dusk;
   } else if (prefersLightTheme) {
      return TscThemeName.dawn;
   } else if (prefersDarkTheme) {
      return TscThemeName.dusk;
   }
   return TscThemeName.dawn;
};

interface IThemeProvider {
   theme: TscThemeNames;
}

const setBodyClass = (newTheme: TscThemeNames) => {
   if (newTheme === TscThemeName.dawn) {
      document.body.classList.remove(TscThemeName.dusk);
      document.body.classList.add(TscThemeName.dawn);
   } else {
      document.body.classList.remove(TscThemeName.dawn);
      document.body.classList.add(TscThemeName.dusk);
   }
};

const initialState: IThemeProvider = {theme: getThemeFromCookieOrBrowserSettings()};
const store = createContext<IThemeProvider>(initialState);
const Provider = store.Provider;
let privateState = initialState;
let privateUpdateState: React.Dispatch<React.SetStateAction<IThemeProvider>> = () => null;
const publicUpdateState = (newTheme: TscThemeNames) => {
   privateUpdateState(Object.assign({}, privateState, {theme: newTheme}));

   try {
      const yearOffset = 10;
      const date = new Date();
      date.setFullYear(date.getFullYear() + yearOffset);
      const dateAsString = date.toUTCString();
      document.cookie = `${Constants.themeKey}=${newTheme}; expires=${dateAsString}; path=/`;
      document.cookie = `${Constants.themeKey}=${newTheme}; expires=${dateAsString}; path=/c`;
   } catch (e) {
      logging.error('Failed to set theme cookie: ', e);
   }

   setBodyClass(newTheme);
};

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<IThemeProvider>(initialState);
   privateUpdateState = updateState;
   privateState = state;
   setBodyClass(state.theme);

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useThemeProvider,
   store as themeStore,
   publicUpdateState as updateThemeState
};
