import React from 'react';
import {useTranslation} from 'react-i18next';
import {MenuItem, TscThemeName, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import config from '../../service/config';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore, updateThemeState} from '../../context/themeProvider';
import {SimpleList} from '../util/StyledElements';
import Constants from '../../constants/Constants';

export const SettingsMenuBase: React.FC<ISettingsMenuProps & IStateMappedProps> = ({hideThemePicker, theme}) => {
   const {t} = useTranslation();

   const onThemeChange = (): void => {
      const newTheme = theme === TscThemeName.dusk ? TscThemeName.dawn : TscThemeName.dusk;
      updateThemeState(newTheme);
   };

   const navigateToSettings = () => {
      window.location.href = Constants.navigation.userSettingsLink;
   };

   const navigateToAdmin = () => {
      window.location.href = Constants.navigation.admin.adminLink;
   };

   return (
      <>
         <SimpleList>
            {!hideThemePicker &&
               <MenuItem key={t('themeSwitchKey')} label={theme === TscThemeName.dusk ? t('useLightTheme') : t('useDarkTheme')} onClick={onThemeChange} />
            }
            {config.user.IsSignedIn && config.featureSwitches.IsScreencast && <MenuItem key="settings" label={t('Settings')} onClick={navigateToSettings} />}
            {config.isAdmin && config.featureSwitches.IsScreencast && <MenuItem key="admin" label={t('SiteAdmin')} onClick={navigateToAdmin} />}
         </SimpleList>
      </>
   );
};

export interface ISettingsMenuProps {
   hideThemePicker?: boolean;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {hideThemePicker}: ISettingsMenuProps,
   {theme}: IStateMappedProps
): IStateMappedProps & ISettingsMenuProps => ({hideThemePicker, theme});

export default withMemoizedContexts(mapStatesToProps, themeStore)(SettingsMenuBase);
