import React, {createContext, ReactElement, useState} from 'react';

interface IMediaOperationsStore {
   IsReplacingMedia: boolean;
}

const initialState: IMediaOperationsStore = {IsReplacingMedia: false};
const store = createContext<IMediaOperationsStore>(initialState);
const Provider = store.Provider;
let privateState = initialState;
let privateUpdateState: React.Dispatch<React.SetStateAction<IMediaOperationsStore>> = () => null;

const publicUpdateState = (newState: Partial<IMediaOperationsStore>) => {
   privateUpdateState((Object.assign({}, privateState, newState)));
};

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<IMediaOperationsStore>(initialState);
   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useMediaOperationsProvider,
   store as mediaOperationsStore,
   publicUpdateState as updateMediaOperationsState
};
