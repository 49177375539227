import React, {useRef, useState, useEffect} from 'react';

import styled from 'styled-components';
import {Glyphs, TscStyles, ControlButton, TscButtonType, TscThemeName} from '@techsmith/tsc-cloud-style-guide';
import Axios from 'axios';
import searchApi from '../../service/searchApi';
import {ITypeAheadRecord} from '../../model/typeAheadRecordModel';
import {useTranslation} from 'react-i18next';
import SearchResultUser from '../../static/svg/SearchResultUser.svg';
import cssConstants from '../../constants/cssConstants';
import config from '../../service/config';

interface MyWindow extends Window {
   tscForceShowSearchResults: boolean; // for debuggability
}

declare let window: MyWindow;

const SearchBarContainer = styled.div<{hasFocus: boolean}>`
   border-radius: ${TscStyles.border.radius.md};
   background: ${props => props.hasFocus ? TscStyles.color.text.mediumDark : 'none'};
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   transition: background 0.2s;
   margin: 0 0.5rem 0 1rem!important;
`;

const SearchBarInput = styled.input<{hasFocus: boolean}>`
   background: none;
   color: ${TscStyles.color.text.light};
   outline: none;
   border: none;
   padding-left: ${props => props.hasFocus ? '0.5rem' : 0};
   width: ${props => props.hasFocus ? '14rem' : 0};
   transition: width 0.2s;

   ::placeholder {
      color: ${TscStyles.color.text.light};
      opacity: .7;
   }

   /* hide the native ui for search bar */
   ::-webkit-search-decoration,
   ::-webkit-search-cancel-button,
   ::-webkit-search-results-button,
   ::-webkit-search-results-decoration {
      display: none;
   }
`;

const SearchForm = styled.form`
   display: flex;
`;

const TypeAheadWrapper = styled.ul<{displayed: boolean}>`
   background-color: ${TscStyles.color.ui.dawn.light};
   border-top: none;
   border-radius: ${TscStyles.border.radius.md};
   color: ${TscStyles.color.text.dark};
   box-sizing: border-box;
   box-shadow: rgba(0, 0, 20, 0.25) 1px 1px 12px;
   position: absolute;
   top: 2.0rem;
   left: 0;
   min-width: 100%;
   max-width: 15rem;
   max-height: 29rem;
   overflow: auto;
   display: flex;
   flex-direction: column;
   z-index: ${cssConstants.zIndex.header};
   padding: 0;
   margin: 0;

   div:last-of-type {
      border-radius: 0 0 ${TscStyles.border.radius.md} ${TscStyles.border.radius.md};
   }
`;

const TypeAheadRecord = styled.li`
   box-sizing: border-box;
   cursor: pointer;
   padding: 0 0.5rem;
   text-decoration: none;
   list-style: none;

   &:hover {
      background-color: ${TscStyles.color.ui.dawn.medium};
   }

   svg {
      margin-right: 0.75rem;
      fill: ${TscStyles.color.ui.dusk.medium};
      color: ${TscStyles.color.ui.dusk.medium};
   }

   :first-child {
      border-radius: ${TscStyles.border.radius.md} ${TscStyles.border.radius.md} 0 0;

      a {
         border: none!important;
      }
   }

   :last-child {
      border-radius: 0 0 ${TscStyles.border.radius.md} ${TscStyles.border.radius.md};
   }
`;

const TypeAheadGlyph = styled.span`
   vertical-align: middle;
`;

const TypeAheadLink = styled.a`
   display: flex;
   align-items: center;
   text-decoration: none;
   text-overflow: ellipsis;
   overflow: hidden;
   height: 1.75rem;
   padding: 0.5rem 0.25rem;
   border-top: 1px solid ${TscStyles.color.ui.dawn.medium};
   color: ${TscStyles.color.text.mediumDark};
`;

const {Search16x16} = Glyphs;

const recordTypesToGlyphs: {[key: string]: React.FC} = {
   user: SearchResultUser,
   image: Glyphs.Image16x16,
   video: Glyphs.Video16x16,
   group: Glyphs.Collection16x16
};

const SearchBar: React.FC<ISearchBarProps> = ({onSearchFocus, onSearchBlur}: ISearchBarProps) => {
   const [inputValue, setInputValue] = useState('');
   const [hasFocus, setHasFocus] = useState(false);
   const [performSearchOnIconClick, setPerformSearchOnIconClick] = useState(false);
   const inputRef = useRef<HTMLInputElement>(null);
   const [searchResults, setSearchResults] = useState<ITypeAheadRecord[]>([]);
   const cancelTokenSourceRef = useRef(null);
   const {t} = useTranslation();
   const searchBarContainerRef = useRef(null);
   const blurTimeout = useRef(-1);
   const focusTimeout = useRef(-1);

   const performSearch = () => {
      if (config.featureSwitches.IsScreencast) {
         return;
      }
      window.location.href = `/search?q=${inputValue}`;
   };

   const onInputValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      setSearchResults([]);
   };

   const onFormClicked = (event: React.MouseEvent) => {
      event.preventDefault();

      if (inputRef.current) {
         inputRef.current.focus();
      }
   };

   const onSearchButtonClicked = (event: React.MouseEvent) => {
      event.preventDefault();
      if (inputValue.trim() && performSearchOnIconClick) {
         performSearch();
         return;
      }

      if (inputRef.current) {
         inputRef.current.focus();
      }
   };

   const onFormSubmit = (event: React.FormEvent) => {
      event.preventDefault();

      if (inputValue.trim()) {
         performSearch();
      }
   };

   const onContainerBlur = () => {
      // kinda hax to manage focus state because of react eventing
      // see https://medium.com/@jessebeach/dealing-with-focus-and-blur-in-a-composite-widget-in-react-90d3c3b49a9b
      const blurTimeoutDelay = 200;
      blurTimeout.current = window.setTimeout(() => {
         setHasFocus(false);
         setPerformSearchOnIconClick(false);
      }, blurTimeoutDelay);
      clearTimeout(focusTimeout.current);
   };

   const blurFocusDelay = 500;
   const onContainerFocus = () => {
      clearTimeout(blurTimeout.current);
      setHasFocus(true);
      focusTimeout.current = window.setTimeout(() => {
         setPerformSearchOnIconClick(true);
      }, blurFocusDelay);
   };

   useEffect(() => {
      const updateTypeahead = async () => {
         cancelTokenSourceRef.current?.cancel();
         const localCancelTokenSource = Axios.CancelToken.source();
         cancelTokenSourceRef.current = localCancelTokenSource;

         let typeAheadRecords = [];
         try {
            typeAheadRecords = await searchApi.getTypeAheadSearchResults(inputValue, localCancelTokenSource);
         } catch (e) {
            // This can get canceled. It's ok. Suppress console errors.
            return;
         }

         cancelTokenSourceRef.current = null;
         setSearchResults(typeAheadRecords);
      };

      if (inputValue) {
         void updateTypeahead();
      }
   }, [inputValue]);

   useEffect(() => {
      if (hasFocus) {
         onSearchFocus();
      } else {
         onSearchBlur();
      }
   }, [hasFocus]);

   const getTypeAheadElementFromRecord = (record: ITypeAheadRecord): React.JSX.Element => {
      const GlyphElement = recordTypesToGlyphs[record.recordtype];
      return (
         <TypeAheadRecord key={record.Id} role="option">
            <TypeAheadLink title={record.DisplayLabel} href={`/searchredirect?type=${record.recordtype}&id=${record.uniqueid}`}>
               <TypeAheadGlyph>
                  <GlyphElement />
               </TypeAheadGlyph>
               <span>{record.DisplayLabel}</span>
            </TypeAheadLink>
         </TypeAheadRecord>
      );
   };

   const searchBarFocused = hasFocus || !!window.tscForceShowSearchResults;
   const typeAheadDisplayed = !!searchResults.length && searchBarFocused;

   return (
      <SearchBarContainer hasFocus={searchBarFocused} onBlur={onContainerBlur} onFocus={onContainerFocus} ref={searchBarContainerRef}>
         <SearchForm onClick={onFormClicked} onSubmit={onFormSubmit}>
            <SearchBarInput type="search" placeholder={t('search.placeHolderText')} hasFocus={searchBarFocused} maxLength={80} aria-label={t('search.placeHolderText')} aria-owns="search-results" value={inputValue} onChange={onInputValueChanged} ref={inputRef} className="t-search-box" />
            <ControlButton type="submit" themeName={TscThemeName.dusk} glyph={<Search16x16 />} buttonType={TscButtonType.tertiary} title={t('search.search')} testId="t-search-icon" onClick={onSearchButtonClicked} />
         </SearchForm>
         <TypeAheadWrapper role="listbox" aria-hidden={!typeAheadDisplayed} id="search-results" displayed={typeAheadDisplayed}>
            {typeAheadDisplayed && (
               <>
                  {searchResults.map(getTypeAheadElementFromRecord)}
                  {!config.featureSwitches.IsScreencast && <TypeAheadRecord role="option">
                     <TypeAheadLink title={t('search.viewAllSearchResults')} href={`/search?q=${inputValue}`}>
                        <TypeAheadGlyph>
                           <Glyphs.Search16x16 />
                        </TypeAheadGlyph>
                        <span>{t('search.viewAllSearchResults')}</span>
                     </TypeAheadLink>
                  </TypeAheadRecord>}
               </>
            )}
         </TypeAheadWrapper>
      </SearchBarContainer>
   );
};

export interface ISearchBarProps {
   onSearchFocus: () => void;
   onSearchBlur: () => void;
}

export default SearchBar;
