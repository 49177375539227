import ApiUtil from '../util/ApiUtil';
import {IQuizData} from '../model/quizModel';
import {AxiosResponse} from 'axios';
import FileDownloader from '../util/FileDownloader';
import {IMediaAnalyticsResponseModel} from '../model/appModel';
import Constants from '../constants/Constants';
import config from './config';
import extractFilenameFromContentDispositionHeader from '../util/ContentDispositionFilenameExtractor';

const quizBaseUrl = '/api/v1/quiz';
const quizzingServiceInstanceUrl = 'v/1/quiz/instance';
const quizzingServiceProgressUrl = 'v/1/quiz/progress';

const downloadQuizResultsData = async (hash: string) => {
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(`${quizBaseUrl}/${hash}/resultscsv`, [Constants.statusCodes.ok], null, {responseType: 'arraybuffer'});
   if (response.status === Constants.statusCodes.ok) {
      const filename = extractFilenameFromContentDispositionHeader(response);
      response.data && FileDownloader.download(filename || `${hash}-quiz-data.zip`, response.data, 'application/zip');
   }
};

const downloadViewingAnalyticsData = async (hash: string) => {
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(`${quizBaseUrl}/${hash}/analyticscsv`, [Constants.statusCodes.ok]);
   if (response.status === Constants.statusCodes.ok) {
      const filename = extractFilenameFromContentDispositionHeader(response);
      response.data && FileDownloader.download(filename || `${hash}-analytics-data.csv`, response.data, 'text/csv');
   }
};

const getQuizData = async (hash: string) => {
   const response = await ApiUtil.executeGetWithoutCacheAsync(`${quizBaseUrl}/${hash}`, [Constants.statusCodes.ok, Constants.statusCodes.created]);
   return response.data as IQuizData;
};

const updateQuizRequireUserId = async (hash: string, requireUserId: boolean) => {
   await ApiUtil.executePatchAsync(`${quizBaseUrl}/${hash}/requireUserId`, [Constants.statusCodes.ok, Constants.statusCodes.noContent], {requireUserId}, {withCredentials: true});
};

const removeQuiz = async (hash: string) => {
   await ApiUtil.executeDeleteAsync(`${quizBaseUrl}/${hash}`, [Constants.statusCodes.noContent]);
};

const removeQuizResponses = async (hash: string) => {
   await ApiUtil.executeDeleteAsync(`${quizBaseUrl}/${hash}/responses`, [Constants.statusCodes.noContent]);
};

const getMediaAnalytics = async (mediaId: string): Promise<IMediaAnalyticsResponseModel> => {
   const response = await ApiUtil.executeGetWithoutCacheAsync(`${quizBaseUrl}/${mediaId}/analytics`, [Constants.statusCodes.ok]);
   return response.data as IMediaAnalyticsResponseModel;
};

const updateAnalyticsForImage = async (quizId: string, firstName?: string, lastName?: string, email?: string) => {
   const quizInstanceResponse = await ApiUtil.executePostAsync(`${config.environmentData.QuizzingServiceUrl}/${quizzingServiceInstanceUrl}`, [Constants.statusCodes.ok], {quiz: quizId, firstname: firstName, lastname: lastName, email: email});

   if (quizInstanceResponse.status === Constants.statusCodes.ok) {
      await ApiUtil.executePostAsync(`${config.environmentData.QuizzingServiceUrl}/${quizzingServiceProgressUrl}`, [Constants.statusCodes.ok], {InstanceId: quizId, Interval: 100, ViewedSegments: [0]});
   }
};

export default {
   downloadQuizResultsData,
   downloadViewingAnalyticsData,
   getQuizData,
   removeQuiz,
   removeQuizResponses,
   getMediaAnalytics,
   updateQuizRequireUserId,
   updateAnalyticsForImage
};
