import React from 'react';
import {TscButtonType} from '@techsmith/tsc-cloud-style-guide';
import {useTranslation} from 'react-i18next';
import config from '../../service/config';
import {AlertModalPortal} from '../util/ModalPortalHelper';

const BatchContentWarningModal: React.FC<IBatchContentWarningModalProps> = ({modalType, validContentCount, onConfirm, onClose}) => {
   const {t} = useTranslation();

   const warningModalText = {
      title: '',
      content: '',
      confirm: ''
   };

   switch (modalType) {
      case BatchContentWarningModalType.Move: {
         warningModalText.title = t('moveContent.modals.moveContentToFolderWarning.title');
         warningModalText.content = t('moveContent.modals.moveContentToFolderWarning.content', {applicationShortName: config.applicationShortName});
         warningModalText.confirm = t('moveContent.modals.moveContentToFolderWarning.confirm');
         break;
      }
      case BatchContentWarningModalType.CreateMove: {
         warningModalText.title = t('moveContent.modals.moveContentToFolderWarning.title');
         warningModalText.content = t('moveContent.modals.moveContentToFolderWarning.content');
         warningModalText.confirm = t('moveContent.modals.moveContentToFolderWarning.confirm');
         break;
      }
      case BatchContentWarningModalType.AddToCollection: {
         warningModalText.title = t('moveContent.modals.moveContentToCollectionWarning.title');
         warningModalText.content = t('moveContent.modals.moveContentToCollectionWarning.content', {numMedia: validContentCount.toString()});
         warningModalText.confirm = t('moveContent.modals.moveContentToCollectionWarning.confirm');
         break;
      }
      case BatchContentWarningModalType.CreateAddToCollection: {
         warningModalText.title = t('moveContent.modals.moveContentToCollectionWarning.title');
         warningModalText.content = t('moveContent.modals.moveContentToCollectionWarning.content', {numMedia: validContentCount.toString()});
         warningModalText.confirm = t('moveContent.modals.moveContentToCollectionWarning.confirm');
         break;
      }
   }

   return (
      <AlertModalPortal
         title={warningModalText.title}
         onClose={onClose}
         onConfirm={onConfirm}
         confirmButtonType={TscButtonType.primary}
         confirmContent={warningModalText.confirm}
         onDismiss={onClose}
         dismissButtonType={TscButtonType.secondary}
         dismissContent={t('general.cancel')}
      >{warningModalText.content}</AlertModalPortal>
   );
};

export default BatchContentWarningModal;

export enum BatchContentWarningModalType {
   Move,
   CreateMove,
   AddToCollection,
   CreateAddToCollection
}

export interface IBatchContentWarningModalProps {
   modalType: BatchContentWarningModalType;
   validContentCount: number;
   onConfirm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
   onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
