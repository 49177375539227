import React, {useState} from 'react';
import styled from 'styled-components';
import {useDrop, DropTargetMonitor} from 'react-dnd';
import {IDraggableContent, IContentBatchList} from '../../model/draggableLibraryContent';
import {IMediaDetails} from '../../model/mediaDetails';
import {IFolderTreeNode} from '../../model/folderTreeNode';
import {DragAndDropItemTypes} from '../../model/dragAndDropItemTypes';
import {clearBatchSelection} from '../../context/batchSelectionProvider';
import BatchContentWarningModal, {BatchContentWarningModalType} from '../modals/BatchContentWarningModal';
import {TscStyles} from '@techsmith/tsc-cloud-style-guide';

const invalidDropTargetOpacity = 0.25;
export const DropTargetContainer = styled.div<{canDrop: boolean; isDragging: boolean}>`
   position: relative;

   > :first-child {
      &:after {
         content: '';
         display: block;
         position: absolute;
         top: 0;
         bottom: 0;
         left: 0;
         right: 0;
         background-color: ${TscStyles.color.ui.dusk.mediumDark};
         opacity: ${props => props.isDragging && !props.canDrop ? invalidDropTargetOpacity : 0};
         border-radius: ${TscStyles.border.radius.md};
         pointer-events: none;
      }
   }
`;

const ContentDropTarget: React.FC<IDropTargetProps> = ({children, accept, onDrop, isValidMedia, isValidFolder, warningModalType}) => {
   const defaultWarningModalState = {
      showModal: false,
      actionableContent: null as IContentBatchList,
      clearBatchSelection: false
   };

   const [warningModalInfo, setWarningModalInfo] = useState(defaultWarningModalState);

   const canDropContent = (content: IDraggableContent) => {
      if (content.type === DragAndDropItemTypes.Media) {
         return isValidMedia ? isValidMedia(content.item as IMediaDetails) : true;
      }
      if (content.type === DragAndDropItemTypes.Folder) {
         return isValidFolder ? isValidFolder(content.item as IFolderTreeNode) : true;
      }
      if (content.type === DragAndDropItemTypes.Mixed) {
         // this technique will properly show 'collections' for example as invalid drop targets if all you have selected are folders
         // this may become non-performant, if front-end performance drops with _MANY_ folders and/or _MANY_ batch items selected,
         // if necessary you could just return 'true' here, allow the batch drop and fall back to calculating the filtered list when you drop
         const mediaList: IMediaDetails[] = (content.item as IContentBatchList).media ?? [];
         const folderList: IFolderTreeNode[] = (content.item as IContentBatchList).folders ?? [];

         const filteredMediaList = mediaList.filter(media => isValidMedia ? isValidMedia(media) : true);
         const filteredFolderList = folderList.filter(folder => isValidFolder ? isValidFolder(folder) : true);

         if (filteredMediaList.length === 0 && filteredFolderList.length === 0) {
            return false;
         }

         return true;
      }
      return false;
   };

   const dropContent = async (content: IDraggableContent) => {
      let mediaList: IMediaDetails[] = [];
      let folderList: IFolderTreeNode[] = [];
      if (content.type === DragAndDropItemTypes.Media) {
         if (isValidMedia ? isValidMedia(content.item as IMediaDetails) : true) {
            mediaList = [content.item as IMediaDetails];
         }
      }
      if (content.type === DragAndDropItemTypes.Folder) {
         if (isValidFolder ? isValidFolder(content.item as IFolderTreeNode) : true) {
            folderList = [content.item as IFolderTreeNode];
         }
      }
      if (content.type === DragAndDropItemTypes.Mixed) {
         mediaList = (content.item as IContentBatchList).media ?? [];
         folderList = (content.item as IContentBatchList).folders ?? [];
      }

      const actionableContent = {
         media: mediaList.filter(media => isValidMedia ? isValidMedia(media) : true),
         folders: folderList.filter(folder => isValidFolder ? isValidFolder(folder) : true)
      };

      if (mediaList.length !== actionableContent.media.length || folderList.length !== actionableContent.folders.length) {
         setWarningModalInfo({
            showModal: true,
            actionableContent: actionableContent,
            clearBatchSelection: content.type === DragAndDropItemTypes.Mixed
         });
         return;
      }

      onDrop && await onDrop(actionableContent);

      if (content.type === DragAndDropItemTypes.Mixed) {
         clearBatchSelection();
      }
   };

   const [{canDrop, isDragging}, drop] = useDrop(
      () => ({
         accept: accept,
         drop: (item: IDraggableContent) => {
            void dropContent(item);
         },
         canDrop: (item: IDraggableContent) => canDropContent(item),
         collect: (monitor: DropTargetMonitor) => ({
            canDrop: !!monitor.canDrop(),
            isDragging: !!monitor.getItem()
         })
      }),
      []
   );

   const moveContentWarningModalConfirm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      onDrop && onDrop(warningModalInfo.actionableContent);
      if (warningModalInfo.clearBatchSelection) {
         clearBatchSelection();
      }
      setWarningModalInfo(defaultWarningModalState);
   };

   const moveContentWarningModalClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      e.stopPropagation();
      setWarningModalInfo(defaultWarningModalState);
   };

   return (<DropTargetContainer className="content-drop" data-test-id="content-drop-target" ref={drop} canDrop={canDrop} isDragging={isDragging}>
      {children}
      {warningModalInfo.showModal && <BatchContentWarningModal modalType={warningModalType} validContentCount={warningModalInfo.actionableContent.folders?.length + warningModalInfo.actionableContent.media?.length} onConfirm={moveContentWarningModalConfirm} onClose={moveContentWarningModalClose} />}
   </DropTargetContainer>);
};

export interface IDropTargetProps {
   children?: React.ReactNode;
   accept: string[];
   onDrop?(content: IContentBatchList): Promise<void>;
   isValidMedia?(media: IMediaDetails): boolean;
   isValidFolder?(folder: IFolderTreeNode): boolean;
   warningModalType?: BatchContentWarningModalType;
}

export default ContentDropTarget;
