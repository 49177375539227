import React from 'react';
import {Avatar, ControlButton, TscButtonType, Glyphs, TscThemeNames, SelectInput, Label} from '@techsmith/tsc-cloud-style-guide';
import {IMemberDetailsWithProfileImageModel} from '../../../model/appModel';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import withMemoizedContexts from '../../../context/contextContainerHoC';
import {themeStore} from '../../../context/themeProvider';
import {CollectionRoleTypes} from '../../../constants/Constants';
import cssConstants from '../../../constants/cssConstants';
import config from '../../../service/config';

export const Wrapper = styled.div`
   padding: 0.375rem;
   box-sizing: border-box;

   .member-role-label {
      font-style: italic;
      font-weight: normal;
      padding-right: .5rem;
   }
`;

const FlexContainer = styled.div`
   display: flex;
   flex-direction: column;
   flex: 1 1 auto;
   justify-content: center;
   overflow: hidden;
`;

const Text = styled.span`
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
`;

const PrimaryText = styled(Text)`
   font-size: 0.875rem;
   font-weight: 500;
`;

const RoleSelectInput = styled(SelectInput)`
   max-width: ${cssConstants.defaultButtonWidth};
`;

const GridMemberRow = styled.div<{changesDisabled: boolean}>`
   display: grid;
   grid-template-columns: ${props => props.changesDisabled ? '2rem auto 6rem' : '2rem auto 7rem 1rem'};
   grid-gap: .75rem;
`;

const RemoveButton = styled(ControlButton)`
   width: auto;
`;

export const CollectionMemberListItemBase: React.FC<ICollectionMemberListItemProps & IStateMappedProps> = ({member, disableChanges, onRoleChange, onRemoveClick, theme}) => {
   const {t} = useTranslation();

   const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const role: CollectionRoleTypes = e.target.value as CollectionRoleTypes;
      onRoleChange(member, role);
   };

   const roleOptions = [
      {label: CollectionRoleTypes.participant, value: CollectionRoleTypes.participant},
      {label: CollectionRoleTypes.contributor, value: CollectionRoleTypes.contributor}
   ];

   // Only allow promotion to manager role on enterprise environments. Of course display it on KPro for the logged in user since they are the collection manager. They won't be able to demote or remove themselves anyway.
   if (member.TechSmithId === config.user.TechSmithId || !config.featureSwitches.UseEntitlementAuthorization) {
      roleOptions.push({label: CollectionRoleTypes.manager, value: CollectionRoleTypes.manager});
   }

   const roleChangeDisabled = disableChanges || !onRoleChange;
   const removeDisabled = disableChanges || !onRemoveClick;
   const changesDisabled = removeDisabled || removeDisabled;

   return (
      <Wrapper>
         <GridMemberRow data-test-id={`collection-member-${member.TechSmithId}`} changesDisabled={changesDisabled}>
            <Avatar testId="collection-member-avatar" avatarImageUrl={member.profileImageUrl} userDisplayName={member.DisplayName} userAltText={member.DisplayName} userID={member.TechSmithId}/>
            <FlexContainer>
               <PrimaryText data-test-id={'member-display-name'} title={member.DisplayName}>{member.DisplayName}</PrimaryText>
            </FlexContainer>
            {roleChangeDisabled && <Label data-test-id={'member-role-label'} labelText={member.Role} className="member-role-label" />}
            {!roleChangeDisabled && <RoleSelectInput onChange={onSelectChange} value={member.Role} options={roleOptions} themeName={theme} testId={'member-role-select-input'}/>}
            {!removeDisabled && <RemoveButton
               buttonType={TscButtonType.floating}
               title={t('general.remove')}
               onClick={onRemoveClick}
               themeName={theme}
               glyph={<Glyphs.CircleRemove16x16/>}
               testId="member-remove-button"
            />}
         </GridMemberRow>
      </Wrapper>
   );
};

export interface ICollectionMemberListItemProps {
   member: IMemberDetailsWithProfileImageModel;
   disableChanges?: boolean;
   onRoleChange?(member: IMemberDetailsWithProfileImageModel, role: CollectionRoleTypes): void;
   onRemoveClick?(): void;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {member, disableChanges, onRoleChange, onRemoveClick}: ICollectionMemberListItemProps,
   {theme}: Partial<IStateMappedProps>
): IStateMappedProps & ICollectionMemberListItemProps => ({member, disableChanges, onRoleChange, onRemoveClick, theme});

export default withMemoizedContexts(mapStatesToProps, themeStore)(CollectionMemberListItemBase);
