import React, {MouseEvent, useState} from 'react';
import styled from 'styled-components';
import {ControlButton, TscButtonType, Glyphs, TscThemeName, TscStyles} from '@techsmith/tsc-cloud-style-guide';
import {useTranslation} from 'react-i18next';
import {DragPreviewImage, useDrag} from 'react-dnd';
import cssConstants from '../../constants/cssConstants';
import {MenuList} from '../util/StyledElements';
import {DragAndDropItemTypes} from '../../model/dragAndDropItemTypes';
import ContentDropTarget from '../content/ContentDropTarget';
import {IDraggableContent, IContentBatchList} from '../../model/draggableLibraryContent';
import {generateDragPreviewImage} from '../../util/DragPreviewImageGenerator';
import {IMediaDetails} from '../../model/mediaDetails';
import {IFolderTreeNode} from '../../model/folderTreeNode';
import {BatchContentWarningModalType} from '../modals/BatchContentWarningModal';

const ninetyDegrees = 90;
const accentColor = '#292F34';
// navbar currently does not change themes, when it does this will be dynamic
const navBarThemeGlyphColor = TscStyles.color.text.lightMedium;
const navBarThemeTextColor = TscStyles.color.text.light;

const MenuRowContainer = styled.li<{isTopLevel: boolean; highlightRow?: boolean}>`
   position: relative;
   display: flex;
   flex-direction: row;
   height: 40px;
   padding: 0 0.5rem;
   flex: none;
   font-size: .85rem;

   .content-drop, .content-drag {
      display: flex;
      flex: 1 1 auto;
      height: 100%;
      overflow: hidden;
   }

   // the spacer content (expand,context) is contained in spans while the 'main' content is contained in a div, so we can get away with this, if we ever
   // change them all to spans or divs for consistency, this becomes problematic. without adding classes i didnt find a better way to target this element
   // since the dom structure of the menu rows is very conditional
   > div {
      margin-left: -.25rem; // this 'adjustment' plus the corresponding comment shift the container over a bit so hover/active dont feel so close to edge
   }

   ${props => props.isTopLevel ? `
      border-bottom: 1px solid ${accentColor};
      border-top: 1px solid ${accentColor};

      > div > a, div > div > a, > div > button, > div > div > button {
         font-weight: 600;
         letter-spacing: 0.05em;
         text-transform: uppercase;
      }
   ` : `
      > div > a, div > div > a, > div > button, > div > div > button {
         font-weight: 400;
      }
   `}

   ${props => props.highlightRow ? `
      background: linear-gradient(to right, rgba(214, 124, 44, 0.175) 0, rgba(214, 124, 44, 0) 3rem);

      ::before {
         position: absolute;
         left: 0;
         width: 4px;
         height: 100%;
         background-color: ${cssConstants.layoutBorderAccentColor};
         content: '';
      }
      ` : ''
}
`;

const MenuRowSpacer = styled.span`
   display: flex;
   width: 1.5rem;
   height: 100%;
   flex: none;
   justify-content: center;
   align-items: center;
`;

// these are defined as direct descendants so they dont bleed down into the 'more' menus of the popover buttons (for instance),
// the consistent design of style guide can cause unintended effects when matching all descendants attributes
export const MenuRowButton = styled.div`
   > button, > div > button, > a, > div > a {
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      > span > svg {
         fill: ${navBarThemeGlyphColor};
      }
   }
`;

const MenuRowPrimaryButton = styled.div`
   display: flex;
   flex: auto;
   height: 100%;
   align-items: center;
   border-radius: 0;
   overflow: hidden;
   outline-offset: -2px;
   font-weight: 400;
   color: ${navBarThemeTextColor} !important; //Present clobbers these styles
   :hover {
      box-shadow: none;
      border-radius: ${TscStyles.border.radius.md}
   }

   > button, > a, > div > button, > div > a {
      display: flex;
      flex: 1 1 auto;
      height: 100%;
      justify-content: flex-start;
      align-items: center;
      gap: 0;
      padding: 0 0 0 .25rem; // this 'adjustment' plus the corresponding comment shift the container over a bit so hover/active dont feel so close to edge

      > span {
         padding: 0 .25rem;

         > svg {
            fill: ${navBarThemeGlyphColor};
         }
      }
   }
`;

const MenuRowAccordionButton = styled(MenuRowButton)<{isExpanded: boolean}>`
   button {
      svg {
         width: 20px;
         height: 20px;
         transform: rotate(${props => props.isExpanded ? ninetyDegrees : 0}deg);
         transition: transform 0.2s ease-out;
      }
   }
`;

const MenuRow: React.FC<IMenuRowProps> = ({subMenu, accordionDefinition, dragInfo, fragmentKey, label, link, onClick, glyph, nestingLevel, dropInfo, controlButton, highlightRow}: IMenuRowProps) => {
   // these are the defaults when expansionDefinition is not defined at all
   let isExpandable = subMenu?.length > 0;
   let initialState = false;
   let showPlaceholder = true;

   if (accordionDefinition) {
      if (accordionDefinition.noPlaceholder) {
         showPlaceholder = false;
         isExpandable = false;
         initialState = true;
      } else if (accordionDefinition.isAlwaysExpanded) {
         isExpandable = false;
         initialState = true;
      } else {
         initialState = accordionDefinition.isInitiallyExpanded ?? false;
      }
   }

   const {t} = useTranslation();
   const [isExpanded, setIsExpanded] = useState(initialState);

   // even if dragInfo is not defined, we must create the hook, it should never be used so a bunch of null values in config should be ok
   // eslint-disable-next-line no-empty-pattern
   const [{}, drag, preview] = useDrag(
      () => ({
         type: dragInfo?.type ?? 'unknown',
         item: {
            type: dragInfo?.type ?? 'unknown',
            item: dragInfo?.item
         } as IDraggableContent
      }),
      [dragInfo]
   );

   const accordionControl = isExpandable ?
      (<MenuRowAccordionButton isExpanded={isExpanded}>
         <ControlButton
            buttonType={TscButtonType.tertiary}
            glyph={<Glyphs.ArrowRight20x20 />}
            themeName={TscThemeName.dusk}
            onClick={() => setIsExpanded(!isExpanded)}
            title={t('ToggleMenu')}
            ariaExpanded={isExpanded}
            testId={`${fragmentKey}.accordionControl`}
         />
      </MenuRowAccordionButton>) : ''
   ;

   /* eslint-disable no-undefined */
   const primaryControlButton = dragInfo ? (<>
      <DragPreviewImage connect={preview} src={generateDragPreviewImage(dragInfo?.type)} />
      <MenuRowPrimaryButton className={'content-drag'} ref={drag}>
         <ControlButton
            label={label}
            href={link ?? undefined}
            onClick={onClick ?? undefined}
            glyph={glyph ?? undefined}
            buttonType={TscButtonType.tertiary}
            themeName={TscThemeName.dusk}
            testId={fragmentKey}
         />
      </MenuRowPrimaryButton>
   </>) : (
      <MenuRowPrimaryButton>
         <ControlButton
            label={label}
            href={link ?? undefined}
            onClick={onClick ?? undefined}
            glyph={glyph ?? undefined}
            buttonType={TscButtonType.tertiary}
            themeName={TscThemeName.dusk}
            testId={fragmentKey}
         />
      </MenuRowPrimaryButton>
   );
   /* eslint-enable no-undefined */

   return (<>
      <MenuRowContainer title={label} key={fragmentKey} isTopLevel={nestingLevel === 0} highlightRow={!!highlightRow}>
         {Array.from({length: Math.max(nestingLevel - 1, 0)}, (_, i) => <MenuRowSpacer key={`${fragmentKey}.spacer${i}`} />)}
         {showPlaceholder && <MenuRowSpacer>
            {accordionControl}
         </MenuRowSpacer>}
         {dropInfo ?
            <ContentDropTarget accept={[DragAndDropItemTypes.Media, DragAndDropItemTypes.Folder, DragAndDropItemTypes.Mixed]} onDrop={dropInfo.onDrop} isValidMedia={dropInfo.isValidMedia} isValidFolder={dropInfo.isValidFolder} warningModalType={dropInfo.warningModalType}>
               {primaryControlButton}
            </ContentDropTarget> :
            primaryControlButton
         }
         {!!controlButton && <MenuRowSpacer key={`${fragmentKey}.spacerRightButton`}>
            {controlButton}
         </MenuRowSpacer>}
      </MenuRowContainer>
      {!!subMenu && isExpanded && <MenuList>
         {subMenu}
      </MenuList>}
   </>);
   /* eslint-enable no-undefined */
};

export interface IMenuRowAccordionProps {
   noPlaceholder?: boolean;
   isAlwaysExpanded?: boolean;
   isInitiallyExpanded?: boolean;
}

export interface IMenuRowDropTargetProps {
   onDrop?(content: IContentBatchList): Promise<void>;
   isValidMedia?(media: IMediaDetails): boolean;
   isValidFolder?(folder: IFolderTreeNode): boolean;
   warningModalType: BatchContentWarningModalType;
}

export interface IMenuRowProps {
   fragmentKey: string;
   nestingLevel: number;
   label: string;
   link?: string;
   onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
   glyph?: React.JSX.Element;
   highlightRow?: boolean;
   accordionDefinition?: IMenuRowAccordionProps;
   controlButton?: React.JSX.Element;
   subMenu?: React.JSX.Element[];
   dragInfo?: IDraggableContent;
   dropInfo?: IMenuRowDropTargetProps;
}

export default MenuRow;
