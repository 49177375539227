import React from 'react';
import {useTranslation} from 'react-i18next';
import {MenuItem} from '@techsmith/tsc-cloud-style-guide';
import config from '../../service/config';

export interface ICreateMediaMenuProps {
   setDisplayYoutubeModal(showModal: boolean): void;
   setDisplayConnectMobileModal(showModal: boolean): void;
}

const CreateMediaMenu: React.FC<ICreateMediaMenuProps> = ({setDisplayConnectMobileModal, setDisplayYoutubeModal}) => {
   const {t} = useTranslation();

   return (<>
      {config.IsYouTubeEnabled && <MenuItem key={`${t('sidebar.importYoutube')}-button`} label={t('sidebar.importYoutube')} onClick={() => setDisplayYoutubeModal(true)} />}
      {!config.featureSwitches.DisableMobileConnect && <MenuItem key={`${t('sidebar.connectMobileDevice')}-button`} label={t('sidebar.connectMobileDevice')} onClick={() => setDisplayConnectMobileModal(true)} />}
   </>);
};

export default CreateMediaMenu;
