import React, {createContext, ReactElement, useState} from 'react';
import {IFolderTreeNode} from '../model/folderTreeNode';
import {IMediaDetails} from '../model/mediaDetails';

interface IBatchSelectionState {
   selectedFolders: IFolderTreeNode[];
   selectedMedia: IMediaDetails[];
}

const initialState: IBatchSelectionState = {selectedFolders: [], selectedMedia: []};

const store = createContext(initialState);
const Provider = store.Provider;

let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<IBatchSelectionState>> = () => null;
const publicUpdateState = (newState: Partial<IBatchSelectionState>) => privateUpdateState(Object.assign({}, privateState, newState));

const clearBatchSelection = () => {
   publicUpdateState(initialState);
};

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<IBatchSelectionState>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useBatchSelectionProvider,
   store as batchSelectionStore,
   publicUpdateState as updateBatchSelectionState,
   clearBatchSelection
};
