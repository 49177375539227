import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {ControlButton, TscButtonType, TscThemeNames, TscStyles} from '@techsmith/tsc-cloud-style-guide';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';

export const TrialStatus = styled.div`
   align-self: center;
   flex-shrink: 0;
   padding: 0 0.75rem;
`;

const BuyNowButtonContainer = styled.div`
   margin-right: 0.5rem;

   a,
   a:active,
   a:hover,
   a:focus,
   a:visited {
      color: ${TscStyles.color.text.light} !important; /* Present clobbers this style with site-wide anchor styling */
   }

`;

export const TrialDetailsBase: React.FC<ITrialDetailsProps & IStateMappedProps> = ({numberOfDaysLeft, purchaseLink, theme}) => {
   const {t} = useTranslation();

   const daysRemainingText = t('purchase.daysRemaining', {count: numberOfDaysLeft});
   return (
      <>
         <TrialStatus className="t-trial-status">{daysRemainingText}</TrialStatus>
         {purchaseLink && (
            <BuyNowButtonContainer>
               <ControlButton
                  themeName={theme}
                  buttonType={TscButtonType.cta}
                  testId="edit-media-original"
                  label={t('purchase.buyNow')}
                  href={purchaseLink}
               />
            </BuyNowButtonContainer>
         )}
      </>
   );
};

export interface ITrialDetailsProps {
   numberOfDaysLeft: number;
   purchaseLink?: string;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {numberOfDaysLeft, purchaseLink}: ITrialDetailsProps,
   {theme}: Partial<IStateMappedProps>
): IStateMappedProps & ITrialDetailsProps => ({numberOfDaysLeft, purchaseLink, theme});

export default withMemoizedContexts(mapStatesToProps, themeStore)(TrialDetailsBase);
