import React from 'react';
import styled from 'styled-components';
import {TscStyles, TscThemeName, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';

export const HorizontalSeparatorContainer = styled.span<{theme: TscThemeNames}>`
   margin-left: .5rem;
   margin-right: .5rem;
   display: inline-block;
   color: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.light : TscStyles.color.ui.dusk.dark};
`;

export const HorizontalSeparatorBase: React.FC<IStateMappedProps> = ({theme}) => (
   <HorizontalSeparatorContainer theme={theme}>|</HorizontalSeparatorContainer>
);

export interface IStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   // eslint-disable-next-line no-empty-pattern
   {},
   {theme}: Partial<IStateMappedProps>
): IStateMappedProps => ({theme});

export default withMemoizedContexts(mapStatesToProps, themeStore)(HorizontalSeparatorBase);
