import React from 'react';
import styled from 'styled-components';
import {Spinner} from '@techsmith/tsc-cloud-style-guide';

export const AlertModalSpinnerWrapper = styled.div`
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   align-items: center;
   display: flex;
   opacity: 0.8;
   background: white;
   border-bottom-right-radius: 8px;
   border-bottom-left-radius: 8px;
   overflow: hidden;
`;

const AlertModalSpinner: React.FC<unknown> = () => (
   <AlertModalSpinnerWrapper><Spinner/></AlertModalSpinnerWrapper>
);

export default AlertModalSpinner;
