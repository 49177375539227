import React from 'react';
import {useTranslation} from 'react-i18next';
import {
   ControlButton,
   TscButtonType,
   Glyphs,
   SelectInput,
   TscThemeNames
} from '@techsmith/tsc-cloud-style-guide';
import cssConstants from '../../constants/cssConstants';
import {MinWidthButtonWrapper, StyledWarning} from '../util/StyledElements';
import '../../static/css/modal.less';
import {IFolderTreeNode} from '../../model/folderTreeNode';
import {IWorkerState} from '../../model/workerState';
import folderApi from '../../service/folderApi';
import config from '../../service/config';
import styled from 'styled-components';
import {BasicModalPortal} from '../util/ModalPortalHelper';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';
import {IMediaDetails} from '../../model/mediaDetails';
import {foldersStore} from '../../context/foldersProvider';

const ErrorContainer = styled.div`
   margin-bottom: 1rem;
`;

const FooterButtonsWrapper = styled.div<{useMinWidthButtons: boolean}>`
   display: flex;
   justify-content: space-between;
   .button-group {
      margin-left: auto;
   }

   button {
      min-width: ${props => props.useMinWidthButtons ? cssConstants.defaultButtonWidth : ''};
   }
`;

export const MoveModalBase: React.FC<IMoveModalProps & IStateMappedProps> = ({onClose, onMove, onCreateFolder, selectedMedia, selectedFolders, destinationFolderHash, theme, folderTree}) => {
   const {t} = useTranslation();
   const [modalState, setModalState] = React.useState<IMoveModalState>({
      destinationFolder: destinationFolderHash,
      isWorking: false,
      hasError: false
   });

   React.useEffect(() => {
      setModalState({...modalState, destinationFolder: destinationFolderHash});
   }, [destinationFolderHash]);

   // because media-filters we can no longer assume all batch content will come from the same directory, so we have to
   // consider all media and just fallback to 'move wherever' if they are not all from the same top-level directory

   const selectedContentParents = (selectedMedia.concat(selectedFolders as any[]) as (IMediaDetails | IFolderTreeNode)[]).map(s => s.ParentFolder);
   // eslint-disable-next-line no-undefined
   const parentFolder = selectedContentParents.length !== 0 && selectedContentParents.every((m, _, all) => m?.Hash === all[0]?.Hash) ? selectedContentParents[0] ?? null : undefined;
   const isSubfolderCreationAllowed = !parentFolder || !parentFolder.Hash; // this is indicator that identified parent folder is not 2-levels deep

   const folderOptions = [
      {label: t('folders.move.selectPrompt'), value: ''},
      {label: t('details.rootFolderName'), value: 'root', disabled: parentFolder === null} // null indicates root folder, undefined indicates mix-match selection
   ];

   folderTree?.SubFolders.forEach(f => {
      folderOptions.push({
         label: f.Name,
         value: f.Hash,
         disabled: selectedFolders.some(fo => f.Hash === fo.Hash) || f.Hash === parentFolder?.Hash
      });

      f.SubFolders.forEach(subfolder => folderOptions.push({
         label: ` - ${subfolder.Name}`,
         value: subfolder.Hash,
         disabled: selectedFolders.length > 0 || subfolder.Hash === parentFolder?.Hash
      }));
   });

   const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setModalState({...modalState, destinationFolder: e.target.value});
   };

   const onSubmit = async () => {
      setModalState({...modalState, isWorking: true, hasError: false});
      try {
         const mediaHashesToMove = selectedMedia.map(m => m.Hash);
         const folderHashesToMove = selectedFolders.map(f => f.Hash);
         await folderApi.updateParentFolder(mediaHashesToMove, folderHashesToMove, modalState.destinationFolder, config.libraryConfig?.profileTechSmithId ?? config.user.TechSmithId);

         onMove();

         onClose();
      } catch (e) {
         setModalState({...modalState, isWorking: false, hasError: true});
      }
   };

   const showSubfolderError = selectedFolders.some(f => f.SubFolders.length > 0);

   const onCreateFolderClick = () => {
      onCreateFolder && onCreateFolder();
   };

   const modalTitle = selectedMedia?.length > 0 && selectedFolders?.length > 0 ? t('folders.move.modalContentTitle') :
      selectedFolders?.length > 0 ? t('folders.move.modalFolderTitle') : t('folders.move.modalMediaTitle');

   return (
      <BasicModalPortal
         testId="move-modal"
         isLoading={modalState.isWorking}
         visible={true}
         title={showSubfolderError ? t('folders.move.cannotMoveTitle') : modalTitle}
         width={cssConstants.defaultModalWidth}
         onClose={() => onClose()}
         themeName={theme}
      >
         <div className="modal-body">
            <div className="form-body themeable-section">
               {modalState.hasError && <StyledWarning>{t('general.errorText')}</StyledWarning>}
               {showSubfolderError ? (
                  <>
                     <ErrorContainer>{t('folders.move.cannotMoveDescription')}</ErrorContainer>
                     <div className="button-group">
                        <MinWidthButtonWrapper>
                           <ControlButton
                              label={t('general.ok')}
                              buttonType={TscButtonType.secondary}
                              themeName={theme}
                              onClick={() => onClose()}
                           />
                        </MinWidthButtonWrapper>
                     </div>
                  </>
               ) : (
                  <>
                     <div className="form-field-group">
                        <SelectInput label=" " className={'t-destination'} onChange={onSelectChange} value={modalState.destinationFolder} options={folderOptions} themeName={theme} isFullWidth={true} />
                     </div>
                     <FooterButtonsWrapper useMinWidthButtons={!onCreateFolder}>
                        {isSubfolderCreationAllowed && onCreateFolder && <ControlButton
                           label={t('folders.create.new')}
                           buttonType={TscButtonType.tertiary}
                           glyph={<Glyphs.Add16x16 />}
                           themeName={theme}
                           onClick={onCreateFolderClick}
                           testId="create-folder-button"
                        />}
                        <div className="button-group">
                           <ControlButton
                              label={t('general.close')}
                              buttonType={TscButtonType.secondary}
                              themeName={theme}
                              onClick={() => onClose()}
                           />
                           <ControlButton
                              label={t('folders.move.moveButtonText')}
                              buttonType={TscButtonType.primary}
                              themeName={theme}
                              onClick={onSubmit}
                              disabled={!modalState.destinationFolder}
                              testId={'move-button'}
                           />
                        </div>
                     </FooterButtonsWrapper>
                  </>
               )}
            </div>
         </div>
      </BasicModalPortal>
   );
};

MoveModalBase.defaultProps = {
   selectedMedia: [],
   selectedFolders: [],
   destinationFolderHash: ''
};

export interface IMoveModalProps {
   onClose(): void;
   onMove(): void;
   onCreateFolder?: () => void;
   selectedMedia?: IMediaDetails[];
   selectedFolders?: IFolderTreeNode[];
   destinationFolderHash?: string;
}

export interface IMoveModalState extends IWorkerState {
   destinationFolder: string;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
   folderTree: IFolderTreeNode;
}

const mapStatesToProps = (
   {onClose, onMove, onCreateFolder, selectedMedia, selectedFolders, destinationFolderHash}: IMoveModalProps,
   {theme}: Partial<IStateMappedProps>,
   {folderTree}: Partial<IStateMappedProps>
): IStateMappedProps & IMoveModalProps => ({onClose, onMove, onCreateFolder, selectedMedia, selectedFolders, destinationFolderHash, theme, folderTree});

export default withMemoizedContexts(mapStatesToProps, themeStore, foldersStore)(MoveModalBase);
