import {AxiosResponse} from 'axios';
import ApiUtil from '../util/ApiUtil';
import FileDownloader from '../util/FileDownloader';
import Constants, {UserTypes} from '../constants/Constants';
import {IConversationData} from '../model/conversationModel';
import {IComment} from '../model/commentModel';
import {ICommentRequest} from '../model/commentRequestModel';
import {ICommentResponse} from '../model/commentResponseModel';
import {IEmojiReactionData} from '../model/emojiReactionsModel';
import {IFullCommentResponse} from '../model/fullCommentResponseModel';
import {IReaction, ILike} from '../model/reactionModel';
import {IReplyRequest} from '../model/replyRequestModel';
import {IServerUserReponse} from '../model/serverUserResponseModel';
import {sortCommentsByTime} from '../util/CommentSorter';
import RequestAuthUtil from '../util/RequestAuthUtil';
import config from './config';
import profileApi from './profileApi';
import {IUserProfileModel} from '../model/appModel';
import {t} from 'i18next';

const conversationBaseUrl = '/api/v1/conversation';
const commentsBaseUrl = '/api/v1/conversation/comments';

interface IConversationSubscriptionResponse {
   conversationId: string;
   enabled: boolean|null;
}

const createConversation = async (hash: string, title: string) => {
   const response: AxiosResponse = await ApiUtil.executePostAsync(`${conversationBaseUrl}`, [Constants.statusCodes.created], {Hash: hash, Title: title});
   return getConversationSummary(hash, response.data.ConversationHash);
};

const getConversations = async (hash: string): Promise<IConversationData[]> => {
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(`${conversationBaseUrl}/${hash}`, [Constants.statusCodes.ok]);
   const conversations: IConversationData[] = response.data.Conversations;
   const conversationsWithSummaryData: IConversationData[] = [];

   await Promise.all(conversations.map(async (conversation: IConversationData, index: number) => {
      conversationsWithSummaryData[index] = await getConversationSummary(hash, conversation.ConversationHash);
   }));

   return conversationsWithSummaryData;
};

const getConversationSummary = async (mediaHash: string, conversationHash: string): Promise<IConversationData> => {
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(`${conversationBaseUrl}/${mediaHash}/${conversationHash}/summary`, [Constants.statusCodes.ok]);

   return response.data.Conversation as IConversationData;
};

const downloadMediaConversationData = async (mediaHash: string) => {
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(`${conversationBaseUrl}/${mediaHash}/csv`, [Constants.statusCodes.ok, Constants.statusCodes.noContent], null, {responseType: 'arraybuffer'});

   if (response.status === Constants.statusCodes.ok) {
      // Match just the filename out of the header and strip it of any invalid filename characters
      const filename = response.headers['content-disposition'].match(/filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/)[2].replace(/[<>:*/\\|?"]+/g, '');

      response.data && FileDownloader.download(filename, response.data, 'application/zip');
   }
};

const downloadSingleConversationData = async (mediaHash: string, conversationHash: string) => {
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(`${conversationBaseUrl}/${mediaHash}/${conversationHash}/csv`, [Constants.statusCodes.ok]);

   if (response.status === Constants.statusCodes.ok) {
      // Prepending a byte order mark to ensure Excel opens the csv correctly
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const responseContents = !response.data.Contents || response.data.Contents.startsWith('\ufeff') ? response.data.Contents : `\ufeff${response.data.Contents}`;

      response.data && FileDownloader.download(response.data.FileName, responseContents, 'application/zip');
   }
};

const renameConversation = async (mediaHash: string, conversationHash: string, newTitle: string): Promise<IConversationData> => {
   const response: AxiosResponse = await ApiUtil.executePutAsync(`${conversationBaseUrl}/${mediaHash}/${conversationHash}`, [Constants.statusCodes.ok], {Title: newTitle});
   const updatedConversation = response.data as IConversationData;
   return await getConversationSummary(mediaHash, updatedConversation.ConversationHash);
};

const clearMediaConversationComments = async (mediaHash: string) => {
   await ApiUtil.executeDeleteAsync(`${conversationBaseUrl}/${mediaHash}/comments`, [Constants.statusCodes.noContent]);
};

const deleteConversationComments = async (mediaHash: string, conversationHash: string): Promise<IConversationData> => {
   await ApiUtil.executeDeleteAsync(`${conversationBaseUrl}/${mediaHash}/${conversationHash}/comments`, [Constants.statusCodes.noContent]);
   return await getConversationSummary(mediaHash, conversationHash);
};

const deleteConversation = async (mediaHash: string, conversationHash: string) => {
   await ApiUtil.executeDeleteAsync(`${conversationBaseUrl}/${mediaHash}/${conversationHash}`, [Constants.statusCodes.noContent]);
};

const ensureUserDisplayNamePopulated = async (contributors: IServerUserReponse[]): Promise<IServerUserReponse[]> => await Promise.all(contributors.map(async contributor => {
   if (!contributor.DisplayName && contributor.UserType === UserTypes.techSmith) {
      let userInfo = {} as IUserProfileModel;
      try {
         userInfo = await profileApi.getUserProfileInfo(contributor.Id);
      } catch (error) {
         userInfo.displayName = t('NotFound');
      }

      return Object.assign({}, contributor, {DisplayName: userInfo.displayName});
   }

   return contributor;
}));

const getFormattedLikes = (reactions: IReaction[], contributors: IServerUserReponse[]): ILike[] => reactions.map((reaction: IReaction) => (
   {
      UserId: reaction.UserId,
      DisplayName: contributors.find(contributor => contributor.Id === reaction.UserId)?.DisplayName
   } as ILike
));

const getFormattedComment = (comment: ICommentResponse, timeInMs: number, reviewId: string, isResolved: boolean, resolutionDate: string, parentCommentId: string, contributors: IServerUserReponse[], replies: ICommentResponse[] = []): IComment => {
   const {Reactions, AuthorId, Text, Id, CreateDate, LastModifiedDate, IsDeleted, Annotation, SynchTimeStamp} = comment;

   return {
      authorId: AuthorId,
      text: Text,
      id: Id,
      createDate: CreateDate,
      lastModifiedDate: LastModifiedDate,
      time: timeInMs / 1000,
      reviewId: reviewId,
      isDeleted: IsDeleted,
      likes: getFormattedLikes(Reactions.filter((reaction: IReaction) => reaction.TypeName === Constants.reactionTypes.like), contributors),
      annotation: Annotation,
      isResolved: isResolved,
      resolutionDate: resolutionDate,
      synchTimeStamp: SynchTimeStamp,
      parentCommentId: parentCommentId,
      user: contributors.find(contributor => contributor.Id === AuthorId),
      replies: replies && replies.map(reply => getFormattedComment(reply, timeInMs, reviewId, isResolved, resolutionDate, comment.Id, contributors, []))
   };
};

const filterDeletedComment = (comments: IComment[]) => {
   comments.forEach(c => {
      c.replies = c.replies?.filter(cr => !cr.isDeleted);
   });
   return comments.filter(c => !c.isDeleted || !!c.replies?.filter(cr => !cr.isDeleted).length);
};

const getConversationComments = async (conversationId: string): Promise<IComment[]> => {
   const response: AxiosResponse = await RequestAuthUtil.executeRequest(ApiUtil.executeGetWithoutCacheAsync, `${conversationBaseUrl}/${conversationId}/comments`, [Constants.statusCodes.ok]);
   const comments: IComment[] = [];
   const contributors = response.data.Contributors ? await ensureUserDisplayNamePopulated(response.data.Contributors) : [];

   response.data.Comments?.forEach((commentResponse: IFullCommentResponse) => {
      const {Comment, Time, ReviewId, IsResolved, ResolutionDate, Replies} = commentResponse;
      comments.push(getFormattedComment(Comment, Time, ReviewId, IsResolved, ResolutionDate, null, contributors, Replies));
   });

   const filteredComments = filterDeletedComment(comments);

   return sortCommentsByTime(filteredComments);
};

const addComment = async (reviewId: string, comment: ICommentRequest): Promise<IComment> => {
   // any is used here because type ICreateCommentResponse (or whatever proper name may be?) doesnt seem to exist
   // const response = await RequestAuthUtil.executeRequest<any>(ApiUtil.executePostAsync, `${config.environmentData.VideoReviewApiUrl}/${reviewBaseUrl}/${reviewId}/comments`, [Constants.statusCodes.created], comment);
   const response = await RequestAuthUtil.executeRequest<any>(ApiUtil.executePostAsync, `${conversationBaseUrl}/${reviewId}/comments`, [Constants.statusCodes.created], comment);

   return getFormattedComment(response.data.Comment, response.data.Time, response.data.ReviewId, response.data.IsResolved, response.data.ResolutionDate, null, [], []);
};

const addReply = async (reply: IReplyRequest, parentComment: IComment): Promise<IComment> => {
   const response = await RequestAuthUtil.executeRequest<ICommentResponse>(ApiUtil.executePostAsync, `${commentsBaseUrl}/${parentComment.id}/replies`, [Constants.statusCodes.created], reply);

   return getFormattedComment(response.data, parentComment.time * 1000, parentComment.reviewId, false, parentComment.resolutionDate, parentComment.id, [], []);
};

const likeComment = async (comment: IComment, userId: string): Promise<IComment> => {
   await RequestAuthUtil.executeRequest(ApiUtil.executePostAsync, `${commentsBaseUrl}/${comment.id}/like`, [Constants.statusCodes.created]);
   const updatedComment = Object.assign({}, comment);
   updatedComment.likes.push({UserId: userId, DisplayName: config.user.DisplayName} as ILike);

   return updatedComment;
};

const unlikeComment = async (comment: IComment, userId: string): Promise<IComment> => {
   await RequestAuthUtil.executeRequest(ApiUtil.executeDeleteAsync, `${commentsBaseUrl}/${comment.id}/like`, [Constants.statusCodes.noContent]);
   const updatedComment = Object.assign({}, comment);
   updatedComment.likes = updatedComment.likes.filter((like: ILike) => like.UserId !== userId);

   return updatedComment;
};

const editComment = async (comment: IComment): Promise<IComment> => {
   const response = await RequestAuthUtil.executeRequest<ICommentResponse>(ApiUtil.executePutAsync, `${commentsBaseUrl}/${comment.id}`, [Constants.statusCodes.ok], {text: comment.text, annotation: comment.annotation});

   return Object.assign({}, comment, {lastModifiedDate: response.data.LastModifiedDate});
};

const deleteComment = async (comment: IComment): Promise<IComment> => {
   await RequestAuthUtil.executeRequest(ApiUtil.executeDeleteAsync, `${commentsBaseUrl}/${comment.id}`, [Constants.statusCodes.noContent]);

   return Object.assign({}, comment, {text: '', annotation: '', isDeleted: true, isResolved: false});
};

const getFormattedReaction = (reactionResponse: IEmojiReactionData, initialDisplay: boolean): IEmojiReactionData => {
   reactionResponse.CreateDate = new Date(reactionResponse.CreateDate);
   reactionResponse.Time = reactionResponse.Time / 1000;
   reactionResponse.InitialDisplay = initialDisplay;
   return reactionResponse;
};

const createReaction = async (conversationId: string, emojiId: string, emojiSkin: number = 0, time: number = 0): Promise<IEmojiReactionData> => {
   const response = await RequestAuthUtil.executeRequest<any>(ApiUtil.executePostAsync, `${conversationBaseUrl}/${conversationId}/reactions`, [Constants.statusCodes.created], {EmojiId: emojiId, EmojiSkin: emojiSkin, Time: time});
   return getFormattedReaction(response.data as IEmojiReactionData, true);
};

const getReactions = async (conversationId: string): Promise<IEmojiReactionData[]> => {
   const response: AxiosResponse = await ApiUtil.executeGetAsync(`${conversationBaseUrl}/${conversationId}/reactions`, [Constants.statusCodes.ok]);
   return (response.data as IEmojiReactionData[]).map(reaction => getFormattedReaction(reaction, false)).sort((a, b) => a.CreateDate.getTime() - b.CreateDate.getTime());
};

const deleteReaction = async (conversationId: string, reactionId: string): Promise<void> => {
   await RequestAuthUtil.executeRequest(ApiUtil.executeDeleteAsync, `${conversationBaseUrl}/${conversationId}/reactions/${reactionId}`, [Constants.statusCodes.noContent]);
};

const isSubscribedToConversation = async (conversationId: string): Promise<boolean|null> => (await RequestAuthUtil.executeRequest<IConversationSubscriptionResponse>(ApiUtil.executeGetWithoutCacheAsync, `${conversationBaseUrl}/notifications/${conversationId}/subscribed`, [Constants.statusCodes.ok])).data.enabled;

const subscribeToConversation = async (conversationId: string): Promise<void> => {
   await RequestAuthUtil.executeRequest(ApiUtil.executePutAsync, `${conversationBaseUrl}/notifications/${conversationId}/subscribe`, [Constants.statusCodes.noContent]);
};

const unsubscribeFromConversation = async (conversationId: string): Promise<void> => {
   await RequestAuthUtil.executeRequest(ApiUtil.executePutAsync, `${conversationBaseUrl}/notifications/${conversationId}/unsubscribe`, [Constants.statusCodes.noContent]);
};

export default {
   createConversation,
   getConversations,
   downloadMediaConversationData,
   downloadSingleConversationData,
   clearMediaConversationComments,
   renameConversation,
   deleteConversationComments,
   deleteConversation,
   getConversationComments,
   addComment,
   addReply,
   likeComment,
   unlikeComment,
   editComment,
   deleteComment,
   createReaction,
   getReactions,
   deleteReaction,
   isSubscribedToConversation,
   subscribeToConversation,
   unsubscribeFromConversation
};
