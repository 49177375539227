import React, {createContext, ReactElement, useState} from 'react';
import {IWorkerState} from '../model/workerState';
import {IUserLibrary} from '../model/library';
import {IMediaDetails} from '../model/mediaDetails';

interface ILibraryProvider extends IWorkerState, IUserLibrary {
   libraryMedia: IMediaDetails[];
   cursor?: string;
   lastLoadedMediaHash?: string;
}

const initialState: ILibraryProvider = {
   isWorking: true,
   hasError: false,
   libraryMedia: [],
   cursor: null,
   lastLoadedMediaHash: null
};

const store = createContext<ILibraryProvider>(initialState);
let privateState = initialState;
let privateUpdateState: React.Dispatch<React.SetStateAction<ILibraryProvider>> = () => null;
const publicUpdateState = (newState: Partial<ILibraryProvider>) => privateUpdateState(Object.assign({}, privateState, newState));
const Provider = store.Provider;

const addLibraryMedia = (media: IMediaDetails[] | IMediaDetails) => {
   const toAdd: IMediaDetails[] = Array.isArray(media) ? media : [media];
   publicUpdateState({libraryMedia: toAdd.concat(privateState.libraryMedia)});
};

const removeLibraryMedia = (media: IMediaDetails[] | IMediaDetails) => {
   const toRemove: IMediaDetails[] = Array.isArray(media) ? media : [media];
   publicUpdateState({libraryMedia: privateState.libraryMedia.filter(m => !toRemove.some(s => s.Hash === m.Hash))});
};

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<ILibraryProvider>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useLibraryProvider,
   store as libraryStore,
   publicUpdateState as updateLibraryState,
   addLibraryMedia,
   removeLibraryMedia
};
