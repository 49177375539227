import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ControlButton, TscButtonType, TextInput, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import {MinWidthButtonWrapper, StyledWarning} from '../util/StyledElements';
import '../../static/css/modal.less';
import cssConstants from '../../constants/cssConstants';
import {IWorkerState} from '../../model/workerState';
import videoApi from '../../service/videoApi';
import styled from 'styled-components';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';
import {addLibraryMedia} from '../../context/libraryProvider';
import Constants from '../../constants/Constants';
import collectionApi from '../../service/collectionApi';
import {collectionsStore, updateActiveCollection} from '../../context/collectionsProvider';
import {ICollectionModel} from '../../model/appModel';
import {collectionMediaStore, updateCollectionMediaState} from '../../context/collectionMediaProvider';
import {IMediaDetails} from '../../model/mediaDetails';
import {IFolderTreeNode} from '../../model/folderTreeNode';
import {foldersStore} from '../../context/foldersProvider';
import {isWellKnownFolder} from '../../constants/wellKnownFolder';
import {BasicModalPortal} from '../util/ModalPortalHelper';

export const isValidYoutubeLink = (link: string): boolean => !!/^(?:https?:\/\/)?(?:(?:(?:www\.)?youtube\.com\/watch\?v=)|(?:youtu\.be\/))[\w\d-_]+/i.exec(link);

const ButtonGroup = styled.div`
   margin-top: 2rem;
`;

export const AddYoutubeMediaModalBase: React.FC<IAddYoutubeMediaModalProps & IStateMappedProps> = ({onSuccess, onClose, collectionId, theme, activeCollection, collectionMedia, activeFolder}) => {
   const {t} = useTranslation();
   const [state, setState] = useState({isWorking: false, hasError: false} as IWorkerState);
   const [youtubeLink, setYoutubeLink] = useState({value: '', errorText: ''});

   const containingFolder = isWellKnownFolder(activeFolder?.Hash) || !activeFolder?.Hash ? Constants.defaultUploadFolderHash : activeFolder?.Hash;

   const updateLink = (evt: React.ChangeEvent<HTMLInputElement>): void => {
      const newLink = evt.target.value.trim();
      const isValid = isValidYoutubeLink(newLink);

      setYoutubeLink({
         ...youtubeLink,
         errorText: !isValid ? t('uploadMedia.youtube.malformedLink') : '',
         value: newLink
      });
   };

   const importYoutubeVideo = async () => {
      setState({isWorking: true, hasError: false});
      try {
         const newMediaDetails = await videoApi.importYoutubeVideo(youtubeLink.value, containingFolder);
         addLibraryMedia(newMediaDetails);
         if (collectionId) {
            await collectionApi.addToCollection(collectionId, newMediaDetails.Hash);
            if (activeCollection && activeCollection.Id === collectionId) {
               activeCollection.MediaCount++;
               await updateActiveCollection({...activeCollection, MediaCount: activeCollection.MediaCount++});
            }
            collectionMedia.push(newMediaDetails);
            updateCollectionMediaState({collectionMedia: [...collectionMedia, newMediaDetails]});
         }
         setState({isWorking: false, hasError: false});
         onSuccess?.();
         onClose();
      } catch {
         setState({isWorking: false, hasError: true});
      }
   };

   return (
      <BasicModalPortal
         visible={true}
         title={t('uploadMedia.youtube.import')}
         width={cssConstants.defaultModalWidth}
         onClose={state.isWorking ? (): void => null : onClose}
         themeName={theme}
         isLoading={state.isWorking}
      >
         <div className="modal-body">
            <div className="form-body themeable-section">
               <div className="form-field-group">
                  {state.hasError && <StyledWarning>{t('general.errorText')}</StyledWarning>}
                  <label>
                     {t('uploadMedia.youtube.link')}
                     <TextInput
                        placeholder={t('uploadMedia.youtube.placeholder')}
                        value={youtubeLink.value}
                        errorText={youtubeLink.errorText}
                        onChange={updateLink}
                        themeName={theme}
                        ariaLabel={t('uploadMedia.youtube.link')}
                        readOnly={false}
                        testId="import-youtube-link-input"
                        disabled={state.isWorking}
                     />
                  </label>
               </div>
               <ButtonGroup className="button-group">
                  <MinWidthButtonWrapper>
                     <ControlButton
                        label={t('general.cancel')}
                        buttonType={TscButtonType.secondary}
                        disabled={state.isWorking}
                        themeName={theme}
                        onClick={state.isWorking ? (): void => null : onClose}
                        testId="import-youtube-cancel-button"
                     />
                  </MinWidthButtonWrapper>
                  <MinWidthButtonWrapper>
                     <ControlButton
                        label={t('general.import')}
                        buttonType={TscButtonType.primary}
                        disabled={!youtubeLink.value || !!youtubeLink.errorText}
                        themeName={theme}
                        onClick={importYoutubeVideo}
                        testId="import-youtube-submit-button"
                     />
                  </MinWidthButtonWrapper>
               </ButtonGroup>
            </div>
         </div>
      </BasicModalPortal>
   );
};

export interface IAddYoutubeMediaModalProps {
   onSuccess?(): void;
   onClose(): void;
   collectionId?: string;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
   activeCollection: ICollectionModel;
   collectionMedia: IMediaDetails[];
   activeFolder: IFolderTreeNode;
}

const mapStatesToProps = (
   {onSuccess, onClose, collectionId}: IAddYoutubeMediaModalProps,
   {theme}: Partial<IStateMappedProps>,
   {activeCollection}: Partial<IStateMappedProps>,
   {collectionMedia}: Partial<IStateMappedProps>,
   {activeFolder}: Partial<IStateMappedProps>
): IStateMappedProps & IAddYoutubeMediaModalProps => ({onSuccess, onClose, collectionId, theme, activeCollection, collectionMedia, activeFolder});

export default withMemoizedContexts(mapStatesToProps, themeStore, collectionsStore, collectionMediaStore, foldersStore)(AddYoutubeMediaModalBase);
