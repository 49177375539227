export const importMediaInterface = () => import(/* webpackChunkName: "smartplayer" */ '@techsmith/techsmith-smartplayer').then(s => s.mediaInterface);

export const importNotificationsClientPlugin = () => import(/* webpackChunkName: "notificationsclient" */ '@techsmith/notifications-client').then(module => ({default: module.NotificationsClientPlugin}));

export const importAppInsightsWeb = () => import(/* webpackChunkName: "appinsights" */ '@microsoft/applicationinsights-web');

export const importAppInsightsReact = () => import(/* webpackChunkName: "appinsights" */ '@microsoft/applicationinsights-react-js');

export const importReactMarkdown = () => import(/* webpackChunkName: "markdown" */ 'react-markdown');

export const importRehypeRaw = () => import(/* webpackChunkName: "markdown" */ 'rehype-raw');