import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {ControlButton, TscButtonType, Glyphs, TextInput, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import Constants, {CollectionPrivacyTypes} from '../../constants/Constants';
import cssConstants from '../../constants/cssConstants';
import collectionApi from '../../service/collectionApi';
import {StyledWarning, MinWidthButtonWrapper} from '../util/StyledElements';
import '../../static/css/modal.less';
import PrivacyOption from '../util/PrivacyOption';
import {IWorkerState} from '../../model/workerState';
import config from '../../service/config';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';
import {BasicModalPortal} from '../util/ModalPortalHelper';
import {IMinimalCollection} from '../../model/minimalCollection';
import {addCollectionToState} from '../../context/collectionsProvider';
import {mediaStore, updateMediaStateFromServer} from '../../context/mediaProvider';
import logging from '../../service/logging';

const InputLabel = styled.label`
   display: block;
   margin-bottom: 0.5rem;
   font-size: .8rem;
   font-weight: 600;
`;

const SectionHeader = styled.span`
   display: block;
   margin-bottom: 0.5rem;
   font-size: .8rem;
   font-weight: 600;
`;

export const CreateCollectionModalBase: React.FC<ICreateCollectionModalProps & IStateMappedProps> = ({onClose, shareMediaToCollection, theme, Hash}) => {
   const {t} = useTranslation();
   const [state, setState] = React.useState({
      privacySetting: config.featureSwitches.HideEnterprisePrivacyOptions ? CollectionPrivacyTypes.public : CollectionPrivacyTypes.link,
      name: {value: '', errorText: ''},
      isWorking: false,
      hasError: false
   } as ICreateCollectionModalState);

   const onCloseClick = () => {
      onClose();
   };

   const updateCollectionName = (evt: React.ChangeEvent<HTMLInputElement>): void => {
      const newCollectionName = evt.target.value;
      const isEmpty = !newCollectionName.trim();
      const isTooLong = newCollectionName.length > Constants.maxCollectionNameLength;
      const errorText = isEmpty ? t('collections.create.nameEmptyError') : isTooLong ? t('collections.create.nameTooLong', {maxLength: Constants.maxCollectionNameLength}) : '';

      setState({...state,
         name: {
            value: newCollectionName,
            errorText: errorText
         }
      });
   };

   const onCreateClick = async () => {
      const newCollectionName = state.name.value.trim();

      setState({...state, isWorking: true, hasError: false});

      try {
         const newCollection = await collectionApi.createCollection(newCollectionName, state.privacySetting);
         addCollectionToState(newCollection);

         if (shareMediaToCollection) {
            await collectionApi.addToCollection(newCollection.Id, Hash);
            updateMediaStateFromServer();
         }

         onClose({Id: newCollection.Id, Name: newCollectionName});
      } catch (e) {
         setState({...state, isWorking: false, hasError: true});
         logging.error('Failed to create and share media with the new collection: ', e);
      }
   };

   const collectionNameInput: React.JSX.Element = (
      <div className="form-field-group">
         <InputLabel>
            {t('collections.create.name')}
         </InputLabel>
         <TextInput
            placeholder={t('collections.create.placeholder', {0: Constants.maxCollectionNameLength})}
            value={state.name.value}
            errorText={state.name.errorText}
            onChange={updateCollectionName}
            themeName={theme}
            ariaLabel="Collection Name"
            readOnly={false}
            testId="collection-name-input"
         />
      </div>
   );

   const onPublicSelect = () => {
      setState({...state, privacySetting: CollectionPrivacyTypes.public});
   };

   const onOrganizationSelect = () => {
      setState({...state, privacySetting: CollectionPrivacyTypes.organization});
   };

   const onLinkSelect = () => {
      setState({...state, privacySetting: CollectionPrivacyTypes.link});
   };

   const onInviteSelect = () => {
      setState({...state, privacySetting: CollectionPrivacyTypes.invite});
   };

   const privacyExtras = {
      organizationName: config.organizationName,
      applicationShortName: config.applicationShortName
   };

   const publicDescription = config.featureSwitches.HideEnterprisePrivacyOptions ?
      t('collections.privacy.public.singleuser.description') :
      t('collections.privacy.public.enterprise.description', privacyExtras);

   const publicTitle = config.featureSwitches.HideEnterprisePrivacyOptions ?
      t('collections.privacy.public.singleuser.title') :
      t('collections.privacy.public.enterprise.title');

   const privateDescription = config.featureSwitches.HideEnterprisePrivacyOptions ?
      t('collections.privacy.private.description') :
      t('collections.privacy.invite.description');

   const privateTitle = config.featureSwitches.HideEnterprisePrivacyOptions ?
      t('collections.privacy.private.title') :
      t('collections.privacy.invite.title');

   const privacyLevelSelection: React.JSX.Element = (
      <div className="form-field-listing">
         <SectionHeader>
            {t('collections.privacy.level')}
         </SectionHeader>
         <PrivacyOption
            onClick={onPublicSelect}
            selected={state.privacySetting === CollectionPrivacyTypes.public}
            header={publicTitle}
            text={publicDescription}
            glyph={<Glyphs.PrivacyLink16x16 />}
            radioTestId="public-collection-privacy-radio"
            theme={theme}
         />
         {!config.featureSwitches.HideEnterprisePrivacyOptions && <PrivacyOption
            onClick={onOrganizationSelect}
            selected={state.privacySetting === CollectionPrivacyTypes.organization}
            header={t('collections.privacy.open.title', privacyExtras)}
            text={t('collections.privacy.open.description', privacyExtras)}
            glyph={<Glyphs.PrivacyOrgAll16x16 />}
            radioTestId="organization-collection-privacy-radio"
            theme={theme}
         />}
         {!config.featureSwitches.HideEnterprisePrivacyOptions && <PrivacyOption
            onClick={onLinkSelect}
            selected={state.privacySetting === CollectionPrivacyTypes.link}
            header={t('collections.privacy.link.title', privacyExtras)}
            text={t('collections.privacy.link.description', privacyExtras)}
            glyph={<Glyphs.PrivacyOrgLink16x16 />}
            radioTestId="link-collection-privacy-radio"
            theme={theme}
         />}
         <PrivacyOption
            onClick={onInviteSelect}
            selected={state.privacySetting === CollectionPrivacyTypes.invite}
            header={privateTitle}
            text={privateDescription}
            glyph={<Glyphs.Lock16x16 />}
            radioTestId="invite-collection-privacy-radio"
            theme={theme}
         />
      </div>
   );

   const buttonCollection: React.JSX.Element = (
      <div className="button-group">
         <MinWidthButtonWrapper>
            <ControlButton
               label={t('general.cancel')}
               buttonType={TscButtonType.secondary}
               disabled={false}
               themeName={theme}
               onClick={onCloseClick}
               testId="cancel-button"
            />
            <ControlButton
               label={t('collections.create.label')}
               buttonType={TscButtonType.primary}
               disabled={Boolean(state.name.errorText || !state.name.value)}
               themeName={theme}
               onClick={onCreateClick}
               testId="create-button"
            />
         </MinWidthButtonWrapper>
      </div>
   );

   return (
      <BasicModalPortal
         testId="create-collection-modal"
         visible={true}
         title={t('collections.create.instructions')}
         width={cssConstants.defaultModalWidth}
         onClose={onCloseClick}
         themeName={theme}
         isLoading={state.isWorking}
      >
         <div className="modal-body">
            <div className="form-body themeable-section">
               {state.hasError && <StyledWarning>{t('general.errorText')}</StyledWarning>}
               {collectionNameInput}
               {privacyLevelSelection}
               {buttonCollection}
            </div>
         </div>
      </BasicModalPortal>
   );
};

export interface ICreateCollectionModalProps {
   onClose(newCollection?: IMinimalCollection): void;
   shareMediaToCollection?: boolean;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
   Hash: string;
}

export interface ICreateCollectionModalState extends IWorkerState {
   privacySetting: CollectionPrivacyTypes;
   name: {value: string; errorText: string};
}

const mapStatesToProps = (
   {shareMediaToCollection, onClose}: ICreateCollectionModalProps,
   {theme}: Partial<IStateMappedProps>,
   {Hash}: Partial<IStateMappedProps>
): ICreateCollectionModalProps & IStateMappedProps => ({shareMediaToCollection, onClose, theme, Hash});

export default withMemoizedContexts(mapStatesToProps, themeStore, mediaStore)(CreateCollectionModalBase);
