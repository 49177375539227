import axios, {AxiosPromise, AxiosRequestConfig, AxiosResponse, isAxiosError, isCancel} from 'axios';
import config from '../service/config';
import Constants from '../constants/Constants';
import logging from '../service/logging';

const apiGetPromiseCache: {[key: string]: AxiosPromise} = {};

const executeCallAsync = async<T>(axiosFunc: (url: string, data?: object, config?: AxiosRequestConfig) => AxiosPromise<T>, apiUrl: string, data?: object, requestConfig?: AxiosRequestConfig) => {
   let url: string;

   if (config.environmentData.IsRunningLocally) {
      apiUrl = apiUrl.includes('api') ? apiUrl.slice(apiUrl.indexOf('api') - 1) : apiUrl.slice(apiUrl.indexOf('connector') - 1);
      url = Constants.localDevServerUrl + apiUrl;
   } else {
      url = apiUrl;
   }

   if (axiosFunc === axios.delete) { // Axios delete only supports 2 params, url and config. You cannot set the data directly, but can with config: {data: 'stuff'}
      requestConfig = Object.assign({}, requestConfig, {data});
      return await axiosFunc(url, requestConfig);
   }

   return await axiosFunc(url, data, requestConfig);
};

const executeGetAsync = async <T>(apiUrl: string, acceptedStatusCodes: number[], getData?: object, requestConfig?: AxiosRequestConfig) => {
   if (!(apiUrl in apiGetPromiseCache)) {
      apiGetPromiseCache[apiUrl] = executeGetWithoutCacheAsync(apiUrl, acceptedStatusCodes, getData, requestConfig);
   }
   return apiGetPromiseCache[apiUrl] as AxiosPromise<T>;
};

const executeGetWithoutCacheAsync = async <T>(apiUrl: string, acceptedStatusCodes: number[], getData?: object, requestConfig?: AxiosRequestConfig) => {
   const get = (u: string, d?: object, c?: AxiosRequestConfig) => axios.get(u, c);
   return await executeRequestWithErrorHandling<T>(get, apiUrl, acceptedStatusCodes, getData, requestConfig);
};

const executePostAsync = async <T>(apiUrl: string, acceptedStatusCodes: number[], postData?: object, requestConfig?: AxiosRequestConfig) => await executeRequestWithErrorHandling<T>(axios.post, apiUrl, acceptedStatusCodes, postData, requestConfig);

const executePutAsync = async <T>(apiUrl: string, acceptedStatusCodes: number[], putData?: object, requestConfig?: AxiosRequestConfig) => await executeRequestWithErrorHandling<T>(axios.put, apiUrl, acceptedStatusCodes, putData, requestConfig);

const executePatchAsync = async <T>(apiUrl: string, acceptedStatusCodes: number[], patchData?: object, requestConfig?: AxiosRequestConfig) => await executeRequestWithErrorHandling<T>(axios.patch, apiUrl, acceptedStatusCodes, patchData, requestConfig);

const executeDeleteAsync = async <T>(apiUrl: string, acceptedStatusCodes: number[], deleteData?: object, requestConfig?: AxiosRequestConfig) => await executeRequestWithErrorHandling<T>(axios.delete, apiUrl, acceptedStatusCodes, deleteData, requestConfig);

const executeRequestWithErrorHandling = async<T>(axiosFunc: (url: string, data?: object, config?: AxiosRequestConfig) => AxiosPromise<T>, apiUrl: string, acceptedStatusCodes: number[], data?: object, requestConfig?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
   let response: AxiosResponse<T>;
   try {
      response = await executeCallAsync<T>(axiosFunc, apiUrl, data, requestConfig);
   } catch (error) {
      if (isCancel(error)) {
         throw error;
      }
      
      if (isAxiosError(error)) {
         logging.warn(`API call ${error.config.method} ${error.config.url} failed with status code ${error.response?.status}`, error);
         // eslint-disable-next-line no-throw-literal
         throw {status: error.response?.status ?? 0, message: error.message, reason: error.response?.data?.Reason, response: error.response};
      }

      logging.error('Unexpected error occurred when executing API method', error);
      throw error;
   }

   if (!acceptedStatusCodes.includes(response.status)) {
      const failureMessage = `API call ${response.config.method} to ${response.config.url} returned unexpected status code ${response.status}`;
      const failureMessageWithDetails = (response.data as any)?.detail ? `${failureMessage}: ${(response.data as any).detail as string}` : failureMessage;
      logging.warn(failureMessageWithDetails);
      // eslint-disable-next-line no-throw-literal
      throw {status: response.status, message: failureMessageWithDetails, response: response};
   }

   return response;
};

export default {
   executeGetAsync,
   executeGetWithoutCacheAsync,
   executePostAsync,
   executePutAsync,
   executePatchAsync,
   executeDeleteAsync
};
