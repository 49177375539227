import React, {createContext, ReactElement, useState} from 'react';
import {IMediaDetails} from '../model/mediaDetails';

interface ICollectionMediaProvider {
   collectionMedia: IMediaDetails[];
   collectionMediaCanLoadMore: boolean;
   collectionMediaCursor?: string;
}

const initialState: ICollectionMediaProvider = {
   collectionMedia: [],
   collectionMediaCanLoadMore: false,
   collectionMediaCursor: null
};

const store = createContext<ICollectionMediaProvider>(initialState);
let privateState = initialState;
let privateUpdateState: React.Dispatch<React.SetStateAction<ICollectionMediaProvider>> = () => null;
const publicUpdateState = (newState: Partial<ICollectionMediaProvider>) => privateUpdateState(Object.assign({}, privateState, newState));
const Provider = store.Provider;

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<ICollectionMediaProvider>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

const removeMediaFromCollection = (media: IMediaDetails[]) => {
   const toRemove: IMediaDetails[] = Array.isArray(media) ? media : [media];
   publicUpdateState({collectionMedia: privateState.collectionMedia.filter(m => !toRemove.some(s => s.Hash === m.Hash))});
};

export {
   useProvider as useCollectionMediaProvider,
   store as collectionMediaStore,
   publicUpdateState as updateCollectionMediaState,
   removeMediaFromCollection
};
