import React, {createContext, ReactElement, useState} from 'react';
import {SortMetric} from '../constants/Constants';

const sortMetricStorageKey = 'sortMetric';
const getSortMetric = () => {
   try {
      const localStorageSortMetric = window.localStorage.getItem(sortMetricStorageKey);
      return localStorageSortMetric ? localStorageSortMetric as SortMetric : SortMetric.dateDescending;
   } catch {
      // Local storage not supported
   }
   return SortMetric.dateDescending;
};

interface ISortMetricProvider {
   sortMetric: SortMetric;
}

const initialState: ISortMetricProvider = {
   sortMetric: getSortMetric()
};

const store = createContext<ISortMetricProvider>(initialState);
let privateState = initialState;
let privateUpdateState: React.Dispatch<React.SetStateAction<ISortMetricProvider>> = () => null;
const publicUpdateState = (newState: Partial<ISortMetricProvider>) => privateUpdateState(Object.assign({}, privateState, newState));
const Provider = store.Provider;

export const setSortMetric = (sortMetric: SortMetric) => {
   publicUpdateState({sortMetric: sortMetric});
   try {
      window.localStorage.setItem(sortMetricStorageKey, sortMetric);
   } catch {
      // Local storage not supported
   }
};

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<ISortMetricProvider>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useSortMetricProvider,
   store as sortMetricStore,
   publicUpdateState as updateSortMetricState
};
