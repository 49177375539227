const BuildQuerySetString = (queryKey: string, queryValue: string|string[]) => {
   const returnValue = '';
   if (Array.isArray(queryValue)) {
      return queryValue.reduce((prev, curr) => prev ? `${prev}&${queryKey}=${curr}` : `${queryKey}=${curr}`, returnValue);
   }
   return `${queryKey}=${queryValue}`;
};

const UpsertQueryParamInUrl = (url: string, queryKey: string, queryValue: string|string[]): string => {
   const paramIndex = url.indexOf('?');

   if (paramIndex === -1) {
      return `${url}?${BuildQuerySetString(queryKey, queryValue)}`;
   }

   if (url.indexOf(`${queryKey}=`) === -1) {
      return `${url}&${BuildQuerySetString(queryKey, queryValue)}`;
   }

   let returnUrl = `${url.substring(0, paramIndex)}?`;
   const params = url.substring(paramIndex + 1, url.length).split('&');
   for (let i = 0; i < params.length; i++) {
      const param = params[i];

      if (i > 0) {
         returnUrl += '&';
      }

      returnUrl += !param.startsWith(queryKey) ? param : BuildQuerySetString(queryKey, queryValue);
   }

   return returnUrl;
};

export {
   UpsertQueryParamInUrl
};
