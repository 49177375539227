import React from 'react';
import {useTranslation} from 'react-i18next';
import DeleteModal from './DeleteModal';
import {ICollectionModel} from '../../model/appModel';
import collectionApi from '../../service/collectionApi';
import {removeCollectionFromState} from '../../context/collectionsProvider';
import logging from '../../service/logging';

export const LeaveCollectionModalBase: React.FC<ILeaveCollectionModalProps> = ({onClose, collection, onLeave}) => {
   const {t} = useTranslation();

   const onDeleteAsync = async () => {
      try {
         await collectionApi.leaveCollection(collection.Id);
         removeCollectionFromState(collection, false);
         onLeave();
         onClose();
      } catch (e) {
         logging.error(`Failed to leave collection ${collection.Id} with error: `, e);
      }
   };

   return (
      <DeleteModal
         title={t('collections.leave.title')}
         warning={t('collections.leave.warning', {collectionName: collection.Name})}
         onClose={onClose}
         onDelete={onDeleteAsync}
         deleteButtonText={t('collections.leave.confirmButtonText')}
      />
   );
};

export interface ILeaveCollectionModalProps {
   onClose(): void;
   collection: ICollectionModel;
   onLeave?(): void;
}

export default LeaveCollectionModalBase;
