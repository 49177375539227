import Constants, {CollectionPrivacyTypes, CollectionRoleTypes} from '../constants/Constants';
import ApiUtil from '../util/ApiUtil';
import {ICollectionModel, ICollectionNavigationResponse, ICollectionSummaryModel} from '../model/appModel';
import {AxiosRequestConfig, AxiosResponse, CancelTokenSource} from 'axios';
import {IMediaDetails} from '../model/mediaDetails';
import {UpsertQueryParamInUrl} from '../component/util/QueryParamsHelper';

export interface IGetCollectionMediaQueryParams {
   from: string;
   take: number;
   orderBy: string;
   descending: boolean;
}

interface ICollectionSubscriptionResponse {
   collectionId: string;
   enabled: boolean;
}

const collectionBaseUrl = '/api/v1/group';

const addToCollection = async (collectionId: string, mediaHash: string[] | string) => {
   try {
      let response;
      if (Array.isArray(mediaHash)) {
         response = await ApiUtil.executePostAsync(`${collectionBaseUrl}/${collectionId}/media`, [Constants.statusCodes.noContent], {Hashes: mediaHash});
      } else {
         response = await ApiUtil.executePutAsync(`${collectionBaseUrl}/${collectionId}/media/${mediaHash}`, [Constants.statusCodes.noContent]);
      }
      return response;
   } catch (e) {
      // A conflict status code means the media is already in the collection.  In that case, we treat it as success.
      if (e.status === Constants.statusCodes.conflict) {
         return e.response as AxiosResponse;
      }
      throw e;
   }
};

const removeFromCollection = async (collectionId: string, mediaHash: string) => await ApiUtil.executeDeleteAsync(`${collectionBaseUrl}/${collectionId}/media/${mediaHash}`, [Constants.statusCodes.noContent]);

const createCollection = async (collectionName: string, privacy: CollectionPrivacyTypes): Promise<ICollectionModel> => {
   const response = await ApiUtil.executePostAsync(`${collectionBaseUrl}`,
      [Constants.statusCodes.created], {
         groupName: collectionName,
         privacy: privacy
      }
   );

   const collection = response.data as ICollectionModel;
   collection.Role = CollectionRoleTypes.manager;
   collection.CanLeave = false;

   return collection;
};

const deleteCollection = async (collectionId: string) => await ApiUtil.executeDeleteAsync(`${collectionBaseUrl}/${collectionId}`, [Constants.statusCodes.noContent]);

const leaveCollection = async (collectionId: string) => await ApiUtil.executePostAsync(`${collectionBaseUrl}/${collectionId}/leave`, [Constants.statusCodes.noContent]);

const addMember = async (collectionId: string, email: string, role: CollectionRoleTypes): Promise<void> => {
   await ApiUtil.executePostAsync(`${collectionBaseUrl}/${collectionId}/members`, [Constants.statusCodes.noContent], {recipientEmail: email, userRole: role});
};

const removeMember = async (collectionId: string, techSmithId: string): Promise<void> => {
   await ApiUtil.executeDeleteAsync(`${collectionBaseUrl}/${collectionId}/members/${techSmithId}`, [Constants.statusCodes.noContent]);
};

const changeRole = async (collectionId: string, techSmithId: string, role: CollectionRoleTypes): Promise<void> => {
   await ApiUtil.executePutAsync(`${collectionBaseUrl}/${collectionId}/members/${techSmithId}`, [Constants.statusCodes.noContent], {newRole: role});
};

const joinCollection = async (collectionId: string): Promise<ICollectionModel> => {
   const response = await ApiUtil.executePostAsync(`${collectionBaseUrl}/${collectionId}/join`, [Constants.statusCodes.ok]);
   return response.data as ICollectionModel;
};

const renameCollection = async (collectionId: string, collectionName: string): Promise<void> => {
   await ApiUtil.executePatchAsync(
      `${collectionBaseUrl}/${collectionId}`,
      [Constants.statusCodes.noContent],
      {groupName: collectionName} as ICollectionPatchRequest);
};

const updatePrivacy = async (collectionId: string, privacy: CollectionPrivacyTypes): Promise<void> => {
   await ApiUtil.executePutAsync(
      `${collectionBaseUrl}/${collectionId}/privacy`,
      [Constants.statusCodes.noContent],
      {privacy: privacy} as ICollectionPatchRequest);
};

const updateDefaultRole = async (collectionId: string, role: CollectionRoleTypes): Promise<void> => {
   await ApiUtil.executePutAsync(
      `${collectionBaseUrl}/${collectionId}/defaultrole`,
      [Constants.statusCodes.noContent],
      {DefaultRole: role} as ICollectionPatchRequest);
};

const getCollectionSummaryById = async (collectionId: string): Promise<ICollectionSummaryModel> => {
   const response = await ApiUtil.executeGetAsync(`${collectionBaseUrl}/${collectionId}/summary`, [Constants.statusCodes.ok]);

   return response.data as ICollectionSummaryModel;
};

const getCollectionById = async (collectionId: string, withoutCache?: boolean): Promise<ICollectionModel> => {
   const response = withoutCache ? await ApiUtil.executeGetWithoutCacheAsync(`${collectionBaseUrl}/${collectionId}`, [Constants.statusCodes.ok]) :
      await ApiUtil.executeGetAsync(`${collectionBaseUrl}/${collectionId}`, [Constants.statusCodes.ok]);
   return response.data as ICollectionModel;
};

const getMedia = async (collectionId: string, queryParams?: Partial<IGetCollectionMediaQueryParams>, CancellationToken?: CancelTokenSource): Promise<IMediaDetails[]> => {
   let url = `${collectionBaseUrl}/${collectionId}/media`;

   if (queryParams) {
      for (const [param, value] of Object.entries(queryParams)) {
         url = UpsertQueryParamInUrl(url, param, value.toString());
      }
   }

   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(url, [Constants.statusCodes.ok], null, requestConfig);
   return response.data as IMediaDetails[];
};

const getCollectionNavigationData = async (collectionId: string, mediaHash: string, queryParams?: Partial<IGetCollectionMediaQueryParams>, CancellationToken?: CancelTokenSource): Promise<ICollectionNavigationResponse> => {
   let url = `${collectionBaseUrl}/${collectionId}/media/${mediaHash}`;
   if (queryParams) {
      for (const [param, value] of Object.entries(queryParams)) {
         url = UpsertQueryParamInUrl(url, param, value.toString());
      }
   }

   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(url, [Constants.statusCodes.ok], null, requestConfig);
   return response.data as ICollectionNavigationResponse;
};

const isSubscribedToCollection = async (collectionId: string): Promise<boolean|null> => (await ApiUtil.executeGetAsync<ICollectionSubscriptionResponse>(`${collectionBaseUrl}/notifications/${collectionId}/subscribed`, [Constants.statusCodes.ok])).data.enabled;

const subscribeToCollection = async (collectionId: string): Promise<void> => {
   await ApiUtil.executePutAsync(`${collectionBaseUrl}/notifications/${collectionId}/subscribe`, [Constants.statusCodes.noContent]);
};

const unsubscribeFromCollection = async (collectionId: string): Promise<void> => {
   await ApiUtil.executePutAsync(`${collectionBaseUrl}/notifications/${collectionId}/unsubscribe`, [Constants.statusCodes.noContent]);
};

interface ICollectionPatchRequest {
   groupName?: string;
   privacy?: string;
   DefaultRole?: string;
}

export default {
   addToCollection: addToCollection,
   removeFromCollection: removeFromCollection,
   addMember: addMember,
   removeMember: removeMember,
   changeRole: changeRole,
   createCollection: createCollection,
   getCollectionById: getCollectionById,
   getCollectionSummaryById: getCollectionSummaryById,
   deleteCollection: deleteCollection,
   leaveCollection: leaveCollection,
   joinCollection: joinCollection,
   renameCollection: renameCollection,
   getMedia: getMedia,
   getCollectionNavigationData: getCollectionNavigationData,
   updatePrivacy: updatePrivacy,
   updateDefaultRole: updateDefaultRole,
   isSubscribedToCollection: isSubscribedToCollection,
   subscribeToCollection: subscribeToCollection,
   unsubscribeFromCollection: unsubscribeFromCollection
};
