import {IMediaChapters, IMediaDetails, ITransferMediaDetails, MediaPrivacy} from '../model/mediaDetails';
import ApiUtil from '../util/ApiUtil';
import config from './config';
import Constants from '../constants/Constants';
import {AxiosResponse} from 'axios';
import extractFilenameFromContentDispositionHeader from '../util/ContentDispositionFilenameExtractor';
import FileDownloader from '../util/FileDownloader';
import {IAccessPhrase} from '../model/accessPhraseModel';
import {ISuggestedChapter, ISuggestedDescription, ISuggestedTitle} from '../model/mediaIntelligence';

const mediaBaseUrl = '/api/v4/media';
interface ISuggestedDescriptionResponse {
   suggestion: string;
}

interface ISuggestedTitleResponse {
   suggestion: string;
}

interface ISuggestedChaptersResponse {
   suggestion: string;
}

const incrementView = async (hash: string) => await ApiUtil.executePostAsync(`${mediaBaseUrl}/${hash}/incrementview`, [Constants.statusCodes.noContent]);

const updateTitle = async (hash: string, newTitle: string) => await ApiUtil.executePatchAsync(`${mediaBaseUrl}/${hash}/title`, [Constants.statusCodes.noContent], {title: newTitle});

const updateDescription = async (hash: string, newDescription: string) => await ApiUtil.executePatchAsync(`${mediaBaseUrl}/${hash}/description`, [Constants.statusCodes.noContent], {description: newDescription});

const updateDownloadEnabled = async (hash: string, downloadEnabled: boolean) => await ApiUtil.executePatchAsync(`${mediaBaseUrl}/${hash}/downloadEnabled`, [Constants.statusCodes.noContent], {downloadEnabled: downloadEnabled});

const updateAllowViewerToFastForwardVideo = async (hash: string, allowViewerToFastForwardVideo: boolean) => await ApiUtil.executePatchAsync(`${mediaBaseUrl}/${hash}/updateAllowViewerToFastForwardVideo`, [Constants.statusCodes.noContent], {allowViewerToFastForwardVideo: allowViewerToFastForwardVideo});

const updateThumbnail = async (hash: string, newThumbnailOffsetInSeconds: number) => await ApiUtil.executePatchAsync(`${mediaBaseUrl}/${hash}/thumbnail`, [Constants.statusCodes.noContent], {timeOffsetInSeconds: newThumbnailOffsetInSeconds});

const updatePrivacy = async (hash: string, newPrivacy: MediaPrivacy) => await ApiUtil.executePatchAsync(`${mediaBaseUrl}/${hash}/privacy`, [Constants.statusCodes.noContent], {privacy: newPrivacy});

const updateChapters = async (hash: string, newChapters: IMediaChapters) => await ApiUtil.executePatchAsync(`${mediaBaseUrl}/${hash}/chapters`, [Constants.statusCodes.noContent], newChapters);

const shouldTryToReachOutToTransferForMediaDetails = (mediaDetails: IMediaDetails): boolean => mediaDetails.EncodingProgress === null && mediaDetails.IsReady === false;

// This method is only here because of having to support the Classic Recorder.
const getMediaDetailsFromTransfer = async (hash: string, mediaDetails: IMediaDetails): Promise<IMediaDetails> => {
   let response;

   try {
      response = await ApiUtil.executeGetWithoutCacheAsync(`${config.environmentData.RelayServerRootUrl}/relay/api/v1/presentations/${hash}`, [Constants.statusCodes.ok]);
   } catch (errResponse) {
      if (errResponse.status && errResponse.status === Constants.statusCodes.notFound) {
         // eslint-disable-next-line no-undefined
         return undefined;
      }
   }

   const uploadingStatusCode = 5;
   const encodingStatusCode = 1;
   const transferMediaDetails = response.data as ITransferMediaDetails;
   const halfCompletionPercentage = 50;
   const uploadingProgress = (transferMediaDetails.Progress * 100) / 2;
   const encodingProgress = ((transferMediaDetails.Progress * 100) / 2) + halfCompletionPercentage;
   mediaDetails.EncodingProgress = transferMediaDetails.Status === uploadingStatusCode ? uploadingProgress : encodingProgress;

   if (transferMediaDetails.Progress === 1.0 && transferMediaDetails.Status === encodingStatusCode) {
      const isReadyResponse = await ApiUtil.executeGetWithoutCacheAsync(`${mediaBaseUrl}/${hash}/isready`, [Constants.statusCodes.ok]);
      mediaDetails.IsReady = isReadyResponse.data as boolean;
   }

   return mediaDetails;
};

const getMediaDetails = async (hash: string): Promise<IMediaDetails> => {
   const response = await ApiUtil.executeGetWithoutCacheAsync(`${mediaBaseUrl}/${hash}/details`, [Constants.statusCodes.ok]);
   const mediaDetails = response.data as IMediaDetails;

   let mediaDetailsFromTransfer: IMediaDetails;
   if (shouldTryToReachOutToTransferForMediaDetails(mediaDetails)) {
      mediaDetailsFromTransfer = await getMediaDetailsFromTransfer(hash, mediaDetails);
   }

   return mediaDetailsFromTransfer || mediaDetails;
};

const deleteMedia = async (hash: string) => await ApiUtil.executeDeleteAsync(`${mediaBaseUrl}/${hash}`, [Constants.statusCodes.noContent]);

const changeOwner = async (hash: string, email: string) => await ApiUtil.executePatchAsync(`${mediaBaseUrl}/${hash}/changeowner`, [Constants.statusCodes.noContent], {newOwnerEmail: email});

const downloadBatchMediaCsv = async (hashes: string[]) => {
   const response: AxiosResponse = await ApiUtil.executePostAsync(`${mediaBaseUrl}/csv`, [Constants.statusCodes.ok], {hashes: hashes}, {responseType: 'arraybuffer'});
   if (response.status === Constants.statusCodes.ok) {
      const filename = extractFilenameFromContentDispositionHeader(response);
      response.data && await FileDownloader.download(filename || (hashes.length === 1 ? `${hashes[0]}-results.zip` : 'batch-results.zip'), response.data, 'application/zip');
   }
};

const getMediaAccessPhrase = async (hash: string) => (await ApiUtil.executeGetWithoutCacheAsync<IAccessPhrase>(`${mediaBaseUrl}/${hash}/accessPhrase`, [Constants.statusCodes.ok])).data.accessPhrase;

const updateMediaAccessPhrase = async (hash: string, newAccessPhrase: string) => {
   const response = await ApiUtil.executePatchAsync<IAccessPhrase>(`${mediaBaseUrl}/${hash}/accessPhrase`, [Constants.statusCodes.ok], {accessPhrase: newAccessPhrase});
   return response.data.accessPhrase;
};

const clearMediaAccessPhrase = async (hash: string) => {
   await ApiUtil.executeDeleteAsync(`${mediaBaseUrl}/${hash}/accessPhrase`, [Constants.statusCodes.noContent]);
};

const getSuggestedChapters = async (hash: string, min: number, max: number): Promise<ISuggestedChapter[]> => {
   const response = await ApiUtil.executeGetWithoutCacheAsync<ISuggestedChaptersResponse>(`${mediaBaseUrl}/${hash}/suggest/chapters?min=${min}&max=${max}`, [Constants.statusCodes.ok, Constants.statusCodes.contentTooLarge]);
   return JSON.parse(response.data.suggestion) as ISuggestedChapter[];
};

const getSuggestedDescription = async (hash: string): Promise<ISuggestedDescription> => {
   const response = await ApiUtil.executeGetWithoutCacheAsync(`${mediaBaseUrl}/${hash}/suggest/description`, [Constants.statusCodes.ok, Constants.statusCodes.contentTooLarge]);
   const responseData = response.data as ISuggestedDescriptionResponse;
   return {
      suggestion: responseData?.suggestion
   } as ISuggestedDescription;
};

const getSuggestedTitle = async (hash: string): Promise<ISuggestedTitle> => {
   const response = await ApiUtil.executeGetWithoutCacheAsync(`${mediaBaseUrl}/${hash}/suggest/title`, [Constants.statusCodes.ok, Constants.statusCodes.contentTooLarge]);
   const responseData = response.data as ISuggestedTitleResponse;
   return {
      suggestion: responseData?.suggestion
   } as ISuggestedTitle;
};

export default {
   incrementView,
   updateTitle,
   updateDescription,
   updateDownloadEnabled,
   updateThumbnail,
   updatePrivacy,
   updateChapters,
   getMediaDetails,
   deleteMedia,
   updateAllowViewerToFastForwardVideo,
   changeOwner,
   downloadBatchMediaCsv,
   updateMediaAccessPhrase,
   clearMediaAccessPhrase,
   getMediaAccessPhrase,
   getSuggestedChapters,
   getSuggestedDescription,
   getSuggestedTitle
};
