import React, {createContext, ReactElement, useState} from 'react';

interface IWindowSizing {
   mediaDetailsReservedHeight: string;
   mediaDetailsIsExpanded: boolean;
}

const initialState = {
   mediaDetailsReservedHeight: '15rem',
   mediaDetailsIsExpanded: false
};

const store = createContext<IWindowSizing>(initialState);
const Provider = store.Provider;

let privateState = initialState;

let privateUpdateState: React.Dispatch<React.SetStateAction<IWindowSizing>> = () => null;
const publicUpdateState = (newState: Partial<IWindowSizing>) => privateUpdateState(Object.assign({}, privateState, newState));

const useProvider = (children: ReactElement) => {
   const [state, updateState] = useState<IWindowSizing>(initialState);

   privateUpdateState = updateState;
   privateState = state;

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useWindowSizingProvider,
   store as windowSizingStore,
   publicUpdateState as UpdateWindowSizingState
};