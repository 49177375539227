export enum EntitlementType {
   free = 'Free',
   pro = 'ScreencastPro',
   unlimited = 'ScreencastProUnlimited'
}

export interface IUserQuotasResponse {
   CurrentBandwidth: number;
   CurrentStorage: number;
   CurrentVideoCount: number;
   IsVideoCountAtLimit: boolean;
   IsVideoCountAtWarningLimit: boolean;
   QuotaEntitlementType: EntitlementType;
   TechSmithId: string;
   UserHasQuotaInformation: boolean;
   VideoCountLimit: number;
   VideoWarningLimit: number;
}

export interface IUserQuotas {
   QuotaEntitlementType: EntitlementType;
   CurrentBandwidth: number;
   CurrentStorage: number;
   CurrentVideoCount: number;
   VideoCountExceeded: boolean;
   VideoCountLimit: number;
   UserHasQuotaInformation: boolean;
}
