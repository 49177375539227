import {useEffect, useState} from 'react';

const useWindowIsAtOrBelowSize = (size: number) => {
   const [isAtOrBelowSize, setIsAtOrBelowSize] = useState(window.innerWidth <= size);

   useEffect(() => {
      const onWindowResize = () => {
         if (window.innerWidth <= size) {
            if (!isAtOrBelowSize) {
               setIsAtOrBelowSize(true);
            }
         } else if (isAtOrBelowSize) {
            setIsAtOrBelowSize(false);
         }
      };

      window.addEventListener('resize', onWindowResize);

      return () => window.removeEventListener('resize', onWindowResize);
   }, [isAtOrBelowSize, size]);

   return isAtOrBelowSize;
};

export default useWindowIsAtOrBelowSize;