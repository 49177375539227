// Derived from https://stackoverflow.com/questions/26501688/a-typescript-guid-class
export const newGuid = (): string => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
   // eslint-disable-next-line no-bitwise, no-magic-numbers
   const r = Math.random() * 16 | 0;
   // eslint-disable-next-line no-bitwise, no-magic-numbers
   // eslint-disable-next-line no-bitwise, no-magic-numbers, no-mixed-operators
   const v = c === 'x' ? r : r & 0x3 | 0x8;
   // eslint-disable-next-line no-magic-numbers
   return v.toString(16);
});
