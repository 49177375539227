import {ICollectionModel} from '../model/appModel';
import ApiUtil from '../util/ApiUtil';
import Constants from '../constants/Constants';
import {IMediaDetails} from '../model/mediaDetails';
import {UpsertQueryParamInUrl} from '../component/util/QueryParamsHelper';
import {CancelTokenSource, AxiosResponse, AxiosRequestConfig} from 'axios';
import {IUserQuotasResponse} from '../model/userQuotas';
import {IMigrationModel} from '../model/migrationModel';
import {MediaContentType} from '../model/baseMedia';
import {IUserConfiguration} from '../model/userConfiguration';
import {ICreatedUserResponse} from '../model/createdUserResponseModel';
import {INotificationServiceEventsResponse} from '@techsmith/notifications-client';
import {IUserNotificationPreferenceModel} from '../model/userNotificationPreferenceModel';

const userBaseUrl = '/api/v1/user';

export interface IGetMediaByTypeQueryParams {
   from: string;
   take: number;
   orderBy: string;
   descending: boolean;
   includeExcludedMedia: boolean;
   mediaContentFilter: MediaContentType[];
}

export interface IGetMediaQueryParams extends IGetMediaByTypeQueryParams {
   folderHash: string;
   includeExcludedMedia: boolean;
}

const dismissCustomerMessage = async (techSmithId: string) => await ApiUtil.executePutAsync(`${userBaseUrl}/${techSmithId}/dismissmessage`,
   [Constants.statusCodes.ok], {
      TechSmithId: techSmithId
   });

const getMedia = async (techSmithId: string, queryParams?: Partial<IGetMediaQueryParams>, CancellationToken?: CancelTokenSource): Promise<IMediaDetails[]> => {
   let url = `${userBaseUrl}/${techSmithId}/ownedmedia`;

   if (queryParams) {
      for (const [param, value] of Object.entries(queryParams)) {
         const queryValue = Array.isArray(value) ? value.map(v => v.toString()) : value.toString();
         url = UpsertQueryParamInUrl(url, param, queryValue);
      }
   }

   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(url, [Constants.statusCodes.ok], null, requestConfig);
   return response.data as IMediaDetails[];
};

const getMediaByType = async (techSmithId: string, queryParams?: Partial<IGetMediaByTypeQueryParams>, CancellationToken?: CancelTokenSource): Promise<IMediaDetails[]> => {
   let url = `${userBaseUrl}/${techSmithId}/ownedmediabytype`;

   if (queryParams) {
      for (const [param, value] of Object.entries(queryParams)) {
         const queryValue = Array.isArray(value) ? value.map(v => v.toString()) : value.toString();
         url = UpsertQueryParamInUrl(url, param, queryValue);
      }
   }

   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(url, [Constants.statusCodes.ok], null, requestConfig);
   return response.data as IMediaDetails[];
};

const deleteMedia = async (techSmithId: string) => {
   const deleteMediaUrl = `${userBaseUrl}/${techSmithId}/media`;
   const response = await ApiUtil.executeDeleteAsync(deleteMediaUrl, [Constants.statusCodes.ok]);
   return response.data;
};

const getCollections = async (techSmithId: string, from?: string, take?: number, includeMediaData: boolean = false): Promise<ICollectionModel[]> => {
   let getGroupsUrl = `${userBaseUrl}/${techSmithId}/groups`;
   if (includeMediaData) {
      getGroupsUrl = UpsertQueryParamInUrl(getGroupsUrl, 'includeMediaData', includeMediaData.toString());
   }
   if (from) {
      getGroupsUrl = UpsertQueryParamInUrl(getGroupsUrl, 'from', from);
   }
   if (take) {
      getGroupsUrl = UpsertQueryParamInUrl(getGroupsUrl, 'take', take.toString());
   }
   const response = await ApiUtil.executeGetWithoutCacheAsync(getGroupsUrl, [Constants.statusCodes.ok, Constants.statusCodes.noContent]);
   return response.data as ICollectionModel[];
};

const getQuotas = async (CancellationToken?: CancelTokenSource): Promise<IUserQuotasResponse> => {
   const url = `${userBaseUrl}/quotas`;
   const requestConfig: AxiosRequestConfig = CancellationToken ? {cancelToken: CancellationToken.token} : null;
   const response: AxiosResponse = await ApiUtil.executeGetWithoutCacheAsync(url, [Constants.statusCodes.ok], null, requestConfig);
   return response.data as IUserQuotasResponse;
};

const getMigrationStatus = async (techSmithId: string): Promise<IMigrationModel[]> => {
   const url = `${userBaseUrl}/${techSmithId}/migrationstatus`;
   const response = await ApiUtil.executeGetWithoutCacheAsync(url, [Constants.statusCodes.ok, Constants.statusCodes.notFound]);
   return response.data as IMigrationModel[];
};

const acknowledgeMigration = async (techSmithId: string, migration: IMigrationModel): Promise<IMigrationModel> => {
   const url = `${userBaseUrl}/${techSmithId}/migrationstatus/acknowledge`;
   const response = await ApiUtil.executePutAsync(url, [Constants.statusCodes.ok], {migrationName: migration.MigrationName, lastKnownMigrationState: migration.MigrationState});
   return response.data as IMigrationModel;
};

const upsertCustomLogoStorageFileId = async (storageFileId: string): Promise<IUserConfiguration> => {
   const url = `${userBaseUrl}/configuration/logo/${storageFileId}`;
   const response = await ApiUtil.executePutAsync(url, [Constants.statusCodes.ok]);
   return response.data as IUserConfiguration;
};

const removeCustomLogoStorageFileId = async (): Promise<void> => {
   const url = `${userBaseUrl}/configuration/logo`;
   await ApiUtil.executeDeleteAsync(url, [Constants.statusCodes.ok]);
};

const createSelfIdentifiedUser = async (displayName: string): Promise<ICreatedUserResponse> => {
   const response = await ApiUtil.executePostAsync(`${userBaseUrl}/selfIdentified`, [Constants.statusCodes.created], {DisplayName: displayName});
   return response.data as ICreatedUserResponse;
};

const getNotifications = async (cursor?: string, limit?: number): Promise<INotificationServiceEventsResponse> => {
   const queryString = `${cursor ? `&cursor=${cursor}` : ''}${limit ? `&limit=${limit}` : ''}`;
   const response = await ApiUtil.executeGetWithoutCacheAsync<INotificationServiceEventsResponse>(`${userBaseUrl}/notifications?cacheBuster=${(+new Date()).toFixed(0)}${queryString}`, [Constants.statusCodes.ok]);
   return response.data;
};

const getNotificationsCount = async (): Promise<number> => {
   const response = await ApiUtil.executeGetWithoutCacheAsync<number>(`${userBaseUrl}/notifications/count?cacheBuster=${(+new Date()).toFixed(0)}`, [Constants.statusCodes.ok]);
   return response.data;
};

const markAllNotificationsRead = async (): Promise<void> => {
   await ApiUtil.executePutAsync(`${userBaseUrl}/notifications/read`, [Constants.statusCodes.noContent]);
};

const markNotificationRead = async (notificationId: string): Promise<void> => {
   await ApiUtil.executePutAsync(`${userBaseUrl}/notifications/read/${notificationId}`, [Constants.statusCodes.noContent]);
};

const configureProductBanner = async (enabled: boolean): Promise<void> => {
   await ApiUtil.executePutAsync(`${userBaseUrl}/configuration/productbanner/${enabled ? 'enable' : 'disable'}`, [Constants.statusCodes.noContent]);
};

const configureUserAutoCaptions = async (enabled: boolean): Promise<void> => {
   await ApiUtil.executePutAsync(`${userBaseUrl}/configuration/userautocaptions/${enabled ? 'enable' : 'disable'}`, [Constants.statusCodes.noContent]);
};

const configureNewUploadConversations = async (enabled: boolean): Promise<void> => {
   await ApiUtil.executePutAsync(`${userBaseUrl}/configuration/newuploadconversations/${enabled ? 'enable' : 'disable'}`, [Constants.statusCodes.noContent]);
};

const getNotificationPreferences = async (): Promise<IUserNotificationPreferenceModel[]> => (await ApiUtil.executeGetWithoutCacheAsync<{ eventType: string; enabled: boolean; deliveryType: string }[]>(`${userBaseUrl}/notifications/preferences`, [Constants.statusCodes.ok])).data;

const manageUserNotificationPreference = async (eventType: string, enabled: boolean): Promise<void> => {
   await ApiUtil.executePutAsync(`${userBaseUrl}/notifications/${eventType}/${enabled ? 'enable' : 'disable'}`, [Constants.statusCodes.noContent]);
};

export default {
   dismissCustomerMessage,
   getMedia,
   getMediaByType,
   getCollections,
   deleteMedia,
   getQuotas,
   getMigrationStatus,
   acknowledgeMigration,
   upsertCustomLogoStorageFileId,
   removeCustomLogoStorageFileId,
   createSelfIdentifiedUser,
   getNotifications,
   getNotificationsCount,
   markAllNotificationsRead,
   markNotificationRead,
   configureProductBanner,
   configureUserAutoCaptions,
   configureNewUploadConversations,
   getNotificationPreferences,
   manageUserNotificationPreference
};
