// note:  most util functions in this file will do checking to assure the var it is operating on is not
// null or undefined before doing the actual string helper operation, therefore these are not going to
// be designed as extensions to the built-in string class but instead are static class operations that
// can operate on either a string or a null/undefined var type

import Constants from '../constants/Constants';

// eslint-disable-next-line eqeqeq
const isNullOrWhiteSpace = (input: string) => input == null || (typeof input === 'string' && /^\s*$/.exec(input) !== null);

const sanitizeAccessPhraseSubmit = (value: string) => {
   // all ws combination in input changed to a single space, note we also 'trim()' the value we actually submit
   // to server; so leading and trailing ws will not be submitted to handle accidental newlines and non-printables
   // eslint-disable-next-line no-control-regex
   value = value.replace(/[\x00-\x1F]+/g, '');
   value = value.replace(/\s+/g, ' ');
   value = value.trim();
   return value;
};

const validateAccessPhraseInput = (newString: string): string[] => {
   const errorArray = [];
   if (newString.includes('  ')) {
      errorArray.push('accessPhrase.doubleSpace');
   }
   // eslint-disable-next-line no-control-regex
   if (newString.match(/[\x00-\x1F]+/g)) {
      errorArray.push('accessPhrase.unprintableCharacter');
   }
   if (newString.length > Constants.maxAccessPhraseLength) {
      errorArray.push('accessPhrase.maxLength');
   }
   return errorArray;
};

export default
{
   validateAccessPhraseInput,
   sanitizeAccessPhraseSubmit,
   isNullOrWhiteSpace
};
