import React from 'react';
import {useTranslation} from 'react-i18next';
import {TscButtonType, Glyphs} from '@techsmith/tsc-cloud-style-guide';
import logging from '../../service/logging';
import '../../static/css/modal.less';
import {IWorkerState} from '../../model/workerState';
import {StyledWarning} from '../util/StyledElements';
import AlertModalSpinner from '../util/AlertModalSpinner';
import styled from 'styled-components';
import {IFolderTreeNode} from '../../model/folderTreeNode';
import {ICollectionModel} from '../../model/appModel';
import folderApi from '../../service/folderApi';
import mediaApi from '../../service/mediaApi';
import collectionApi from '../../service/collectionApi';
import {AlertModalPortal} from '../util/ModalPortalHelper';
import {IMediaDetails} from '../../model/mediaDetails';
import decode from 'entity-decode';

const AlertBody = styled.div`
   white-space: pre-wrap;
   word-break: break-word;
`;

const ItemsToDeleteDescriptionSection = styled.ul`
   max-height: 10rem;
   overflow-y: auto;
   list-style: none;
`;

const ItemDescription = styled.li`
   display: flex;
   align-items: center;
   white-space: nowrap;
`;

const ItemDescriptionTitle = styled.span`
   margin-left: .5rem;
   overflow-x: hidden;
   text-overflow: ellipsis;
   flex: 1;
`;

const DeleteModal: React.FC<IDeleteModalProps> = ({onClose, onDelete, mediaToDelete, foldersToDelete, collectionsToDelete, collectionMediaToDelete, title, warning, testId, deleteButtonText}) => {
   const {t} = useTranslation();
   const [state, setState] = React.useState({
      isWorking: false,
      hasError: false
   } as IWorkerState);

   const onDeleteClick = async () => {
      setState({...state, isWorking: true, hasError: false});

      try {
         const deleteFolderPromises = foldersToDelete.map(f => folderApi.deleteFolder(f.Hash));
         const deleteMediaPromises = mediaToDelete.map(media => mediaApi.deleteMedia(media.Hash));
         const deleteCollectionPromises = collectionsToDelete.map(c => collectionApi.deleteCollection(c.Id));
         const deleteCollectionMediaPromises = collectionMediaToDelete.media.map(media => collectionApi.removeFromCollection(collectionMediaToDelete.collection.Id, media.Hash));
         await Promise.all(deleteFolderPromises.concat(deleteMediaPromises).concat(deleteCollectionPromises).concat(deleteCollectionMediaPromises));

         onDelete();

         onClose();
      } catch (e) {
         logging.error(e);
         setState({...state, isWorking: false, hasError: true});
      }
   };

   const descriptionOfItemsToDelete: React.JSX.Element = (
      <ItemsToDeleteDescriptionSection>
         {foldersToDelete.map(f => <ItemDescription key={f.Hash}><Glyphs.Folder16x16 /><ItemDescriptionTitle>{decode(f.Name)}</ItemDescriptionTitle></ItemDescription>)}
         {mediaToDelete.map(s => {
            const glyph = s.IsVideo ? <Glyphs.Video16x16 /> : <Glyphs.Image16x16 />;
            return <ItemDescription key={s.Hash}>{glyph}<ItemDescriptionTitle>{decode(s.Title)}</ItemDescriptionTitle></ItemDescription>;
         })}
      </ItemsToDeleteDescriptionSection>
   );

   return (
      <AlertModalPortal
         title={title}
         onClose={() => onClose()}
         onConfirm={onDeleteClick}
         confirmButtonType={TscButtonType.destructive}
         confirmContent={deleteButtonText || t('general.delete')}
         onDismiss={() => onClose()}
         dismissButtonType={TscButtonType.secondary}
         dismissContent={t('general.cancel')}
         hideButtons={state.isWorking}
         modalWidth={'30rem'}
         testId={testId}
      >
         {state.isWorking && <AlertModalSpinner/>}
         <AlertBody>
            {state.hasError && <StyledWarning>{t('general.errorText')}</StyledWarning>}
            {warning}
            {descriptionOfItemsToDelete}
         </AlertBody>
      </AlertModalPortal>
   );
};

DeleteModal.defaultProps = {
   mediaToDelete: [],
   foldersToDelete: [],
   collectionsToDelete: [],
   collectionMediaToDelete: {media: [], collection: null}
};

export default DeleteModal;

interface IDeleteModalProps {
   onClose(): void;
   onDelete(): void;
   mediaToDelete?: IMediaDetails[];
   foldersToDelete?: IFolderTreeNode[];
   collectionsToDelete?: ICollectionModel[];
   collectionMediaToDelete?: {media: IMediaDetails[]; collection: ICollectionModel};
   title: string;
   warning: any;
   testId?: string;
   deleteButtonText?: string;
}
