import React from 'react';
import ReactDOM from 'react-dom';
import Header from './component/header/Header';
import {IBaseAppModel} from './model/appModel';
import config from './service/config';
import './static/css/index-header.less';
import {EntryPoint} from './constants/Constants';
import StateProvider from './context/stateProvider';
import {BrowserRouter} from 'react-router-dom';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

const HeaderRoot: React.FC = () => <Header hideSearch={config.featureSwitches.DisableSearch} hideThemePicker={true} hideEntitlementInfo={false} hideNavLinkTabs={false} />;

export default (model: IBaseAppModel) => {
   const headerRoot = document.getElementById('header-root');
   if (!headerRoot) {
      return;
   }

   document.body.classList.add('app-header-body');

   config.initBaseAppModel(EntryPoint.header, model);

   ReactDOM.render(
      <DndProvider backend={HTML5Backend}>
         <BrowserRouter>
            <StateProvider isHeader={true}>
               <HeaderRoot />
            </StateProvider>
         </BrowserRouter>
      </DndProvider>,
      headerRoot
   );
};
