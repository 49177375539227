import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {ControlButton, TscButtonType, Label, Spinner, TscThemeName, TscThemeNames, TscStyles} from '@techsmith/tsc-cloud-style-guide';
import UserEmailInput from '../../details/UserEmailInput';
import cssConstants from '../../../constants/cssConstants';
import {ITypeAheadRecord} from '../../../model/typeAheadRecordModel';
import profileApi from '../../../service/profileApi';
import {scrollElementIntoView} from '../../../util/CommentScroller';
import logging from '../../../service/logging';
import MemberAlreadyExistsError from '../../../class/memberExistsError';
import UserNotFoundError from '../../../class/userNotFoundError';
import withMemoizedContexts from '../../../context/contextContainerHoC';
import {themeStore} from '../../../context/themeProvider';
import {IMemberDetailsWithProfileImageModel, IUserProfileModel, IUserProfileWithEmailModel} from '../../../model/appModel';
import CollectionMemberListItem from './CollectionMemberListItem';
import {CollectionRoleTypes} from '../../../constants/Constants';

const EmailInputContainer = styled.div`
   display: flex;
   margin-bottom: 1rem;

   > button {
      margin-left: 0.5rem;
   }
`;

const FlexContainer = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
`;

const EmailListContainer = styled.div<{theme: TscThemeNames}>`
   display: flex;
   flex-direction: column;
   height: 10rem;
   border: 1px solid ${props => props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.dark : TscStyles.color.ui.dawn.medium};
   border-radius: ${TscStyles.border.radius.md};
   overflow-x: hidden;
   overflow-y: auto;
`;

const LoadingContainer = styled.div`
   margin: 0.25rem 0;
   height: 2.25rem;
   min-height: 2.25rem;
   width: 100%;
   display: flex;
   overflow: hidden;

   > div {
      height: 2.25rem;
   }
`;

const EmptyState = styled.span`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   font-size: 1rem;
   font-weight: ${TscStyles.font.weight.semibold};
`;

interface InputUser {
   email: string;
   techSmithId?: string;
}

export const TypeAheadListManagerBase: React.FC<ITypeAheadListManagerProps & IStateMappedProps> = ({labelText, members, isWorking, removeMember, addMember, onRoleChange, updateWorkerState, theme}) => {
   const {t} = useTranslation();
   const [inputUser, setInputUser] = useState<InputUser>({email: ''});
   const [isValidEmail, setIsValidEmail] = useState(false);
   const [emailInputKey, setEmailInputKey] = useState(0);
   const loadSpinnerRef = React.useRef(null);
   const showEmptyState = !members.length && !isWorking;
   const shareId = 'type-ahead-list-manager';
   const memberContainerId = 'email-list-container';

   useEffect(() => {
      isWorking && scrollElementIntoView(loadSpinnerRef.current, memberContainerId);
   }, [isWorking]);

   const onEmailChange = (newEmail: string, isValid: boolean, typeAheadRecord?: ITypeAheadRecord) => {
      setInputUser({email: newEmail, techSmithId: typeAheadRecord?.techsmithid});
      setIsValidEmail(isValid);
      updateWorkerState(false, '');
   };

   const addEmailToList = async () => {
      updateWorkerState(true, '');

      try {
         const partialUserProfile: IUserProfileModel = inputUser.techSmithId ?
            await profileApi.getUserProfileInfo(inputUser.techSmithId) :
            await profileApi.getUserProfileInfoFromEmail(inputUser.email);

         const addedMember: IUserProfileWithEmailModel = Object.assign({}, partialUserProfile, {email: inputUser.email});

         addMember(addedMember);
         // eslint-disable-next-line no-undefined
         setInputUser({email: '', techSmithId: undefined});
         setEmailInputKey(emailInputKey + 1);
         updateWorkerState(false, '');
      } catch (e) {
         if (e instanceof MemberAlreadyExistsError || e instanceof UserNotFoundError) {
            updateWorkerState(false, e.message);
         } else {
            logging.error(`Error while attempting to add email: ${inputUser.email}`, e);
            updateWorkerState(false, t('general.errorText'));
         }
      }
   };

   const managers = members.filter(m => m.Role === CollectionRoleTypes.manager);
   const isLastManager = (member: IMemberDetailsWithProfileImageModel) => managers.length === 1 && member.TechSmithId === managers[0].TechSmithId;
   return (
      <>
         {addMember && <Label sharedId={shareId} labelText={labelText} />}
         {addMember && <EmailInputContainer>
            <FlexContainer>
               <UserEmailInput onEmailChange={onEmailChange} textInputId={shareId} key={emailInputKey} disabled={isWorking} />
            </FlexContainer>
            <ControlButton
               buttonType={TscButtonType.secondary}
               label={t('general.add')}
               onClick={addEmailToList}
               themeName={theme}
               width={cssConstants.smallButtonWidth}
               disabled={!isValidEmail || isWorking}
               testId="type-ahead-list-manager-add-email-button"
            />
         </EmailInputContainer>}
         <EmailListContainer theme={theme} id={memberContainerId}>
            {showEmptyState && <EmptyState>{t('general.noMembers')}</EmptyState>}
            {members.map((m: IMemberDetailsWithProfileImageModel) => <CollectionMemberListItem member={m} onRoleChange={onRoleChange} onRemoveClick={removeMember ? () => removeMember(m) : null} key={m.EmailAddress} disableChanges={isLastManager(m)}/>)}
            {isWorking && <LoadingContainer ref={loadSpinnerRef}><Spinner testId="loading-spinner" themeName={theme} /></LoadingContainer>}
         </EmailListContainer>
      </>
   );
};

export interface ITypeAheadListManagerProps {
   labelText: string;
   members: IMemberDetailsWithProfileImageModel[];
   isWorking: boolean;
   removeMember?(member: IMemberDetailsWithProfileImageModel): void;
   addMember?(addedMember: IUserProfileWithEmailModel): void;
   onRoleChange?(member: IMemberDetailsWithProfileImageModel, role: CollectionRoleTypes): void;
   updateWorkerState(isWorking: boolean, error: string): void;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {labelText, members, isWorking, removeMember, addMember, onRoleChange, updateWorkerState}: ITypeAheadListManagerProps,
   {theme}: Partial<IStateMappedProps>
): IStateMappedProps & ITypeAheadListManagerProps => ({labelText, members, isWorking, removeMember, addMember, onRoleChange, updateWorkerState, theme});

export default withMemoizedContexts(mapStatesToProps, themeStore)(TypeAheadListManagerBase);
